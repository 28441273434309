import { NumberInputProps } from "@components/form-components/NumberInput/NumberInputTypes";
import TextField from "@components/TextField/TextField";

const NumberInput = ({ className, onChange, ...rest }: NumberInputProps) => {
  const onChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.match(/\D/g) !== null) {
      event.currentTarget.value = event.currentTarget.value.replace(/\D/g, "");
    }
    if (onChange) onChange(event);
  };

  return <TextField className={className} onChange={onChangeField} {...rest} />;
};
export default NumberInput;
