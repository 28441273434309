import { TableProps } from "@components/Table/TableTypes";
import Typography from "@components/Typography/Typography";
import { styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "withoutBorder",
})<Pick<TableProps, "withoutBorder">>`
  ${({ withoutBorder }) => withoutBorder && "border: none;"}
`;

export const StyledTableRow = styled(TableRow)`
  :last-child {
    td {
      border: none;
    }
  }
`;

export const StyledTableTypography = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;
