type AvatarHeadIconProps = {
  className?: string;
};

const AvatarHeadIcon = ({ className }: AvatarHeadIconProps) => {
  return (
    <svg
      className={className}
      width="92"
      height="94"
      viewBox="0 0 92 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.5 93.8571C53.7109 93.8571 62.715 91.1257 70.3737 86.0084C78.0323 80.8911 84.0015 73.6176 87.5264 65.1078C91.0512 56.598 91.9735 47.234 90.1766 38.2001C88.3796 29.1661 83.9441 20.8678 77.4309 14.3547C70.9178 7.84158 62.6196 3.40608 53.5856 1.60911C44.5516 -0.187858 35.1877 0.734412 26.6779 4.25929C18.168 7.78417 10.8946 13.7534 5.77725 21.412C0.659914 29.0706 -2.07146 38.0747 -2.07146 47.2857C-2.07146 59.6372 2.83516 71.4828 11.569 80.2167C20.3028 88.9505 32.1485 93.8571 44.5 93.8571Z"
        fill="#B3C6E2"
      />
    </svg>
  );
};
export default AvatarHeadIcon;
