import { ReactHookFormSelectProps } from "@components/form-components/ReactHookFormSelect/ReactHookFormSelectTypes";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const ReactHookFormSelect = ({
  className,
  name,
  label,
  defaultValue,
  children,
  rules,
  ...restProps
}: ReactHookFormSelectProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ ...rules }}
      render={({ field }) => {
        return (
          <TextField
            className={className}
            select
            label={label}
            {...field}
            {...restProps}
          >
            {children}
          </TextField>
        );
      }}
    />
  );
};
export default ReactHookFormSelect;
