import { GetAcidTaresApiResponse } from "@customTypes/apiTypes/models/acidTare.model";
import { AcidTareApiPaths } from "@redux/api/acidTare/acidTareApiPaths";
import generalApi from "@redux/api/initialApi";

export const acidTareApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAcidTares: builder.query<GetAcidTaresApiResponse, void>({
      query: () => AcidTareApiPaths.AcidTaresAll,
    }),
  }),
});

export const { useGetAcidTaresQuery } = acidTareApi;
