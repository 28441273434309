import TextField from "@components/TextField/TextField";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Box, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { AcidOption } from "@pages/RequestModal/RequestModalTypes";

export const StyledAutocompleteOptions = styled("li")`
  border-bottom: 1px solid rgba(39, 39, 39, 0.3);
  border-width: 70%;
  :last-child {
    border: none;
  }
`;

export const StyledAutocompleteOptionsGridItem = styled(Grid)`
  width: calc(100% - 44px);
  word-wrap: break-word;
  padding: 20px;
`;

export const StyledShortAcidInfoBoxWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isTabletScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTabletScreen: boolean }>>`
  margin-top: 30px;
  background: #fff;
  border-radius: ${borderRadius};
  padding: ${({ isMobileScreen }) => (isMobileScreen ? "15px" : "30px")};
  margin-bottom: ${({ isTabletScreen, theme }) =>
    isTabletScreen ? theme.spacing(10) : theme.spacing(0)};
`;

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "autocompleteValue",
})<{
  autocompleteValue: AcidOption | null;
}>`
  .MuiInputBase-root {
    border-radius: ${borderRadius};
    max-width: 1350px;
  }

  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ autocompleteValue }) =>
          autocompleteValue ? "#50D890" : "#1A73E8"};
      }
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ autocompleteValue }) =>
        autocompleteValue ? "#50D890" : "#27272740"};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 2px solid
      ${({ autocompleteValue }) =>
        autocompleteValue ? "#50D890" : "#27272740"};
  }
`;
