import {
  StyledCustomPopup,
  StyledCustomPopupWrapper,
  StyledCustomPopupButton,
} from "@components/CustomPopup/StyledCustomPopup";
import Typography from "@components/Typography/Typography";
import { Stack, useMediaQuery, useTheme } from "@mui/material";

type CustomPopupProps = {
  message: string | null;
  onClose: () => void;
  isOpen: boolean;
};

const CustomPopup = ({ message, onClose, isOpen }: CustomPopupProps) => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));

  if (!isOpen || !message) {
    return null;
  }

  return (
    <StyledCustomPopupWrapper isLaptopScreen={isLaptopScreen}>
      <StyledCustomPopup isMobileScreen={isMobileScreen}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="body2"
            fontWeight={"fontWeightRegular"}
            fontSize={isMobileScreen ? "14px" : "inherit"}
            color={theme.palette.common.white}
          >
            {message}
          </Typography>
          <StyledCustomPopupButton
            isMobileScreen={isMobileScreen}
            variant="contained"
            onClick={onClose}
          >
            Закрыть
          </StyledCustomPopupButton>
        </Stack>
      </StyledCustomPopup>
    </StyledCustomPopupWrapper>
  );
};
export default CustomPopup;
