import { QuantityIndicatorProps } from "@components/QuantityIndicator/QuantityIndicatorTypes";
import { StyledQuantityIndicatorSquare } from "@components/QuantityIndicator/StyledQuantityIndicator";
import Typography from "@components/Typography/Typography";
import { Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const QuantityIndicator = ({ count }: QuantityIndicatorProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const productAvailability = count > 0 ? "товар в наличии" : "нет в наличии";

  let squares = [
    { id: 1, color: theme.palette.indicators.default },
    { id: 2, color: theme.palette.indicators.default },
    { id: 3, color: theme.palette.indicators.default },
    { id: 4, color: theme.palette.indicators.default },
  ];
  if (count < 10 && count !== 0) {
    squares = [
      { id: 1, color: theme.palette.indicators.danger },
      { id: 2, color: theme.palette.indicators.default },
      { id: 3, color: theme.palette.indicators.default },
      { id: 4, color: theme.palette.indicators.default },
    ];
  }
  if (count >= 10) {
    squares = [
      { id: 1, color: theme.palette.indicators.warning },
      { id: 2, color: theme.palette.indicators.warning },
      { id: 3, color: theme.palette.indicators.default },
      { id: 4, color: theme.palette.indicators.default },
    ];
  }

  if (count >= 20) {
    squares = [
      { id: 1, color: theme.palette.indicators.success },
      { id: 2, color: theme.palette.indicators.success },
      { id: 3, color: theme.palette.indicators.success },
      { id: 4, color: theme.palette.indicators.default },
    ];
  }

  if (count >= 30) {
    squares = [
      { id: 1, color: theme.palette.indicators.success },
      { id: 2, color: theme.palette.indicators.success },
      { id: 3, color: theme.palette.indicators.success },
      { id: 4, color: theme.palette.indicators.success },
    ];
  }

  const indicatorBlocks = squares.map((item) => (
    <StyledQuantityIndicatorSquare
      color={item.color}
      key={item.id}
      isMobileScreen={isMobileScreen}
    ></StyledQuantityIndicatorSquare>
  ));
  return (
    <Box>
      <Grid container justifyContent="center" spacing={1}>
        <Grid>
          <Stack direction="row" spacing={0.3}>
            {indicatorBlocks}
          </Stack>
        </Grid>
        <Grid>
          <Typography
            variant={isMobileScreen ? "body2" : "body1"}
            color={theme.palette.text.secondary}
          >
            - {productAvailability}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default QuantityIndicator;
