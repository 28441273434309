import CategoryCardsGroup from "@components/CategoryCardsGroup/CategoryCardsGroup";
import MainContainer from "@components/MainContainer/MainContainer";
import StyledMobileScreenTitle from "@components/MobileScreenTitle/StyledMobileScreenTitle";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { useTheme, useMediaQuery, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useGetCategoriesQuery } from "@redux/api/categories/categoriesApi";

const CatalogPage = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: fetchedCategories,
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
  } = useGetCategoriesQuery();

  const catalogPageTitle = isMobileScreen ? (
    <StyledMobileScreenTitle variant="h2">Каталог</StyledMobileScreenTitle>
  ) : (
    <Typography variant="h2">Каталог</Typography>
  );

  return (
    <MainContainer>
      <Box sx={{ marginTop: isMobileScreen ? "30px" : "50px" }}>
        {catalogPageTitle}
      </Box>

      <Box sx={{ marginTop: isMobileScreen ? "30px" : "60px" }}>
        <WithLoader isLoading={isCategoriesLoading}>
          <WithErrorMessage
            isError={isCategoriesError}
            stackProps={{
              style: { margin: isMobileScreen ? "90px 0" : "200px 0" },
            }}
          >
            <Grid container direction="column" spacing={3}>
              {fetchedCategories && (
                <CategoryCardsGroup categories={fetchedCategories} />
              )}
            </Grid>
          </WithErrorMessage>
        </WithLoader>
      </Box>

      <Box sx={{ marginTop: "72px" }}>
        <Typography
          variant={isMobileScreen ? "body2" : "body1"}
          color={theme.palette.text.secondary}
        >
          Все цены на сайте представлены без учёта доставки. Для ближнего
          зарубежья отгружаем с НДС 0%. Растворы изготавливаются на объем не
          менее 1000 литров.
        </Typography>
      </Box>
    </MainContainer>
  );
};
export default CatalogPage;
