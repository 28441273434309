import { ButtonProps as MuiButtonProps } from "@components/Button/ButtonTypes";
import MuiButton from "@mui/material/Button";

export type ButtonProps = {} & MuiButtonProps;

const Button = ({ className, children, ...rest }: ButtonProps) => {
  return (
    <MuiButton className={className} {...rest}>
      {children}
    </MuiButton>
  );
};
export default Button;
