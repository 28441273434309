import { styled, Box } from "@mui/material";

const RequestModalNavButtonsBoxWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isTabletScreen" && prop !== "isSmallMobileScreen",
})<{ isTabletScreen: boolean; isSmallMobileScreen: boolean }>`
  position: absolute;
  bottom: ${({ isTabletScreen }) => (isTabletScreen ? "28px" : "86px")};
  right: ${({ isTabletScreen }) => (isTabletScreen ? "0" : "90px")};
  display: flex;
  justify-content: ${({ isTabletScreen }) =>
    isTabletScreen ? "center" : "end"};

  width: ${({ isTabletScreen }) =>
    isTabletScreen ? "100%" : "calc(100% - 90px);"};

  ${({ isTabletScreen }) =>
    isTabletScreen &&
    ` z-index: 1000;
      padding: 0 5px;
    `}
`;

export default RequestModalNavButtonsBoxWrapper;
