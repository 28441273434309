const WAppIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="25" fill="#272727" fillOpacity="0.05" />
      <path
        d="M30.0203 14C21.2099 14 14.0376 21.1661 14.0344 29.9766C14.0328 32.7928 14.7704 35.5422 16.169 37.9648L14 46.0031L22.3727 44.0248C24.7073 45.2985 27.3355 45.9671 30.0109 45.9687H30.0172C38.826 45.9687 45.9952 38.801 46 29.9922C46.0032 25.7214 44.3424 21.7059 41.3245 18.6848C38.3066 15.6653 34.2959 14.0016 30.0203 14ZM30.0172 17.2003C33.4351 17.2019 36.6472 18.5345 39.0618 20.9476C41.4765 23.3638 42.8029 26.5743 42.7997 29.9891C42.7965 37.0361 37.0643 42.7684 30.0141 42.7684C27.8811 42.7668 25.7682 42.2313 23.9072 41.2152L22.829 40.6276L21.6382 40.9089L18.4879 41.6527L19.2568 38.7962L19.6037 37.5148L18.9411 36.3647C17.8242 34.4317 17.2331 32.2216 17.2347 29.9766C17.2379 22.9327 22.9717 17.2003 30.0172 17.2003ZM24.3635 22.6008C24.0963 22.6008 23.6642 22.7008 23.2978 23.1009C22.9313 23.4993 21.8976 24.4643 21.8976 26.4293C21.8976 28.3943 23.329 30.2938 23.5291 30.561C23.7275 30.8266 26.292 34.9864 30.3516 36.5866C33.7247 37.9163 34.4098 37.6539 35.1427 37.5867C35.8756 37.5211 37.507 36.6225 37.8398 35.6896C38.1727 34.7567 38.1734 33.9542 38.0742 33.7894C37.975 33.623 37.7086 33.5238 37.3085 33.3238C36.9101 33.1237 34.9466 32.1596 34.5801 32.0268C34.2137 31.8939 33.9457 31.8267 33.68 32.2268C33.4144 32.6268 32.651 33.5238 32.4174 33.7894C32.1838 34.0567 31.9517 34.0926 31.5517 33.8926C31.1517 33.6909 29.8654 33.2681 28.3389 31.908C27.1516 30.8503 26.3504 29.5453 26.1168 29.1452C25.8848 28.7468 26.0949 28.5279 26.2949 28.3295C26.4742 28.1503 26.6919 27.8631 26.8919 27.6295C27.0903 27.3958 27.1591 27.2294 27.2919 26.9638C27.4247 26.6981 27.3568 26.4637 27.2575 26.2637C27.1583 26.0637 26.3814 24.0914 26.0262 23.3009C25.7269 22.6368 25.4109 22.6214 25.1261 22.6102C24.8941 22.6006 24.6291 22.6008 24.3635 22.6008Z"
        fill="#25D366"
      />
    </svg>
  );
};
export default WAppIcon;
