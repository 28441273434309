type AvatarBodyIconProps = {
  className?: string;
};

const AvatarBodyIcon = ({ className }: AvatarBodyIconProps) => {
  return (
    <svg
      className={className}
      width="126"
      height="93"
      viewBox="0 0 126 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M114.357 93.2857C117.445 93.2857 120.406 92.059 122.59 89.8756C124.773 87.6921 126 84.7307 126 81.6428C126 60.0277 117.413 39.2978 102.129 24.0136C86.845 8.72941 66.1152 0.142822 44.5 0.142822C22.8849 0.142822 2.15501 8.72941 -13.1292 24.0136C-28.4134 39.2978 -37 60.0277 -37 81.6428C-37 84.7307 -35.7733 87.6921 -33.5899 89.8756C-31.4064 92.059 -28.445 93.2857 -25.3571 93.2857H114.357Z"
        fill="#B3C6E2"
      />
    </svg>
  );
};
export default AvatarBodyIcon;
