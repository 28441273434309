import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { IconButton, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";

export const StyledRequestModalDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  .MuiPaper-root {
    max-width: 1536px;
    width: 100%;
    height: 900px;
    justify-content: space-between;
    border-radius: ${borderRadius};
    background: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    ${({ isMobileScreen }) =>
      isMobileScreen &&
      `max-height: 100%; height: 100%; border-radius: initial; margin: 0`}
  }
`;

export const StyledRequestModalDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) =>
    prop !== "isTabletScreen" && prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTabletScreen: boolean }>>`
  padding: ${({ isTabletScreen }) => (isTabletScreen ? "15px" : "90px")};
  height: auto;
  overflow-x: hidden;
  width: ${({ isTabletScreen }) => (isTabletScreen ? "auto" : "100%")};
  ${({ isMobileScreen }) =>
    isMobileScreen && `width: calc(100% - 30px); margin-top: 45px; padding: 0;`}
`;

export const StyledRequestModalCloseButton = styled(IconButton)`
  width: fit-content;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 30px;
  margin-right: 30px;
`;
