import {
  GetAcidByIdApiResponse,
  GetAcidByNameApiResponse,
} from "@customTypes/apiTypes/models/acid.model";
import { AcidApiPaths } from "@redux/api/acid/acidApiPaths";
import generalApi from "@redux/api/initialApi";

export const acidApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAcidsByName: builder.query<GetAcidByNameApiResponse, string>({
      query: (partName) => {
        return {
          url: AcidApiPaths.AcidName,
          params: { partName },
        };
      },
    }),
    getAcidById: builder.query<GetAcidByIdApiResponse, number>({
      query: (id) => AcidApiPaths.Acid + id,
    }),
    getAcidByFrontName: builder.query<GetAcidByIdApiResponse, string>({
      query: (frontName) => `${AcidApiPaths.AcidName}/${frontName}`,
    }),
  }),
});

export const {
  useGetAcidsByNameQuery,
  useLazyGetAcidByIdQuery,
  useLazyGetAcidByFrontNameQuery,
} = acidApi;
