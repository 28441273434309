import mainBannerCube from "@assets/images/mainBannerCube.png";
import mainBannerCubeWebp from "@assets/images/mainBannerCube.webp";
import StyledMobileScreenTitle from "@components/MobileScreenTitle/StyledMobileScreenTitle";
import { Stack, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  StyledMainBannerImage,
  StyledMainBannerTitle,
  StyledMainBannerButton,
  StyledMainBannerStackContainer,
  StyledMainBannerImageContainer,
} from "@pages/MainPage/components/MainBanner/StyledMainBanner";
import RoutePaths from "@routes/routePaths";
import { useNavigate } from "react-router-dom";

const MainBanner = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMonitorSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isDesktopScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const isBigTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletScreen = useMediaQuery("(max-width:768px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallMobileScreen = useMediaQuery("(max-width:400px)");

  const title =
    "Главный поставщик \n фосфорных кислот в \n Нижегородской области";

  const mainBannerTitle = isMobileScreen ? (
    <StyledMobileScreenTitle
      variant="h1"
      color={theme.palette.secondary.main}
      titleFontWeight={theme.typography.fontWeightRegular}
      sx={{ maxWidth: "280px" }}
    >
      {title}
    </StyledMobileScreenTitle>
  ) : (
    <StyledMainBannerTitle
      variant="h1"
      isBigTabletScreen={isBigTabletScreen}
      isMonitorSmallScreen={isMonitorSmallScreen}
    >
      {title}
    </StyledMainBannerTitle>
  );

  const justifyContentContainer = isMobileScreen ? "center" : "space-between";

  const handleClickNavigateCatalog = () => {
    navigate(RoutePaths.CatalogPage);
  };

  return (
    <StyledMainBannerStackContainer
      direction="row"
      isMobileScreen={isMobileScreen}
      justifyContent={justifyContentContainer}
      isDesktopScreen={isDesktopScreen}
    >
      <Stack
        direction="column"
        spacing={isMobileScreen ? "30px" : "60px"}
        justifyContent="center"
      >
        <div>{mainBannerTitle}</div>

        <div>
          <StyledMainBannerButton
            variant="contained"
            fullWidth
            isMobileScreen={isMobileScreen}
            isTabletScreen={isTabletScreen}
            isBigTabletScreen={isBigTabletScreen}
            onClick={handleClickNavigateCatalog}
          >
            Перейти в каталог
          </StyledMainBannerButton>
        </div>
      </Stack>

      <StyledMainBannerImageContainer
        isSmallMobileScreen={isSmallMobileScreen}
        isMobileScreen={isMobileScreen}
        isMonitorSmallScreen={isMonitorSmallScreen}
      >
        <picture>
          <source srcSet={mainBannerCubeWebp} type="image/webp" />
          <StyledMainBannerImage
            src={mainBannerCube}
            width={544}
            height={486}
            alt="Пластиковый еврокуб"
          />
        </picture>
      </StyledMainBannerImageContainer>
    </StyledMainBannerStackContainer>
  );
};
export default MainBanner;
