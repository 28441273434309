import { CategoryCardProps } from "@components/CategoryCard/CategoryCardTypes";
import {
  StyledCategoryCard,
  StyledCategoryCardTitle,
} from "@components/CategoryCard/StyledCategoryCard";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

const CategoryCard = ({
  title,
  backgroundImage,
  className,
  titleMaxWidth = "400px",
  onClick,
}: CategoryCardProps) => {
  const theme = useTheme();

  const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const categoryCardTitleVariant = isMobileScreen ? "body1" : "h4";

  return (
    <StyledCategoryCard
      className={className}
      onClick={onClick}
      background={backgroundImage}
    >
      <Grid
        container
        alignItems="flex-end"
        spacing={0}
        sx={{ height: "100%" }}
        p={3}
      >
        <Grid>
          <StyledCategoryCardTitle
            variant={categoryCardTitleVariant}
            isMobileScreen={isMobileScreen}
            isLaptopScreen={isLaptopScreen}
            titleMaxWidth={titleMaxWidth}
          >
            {title}
          </StyledCategoryCardTitle>
        </Grid>
      </Grid>
    </StyledCategoryCard>
  );
};
export default CategoryCard;
