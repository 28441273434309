import NumberInput from "@components/form-components/NumberInput/NumberInput";
import { ReactHookFormInputProps } from "@components/form-components/ReactHookFormInput/ReactHookFormInputTypes";
import TextField from "@components/TextField/TextField";
import { Controller, useFormContext } from "react-hook-form";

const ReactHookFormInput = ({
  className,
  name,
  defaultValue,
  rules,
  isNumberInput,
  handleBlur,
  ...restProps
}: ReactHookFormInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ ...rules }}
      render={({ field: { onBlur, ref, ...restField }, fieldState }) => {
        if (isNumberInput) {
          return (
            <NumberInput
              className={className}
              helperText={
                fieldState.error
                  ? fieldState.error.message || fieldState.error.type
                  : null
              }
              error={Boolean(fieldState.error)}
              onBlur={handleBlur ? () => handleBlur() : onBlur}
              inputRef={ref}
              {...restField}
              {...restProps}
            />
          );
        }
        return (
          <TextField
            className={className}
            helperText={
              fieldState.error
                ? fieldState.error.message || fieldState.error.type
                : null
            }
            error={Boolean(fieldState.error)}
            onBlur={handleBlur ? () => handleBlur() : onBlur}
            inputRef={ref}
            {...restField}
            {...restProps}
          />
        );
      }}
    />
  );
};
export default ReactHookFormInput;
