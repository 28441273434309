import { useState, useEffect, SyntheticEvent, Fragment } from "react";

import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { MobileButtonConfig } from "@customTypes/requestModalTypes";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import useDebounce from "@hooks/useDebounce";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Autocomplete, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { RequestModalChooseGoodProps } from "@pages/RequestModal/components/RequestModalChooseGood/RequestModalChooseGoodTypes";
import {
  StyledTextField,
  StyledAutocompleteOptions,
  StyledShortAcidInfoBoxWrapper,
  StyledAutocompleteOptionsGridItem,
} from "@pages/RequestModal/components/RequestModalChooseGood/StyledRequestModalChooseGood";
import RequestModalMobileNavButtons from "@pages/RequestModal/components/RequestModalMobileNavButtons/RequestModalMobileNavButtons";
import RequestModalNavButtonsBoxWrapper from "@pages/RequestModal/components/RequestModalNavButtonsBoxWrapper/RequestModalNavButtonsBoxWrapper";
import RequestModalNavigateButtons from "@pages/RequestModal/components/RequestModalNavigateButtons/RequestModalNavigateButtons";
import RequestModalShortAcidInfo from "@pages/RequestModal/components/RequestModalShortAcidInfo/RequestModalShortAcidInfo";
import { AcidOption } from "@pages/RequestModal/RequestModalTypes";
import {
  useGetAcidsByNameQuery,
  useLazyGetAcidByIdQuery,
} from "@redux/api/acid/acidApi";
import { selectAcid } from "@redux/slices/requestModal/requestModalSlice";

const RequestModalChooseGood = ({
  handleChooseTare,
  handleCloseRequestModal,
}: RequestModalChooseGoodProps) => {
  const theme = useTheme();

  const isSmallMobileScreen = useMediaQuery("(max-width:420px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();

  const selectedAcid = useAppSelector(
    (state) => state.requestModal.selectedAcid
  );

  const acidIdFromPage = useAppSelector(
    (state) => state.requestModal.acidIdFromPage
  );

  const [autocompleteValue, setAutocompleteValue] = useState<AcidOption | null>(
    null
  );

  const [inputValue, setInputValue] = useState("");
  const debouncedInputValue = useDebounce(inputValue);

  const { data: acidNames, isLoading: isAcidNamesLoading } =
    useGetAcidsByNameQuery(debouncedInputValue);

  const [autocompleteOptions, setAutocompleteOptions] = useState<
    readonly AcidOption[]
  >(acidNames || []);

  const [
    getAcidById,
    { data: fetchedAcid, isLoading: isAcidLoading, isError: isAcidError },
  ] = useLazyGetAcidByIdQuery();

  /**
   * Components`s useEffects
   */
  useEffect(() => {
    if (!acidIdFromPage) return;

    getAcidById(acidIdFromPage);

    if (!fetchedAcid) return;
    const { id: fetchedAcidId, name: fetchedAcidName } = fetchedAcid;
    setAutocompleteValue({ id: fetchedAcidId, name: fetchedAcidName });
  }, [acidIdFromPage, fetchedAcid, getAcidById]);

  useEffect(() => {
    if (selectedAcid === null) return;

    const { image, ...rest } = selectedAcid;
    setAutocompleteValue(rest);

    getAcidById(selectedAcid.id);
    //?NOTE we need to render selectedAcid when the component did mount on page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autocompleteValue === null) return;
    getAcidById(autocompleteValue.id);
  }, [autocompleteValue, getAcidById]);

  useEffect(() => {
    if (fetchedAcid && autocompleteValue?.id !== undefined) {
      dispatch(
        selectAcid({
          id: fetchedAcid.id,
          name: fetchedAcid.name,
          acidDensity: fetchedAcid.acidDensity,
          prices: fetchedAcid.prices,
          availableTares: fetchedAcid.availableTares,
          image: fetchedAcid.acidFiles[0]?.name,
        })
      );
    }
  }, [dispatch, autocompleteValue?.id, fetchedAcid]);

  useEffect(() => {
    if (!acidNames) return;
    setAutocompleteOptions(acidNames);
  }, [acidNames]);

  /**
   * Handlers
   */

  /**
   * Handle Autocomplete Change
   * @param _event
   * @param newValue
   */
  const handleAutocompleteChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: AcidOption | null
  ) => {
    if (newValue === null) dispatch(selectAcid(null));

    setAutocompleteValue(newValue);
  };

  /**
   * Handle Autocomplete input Change
   * @param _event
   * @param newInputValue
   */
  const handleAutocompleteInputChange = (
    _event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  /**
   * Layouts
   */
  const mobileNavigationButtonsConfig: MobileButtonConfig[] = [
    {
      title: "Далее",
      variant: "contained",
      icon: <ArrowForwardIcon fontSize="large" />,
      onClick: handleChooseTare,
      background: theme.palette.secondary.main,
      textColor: "#F6F7F8",
      disabled: autocompleteValue ? false : true,
    },
  ];

  const chooseGoodNavigateButtons = () => {
    if (isTabletScreen) {
      return (
        <RequestModalMobileNavButtons
          handleCloseRequestModal={handleCloseRequestModal}
          buttonsConfig={mobileNavigationButtonsConfig}
        />
      );
    }
    return (
      <RequestModalNavigateButtons
        handleNexButton={handleChooseTare}
        nextButtonTitle="Выбрать тару"
        disabled={autocompleteValue ? false : true}
      />
    );
  };

  const chosenAcidContent = () => {
    if (!fetchedAcid || !autocompleteValue) return;

    const acidFile =
      fetchedAcid.acidFiles.length === 0 ? null : fetchedAcid.acidFiles[0].name;

    return (
      <>
        <StyledShortAcidInfoBoxWrapper
          isMobileScreen={isMobileScreen}
          isTabletScreen={isTabletScreen}
        >
          <RequestModalShortAcidInfo
            characteristics={fetchedAcid.characteristics?.slice(0, 3)}
            prices={fetchedAcid.prices}
            count={fetchedAcid.count}
            acidTitle={fetchedAcid.name}
            acidImage={acidFile}
            acidFrontName={fetchedAcid.frontName}
            category={fetchedAcid?.category}
            handleCloseRequestModal={handleCloseRequestModal}
          />
        </StyledShortAcidInfoBoxWrapper>
      </>
    );
  };

  return (
    <>
      <Typography variant={isTabletScreen ? "h5" : "h4"}>
        Укажите товар, который хотели бы приобрести:
      </Typography>
      <Autocomplete
        sx={{
          marginTop: "30px",
        }}
        options={autocompleteOptions}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        filterOptions={(x) => x}
        autoComplete
        filterSelectedOptions
        value={autocompleteValue}
        inputValue={inputValue}
        noOptionsText="Товар не найден"
        loading={isAcidNamesLoading}
        loadingText="Загрузка..."
        onChange={handleAutocompleteChange}
        onInputChange={handleAutocompleteInputChange}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            autocompleteValue={autocompleteValue}
            placeholder="Поиск"
            fullWidth
          />
        )}
        renderOption={(props, option) => {
          const customOption = option as AcidOption;
          return (
            <Fragment key={props.id}>
              <StyledAutocompleteOptions {...props}>
                <Grid container alignItems="center" sx={{ width: "100%" }}>
                  <StyledAutocompleteOptionsGridItem>
                    <Typography
                      variant="requestModalWindowBody1"
                      color="rgba(39, 39, 39, 0.7);"
                    >
                      {customOption.name}
                    </Typography>
                  </StyledAutocompleteOptionsGridItem>
                </Grid>
              </StyledAutocompleteOptions>
            </Fragment>
          );
        }}
      />

      <WithLoader
        isLoading={isAcidLoading}
        stackProps={{ style: { height: "80%" } }}
      >
        <WithErrorMessage
          isError={isAcidError}
          stackProps={{ style: { height: "80%" } }}
        >
          {chosenAcidContent()}
        </WithErrorMessage>
      </WithLoader>

      <RequestModalNavButtonsBoxWrapper
        isTabletScreen={isTabletScreen}
        isSmallMobileScreen={isSmallMobileScreen}
      >
        {chooseGoodNavigateButtons()}
      </RequestModalNavButtonsBoxWrapper>
    </>
  );
};
export default RequestModalChooseGood;
