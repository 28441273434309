import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const generalApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === "development"
        ? "/api"
        : process.env.REACT_APP_BASE_URL,
  }),
  endpoints: () => ({}),
});
export default generalApi;
