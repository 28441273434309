const YMapsIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="25" fill="#272727" fillOpacity="0.05" />
      <path
        d="M30 16C23.3724 16 18 21.3724 18 28C18 31.3126 19.3422 34.312 21.513 36.4834C23.6844 38.656 28.8 41.8 29.1 45.1C29.145 45.5948 29.5032 46 30 46C30.4968 46 30.855 45.5948 30.9 45.1C31.2 41.8 36.3156 38.656 38.487 36.4834C40.6578 34.312 42 31.3126 42 28C42 21.3724 36.6276 16 30 16Z"
        fill="#FF4433"
      />
      <path
        d="M30 32.2003C32.3196 32.2003 34.2 30.3199 34.2 28.0003C34.2 25.6807 32.3196 23.8003 30 23.8003C27.6805 23.8003 25.8 25.6807 25.8 28.0003C25.8 30.3199 27.6805 32.2003 30 32.2003Z"
        fill="#E1E2E3"
      />
    </svg>
  );
};
export default YMapsIcon;
