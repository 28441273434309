import InDevelopedButton from "@components/InDevelopedButton/InDevelopedButton";
import MainContainer from "@components/MainContainer/MainContainer";
import { Box, Stack, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import NewsItem from "@pages/NewsPage/components/NewsItem/NewsItem";
import { StyledNewsPageTitle } from "@pages/NewsPage/StyledNewsPage";

const newsItems = [1, 2, 3, 4, 5];

const newsContent = newsItems.map((item) => <NewsItem key={item} />);

const NewsPage = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MainContainer>
      <Box sx={{ marginTop: isMobileScreen ? "30px" : "50px" }}>
        <Grid2
          container
          spacing={"15px"}
          alignItems={isMobileScreen ? "center" : "end"}
        >
          <Grid2>
            <StyledNewsPageTitle isMobileScreen={isMobileScreen} variant="h2">
              Новости
            </StyledNewsPageTitle>
          </Grid2>

          <Grid2>
            <InDevelopedButton>Раздел в разработке</InDevelopedButton>
          </Grid2>
        </Grid2>

        <Stack
          direction="column"
          spacing={"30px"}
          sx={{ marginTop: isMobileScreen ? "30px" : "60px" }}
        >
          {newsContent}
        </Stack>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Grid2
            container
            justifyContent="space-between"
            xs
            sm={6}
            md={5}
            sx={{
              padding: "15px",
              background: "#EEEEEE",
              borderRadius: "10px",
              marginTop: "30px",
            }}
          >
            <Grid2 xs={1}>
              <Box
                sx={{
                  background: "#C7C7C7",
                  borderRadius: "10px",
                  height: "33px",
                }}
              />
            </Grid2>

            <Grid2 xs={6}>
              <Box
                sx={{
                  background: "#C7C7C7",
                  borderRadius: "10px",
                  height: "33px",
                }}
              />
            </Grid2>

            <Grid2 xs={3}>
              <Box
                sx={{
                  background: "#C7C7C7",
                  borderRadius: "10px",
                  height: "33px",
                }}
              />
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </MainContainer>
  );
};
export default NewsPage;
