import Button from "@components/Button/Button";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Dialog, DialogContent, styled } from "@mui/material";

export const StyledRequestModalConfirmationDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: ${borderRadius};
    max-width: 511px;
  }
`;

export const StyledRequestModalConfirmationButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "background" &&
    prop !== "textColor" &&
    prop !== "activeBackground",
})<{
  background?: string;
  textColor?: string;
  activeBackground: string;
}>`
  border-radius: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: ${({ background, theme }) =>
    background ? background : theme.palette.secondary.main};
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.palette.common.white};
  padding: 15px 0;

  :hover {
    background-color: ${({ background, theme }) =>
      background ? background : theme.palette.secondary.main};
  }

  :active {
    background-color: ${({ activeBackground }) => activeBackground};
  }
`;

export const StyledRequestModalConfirmationDialogContent = styled(
  DialogContent,
  { shouldForwardProp: (prop) => prop !== "isMobileScreen" }
)<TypeStyledComponentWithMobileScreenProp>`
  padding: ${({ isMobileScreen }) => (isMobileScreen ? "20px" : "45px")};
`;
