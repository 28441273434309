import InDevelopedButton from "@components/InDevelopedButton/InDevelopedButton";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Divider, styled } from "@mui/material";

export const StyledNewsBlock = styled("div", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  border-radius: ${borderRadius};
  background: #2727270d;
  overflow-x: ${({ isMobileScreen }) => (isMobileScreen ? "visible" : "clip")};
  ${({ isMobileScreen }) =>
    !isMobileScreen && "max-height: 349px; height: 100%;"}
  padding: ${({ isMobileScreen }) => (isMobileScreen ? "30px 32px" : "60px")};
  ${({ isMobileScreen }) => !isMobileScreen && "position: relative"}
`;

export const StyledNewsBlockImageContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  position: absolute;
  top: ${({ isMobileScreen }) => (isMobileScreen ? "-6%" : "-16%")};
  ${({ isMobileScreen }) => (isMobileScreen ? "left: 0" : "right: -86px;")};
`;

export const StyledNewsBlockImage = styled("img")`
  border-top-right-radius: ${borderRadius};
`;

export const StyledNewsBlockDivider = styled(Divider)`
  border: 1px solid #2727270d;
`;

export const StyledNewsBlockInDevelopedButton = styled(InDevelopedButton, {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isLaptopScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isLaptopScreen: boolean }>>`
  position: absolute;
  top: ${({ isLaptopScreen }) => (!isLaptopScreen ? 0 : "50%")};
  left: ${({ isLaptopScreen }) => (!isLaptopScreen ? "50%" : "12px")};
  ${({ isMobileScreen }) => isMobileScreen && `top: 0; right: 12px; left: auto`}
`;
