import { GetLastInfoMessageResponse } from "@customTypes/apiTypes/models/infoMessage.model";
import { InfoMessageApiPaths } from "@redux/api/infoMessage/infoMessageApiPaths";
import generalApi from "@redux/api/initialApi";

export const infoMessageApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getLastInfoMessage: builder.query<GetLastInfoMessageResponse, void>({
      query: () => InfoMessageApiPaths.InfoMessageLast,
    }),
  }),
});

export const { useGetLastInfoMessageQuery } = infoMessageApi;
