import Button from "@components/Button/Button";
import { borderRadius } from "@constants/constants.style";
import { IconButton, styled } from "@mui/material";

export const StyledRequestModalMobileNavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "background" && prop !== "textColor",
})<{ background: string; textColor: string }>`
  padding: 15px 20px;
  justify-content: space-between;
  width: 100%;
  border-radius: ${borderRadius};
  background: ${({ background }) => background};
  box-shadow: none;
  color: ${({ textColor }) => textColor};
  &:hover {
    background: ${({ background }) => background};
  }
  .MuiButton-endIcon {
    svg {
      font-size: 25px;
    }
  }
`;

export const StyledRequestModalMobileNavIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "background" && prop !== "textColor",
})<{ background: string; textColor: string }>`
  padding: 15px 30px;
  width: 100%;
  border-radius: ${borderRadius};
  background: ${({ background }) => background};
  box-shadow: none;
  color: ${({ textColor }) => textColor};
  &:hover {
    background: ${({ background }) => background};
  }
`;
