import { Box, Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import { RequestModalConfirmationDialogProps } from "@pages/RequestModal/components/RequestModalConfirmationDialog/RequestModalConfirmationDialogTypes";
import {
  StyledRequestModalConfirmationDialog,
  StyledRequestModalConfirmationButton,
  StyledRequestModalConfirmationDialogContent,
} from "@pages/RequestModal/components/RequestModalConfirmationDialog/StyledRequestModalConfirmationDialog";

const RequestModalConfirmationDialog = ({
  openConfirmationWindow,
  handleCloseConfirmationWindow,
  handleClosingConfirmation,
}: RequestModalConfirmationDialogProps) => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const confirmText =
    "Вы уверены, что хотите закрыть окно? \n В случае закрытия данные будут утеряны.";

  return (
    <StyledRequestModalConfirmationDialog
      open={openConfirmationWindow}
      onClose={handleCloseConfirmationWindow}
    >
      <StyledRequestModalConfirmationDialogContent
        isMobileScreen={isMobileScreen}
      >
        <Box sx={{ marginBottom: "30px" }}>
          <Typography
            variant={isMobileScreen ? "body2" : "body1"}
            sx={{ whiteSpace: "pre-line" }}
          >
            {confirmText}
          </Typography>
        </Box>

        <Stack direction="row" spacing={"10px"} justifyContent="center">
          <StyledRequestModalConfirmationButton
            background="#EBECED"
            activeBackground="#D8D9DA"
            textColor={theme.palette.text.primary}
            onClick={handleCloseConfirmationWindow}
            fullWidth
          >
            Отмена
          </StyledRequestModalConfirmationButton>
          <StyledRequestModalConfirmationButton
            onClick={handleClosingConfirmation}
            background={theme.palette.error.light}
            activeBackground="#E05A4E"
            fullWidth
          >
            Закрыть
          </StyledRequestModalConfirmationButton>
        </Stack>
      </StyledRequestModalConfirmationDialogContent>
    </StyledRequestModalConfirmationDialog>
  );
};
export default RequestModalConfirmationDialog;
