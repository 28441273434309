export type SecondaryLogoIconProps = {
  width?: number;
  height?: number;
};

const SecondaryLogo = ({
  height = 57,
  width = 187,
}: SecondaryLogoIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 187 57"
      fill="none"
      aria-label="Нижегородский завод фосфорных кислот"
    >
      <path
        d="M34.3986 12.3511V49.6806H25.7186V34.3755H8.68003V49.6806H0V12.3511H8.68003V27.0696H25.7186V12.3511H34.3986Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M67.0334 30.2157C69.2124 30.9623 70.9091 32.1355 72.1236 33.7354C73.3738 35.2996 73.9989 37.1306 73.9989 39.2281C73.9989 41.5034 73.2488 43.4766 71.7485 45.1475C70.2483 46.8185 68.2122 48.0983 65.6403 48.9871C63.1042 49.8759 60.318 50.3203 57.2818 50.3203C54.7099 50.3203 52.1381 50.0004 49.5662 49.3604C47.0301 48.7205 44.619 47.7428 42.3329 46.4274L45.0119 40.2414C46.655 41.2368 48.4589 42.0012 50.4235 42.5345C52.4238 43.0322 54.3706 43.281 56.2638 43.281C58.8356 43.281 60.961 42.8189 62.6398 41.8945C64.3544 40.9702 65.2117 39.6725 65.2117 38.0016C65.2117 36.6151 64.6223 35.5663 63.4435 34.8552C62.3005 34.1442 60.7288 33.7887 58.7285 33.7887H49.5662V27.2827H58.0855C59.8358 27.2827 61.1932 26.9272 62.1576 26.2161C63.1578 25.5051 63.6579 24.5274 63.6579 23.2831C63.6579 21.8255 62.9435 20.6878 61.5146 19.8701C60.1216 19.0524 58.3177 18.6436 56.103 18.6436C54.4599 18.6436 52.7632 18.8746 51.0129 19.3368C49.2626 19.7634 47.6016 20.4212 46.0299 21.31L43.4045 14.5906C47.3694 12.5642 51.6558 11.551 56.2638 11.551C59.1928 11.551 61.8897 11.9776 64.3544 12.8308C66.8191 13.6841 68.7837 14.9284 70.2483 16.5638C71.7128 18.1992 72.4451 20.1012 72.4451 22.2699C72.4451 24.0475 71.9628 25.6295 70.9984 27.016C70.0339 28.4026 68.7123 29.4691 67.0334 30.2157Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M167.39 34.6422H161.389V49.6806H152.762V12.3511H161.389V27.4429H167.604L177.463 12.3511H186.625L174.355 30.376L187 49.6806H177.141L167.39 34.6422Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M95.9298 4.18257C97.5971 4.18257 98.8221 4.53253 99.6047 5.23245C100.399 5.92108 100.796 6.82984 100.796 7.95875C100.796 8.70382 100.597 9.39245 100.2 10.0246C99.8146 10.6455 99.2134 11.1535 98.3967 11.5486C97.5801 11.9325 96.5649 12.1244 95.3513 12.1244C94.512 12.1244 93.6726 12.0341 92.8333 11.8534C92.0053 11.6728 91.2794 11.4075 90.6555 11.0576L92.0166 8.11115C92.5157 8.39337 93.0374 8.60786 93.5819 8.75462C94.1376 8.90138 94.6764 8.97475 95.1982 8.97475C95.6632 8.97475 96.0375 8.89573 96.3211 8.73768C96.616 8.56835 96.7634 8.33693 96.7634 8.04341C96.7634 7.76119 96.633 7.55234 96.3721 7.41687C96.1112 7.27012 95.6405 7.19674 94.96 7.19674H91.5062L92.1017 0H100.013V3.01416H95.4023L95.3173 4.18257H95.9298Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M88.5805 0V11.8534H84.5653V3.01416H82.5236V0H88.5805Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M109.02 12.359H117.052V49.6802H109.02V12.359Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M125.083 43.1853C135.648 43.1853 144.212 34.6611 144.212 24.1459C144.212 13.6308 135.648 5.10661 125.083 5.10661V12.436C131.58 12.436 136.848 17.6787 136.848 24.1459C136.848 30.6131 131.58 35.8558 125.083 35.8558V43.1853Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M100.989 57C90.4236 57 81.859 48.4758 81.859 37.9607C81.859 27.4455 90.4236 18.9213 100.989 18.9213V26.2508C94.4907 26.2508 89.2232 31.4935 89.2232 37.9607C89.2232 44.4279 94.4907 49.6706 100.989 49.6706V57Z"
        fill="white"
        fillOpacity="0.25"
      />
    </svg>
  );
};
export default SecondaryLogo;
