import { useEffect } from "react";

import useAppDispatch from "@hooks/useAppDispatch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme, useMediaQuery } from "@mui/material";
import { RequestModalSentRequestProps } from "@pages/RequestModal/components/RequestModalSentRequest/RequestModalSentRequestTypes";
import {
  StyledRequestModalSentRequest,
  StyledRequestModalSentRequestTitle,
} from "@pages/RequestModal/components/RequestModalSentRequest/StyledRequestModalSentRequest";
import {
  clearFormalizedAcidId,
  closeRequestModal,
  deleteFormalizedAcids,
  selectAcid,
} from "@redux/slices/requestModal/requestModalSlice";

const RequestModalSentRequest = ({
  setActiveStep,
  setIsChooseTare,
  setIsOrderVerify,
}: RequestModalSentRequestProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(closeRequestModal());
      setActiveStep(0);
    }, 1500);
    dispatch(deleteFormalizedAcids());
    dispatch(clearFormalizedAcidId());
    dispatch(selectAcid(null));
    setIsChooseTare(false);
    setIsOrderVerify(false);
  }, [dispatch, setActiveStep, setIsChooseTare, setIsOrderVerify]);

  return (
    <StyledRequestModalSentRequest>
      <CheckCircleIcon sx={{ color: "#50D890", fontSize: "64px" }} />
      <StyledRequestModalSentRequestTitle
        isMobileScreen={isMobileScreen}
        variant="h2"
        fontWeight={isMobileScreen ? "fontWeightBold" : "fontWeightRegular"}
      >
        Заявка отправлена!
      </StyledRequestModalSentRequestTitle>
    </StyledRequestModalSentRequest>
  );
};
export default RequestModalSentRequest;
