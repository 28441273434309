import CustomImage from "@components/CustomImage/CustomImage";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Stack, styled } from "@mui/material";

export const StyledRequestModalShortAcidInfoImageWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "isTabletScreen",
})<{ isTabletScreen: boolean }>`
  overflow: hidden;
  display: flex;
  justify-content: ${({ isTabletScreen }) =>
    isTabletScreen ? "center" : "flex-start"};
  align-items: center;
  max-height: 330px;
  border-radius: ${borderRadius};
  ${({ isTabletScreen }) => isTabletScreen && "margin: auto"}
`;

export const StyledRequestModalShortAcidInfoBigImage = styled(CustomImage, {
  shouldForwardProp: (prop) => prop !== "isTabletScreen",
})<{ isTabletScreen: boolean }>`
  width: ${({ isTabletScreen }) => (isTabletScreen ? "100%" : "330px")};
  height: ${({ isTabletScreen }) => (isTabletScreen ? "240px" : "330px")};
  object-fit: cover;
  border-radius: ${borderRadius};
`;

export const StyledRequestModalShortAcidInfoBaseInfoStackWrapper = styled(
  Stack,
  { shouldForwardProp: (prop) => prop !== "isMobileScreen" }
)<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) =>
    isMobileScreen && `margin-top: 15px; margin-left: 15px`}
`;
