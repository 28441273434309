import { useCallback, useEffect, useMemo, useState } from "react";

import imageError from "@assets/images/imageError.png";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import {
  AcidTareType,
  MobileButtonConfig,
  ExtraAction,
} from "@customTypes/requestModalTypes";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, Stack, useTheme, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import RequestModalChooseTareExtraActions from "@pages/RequestModal/components/RequestModalChooseTare/components/RequestModalChooseTareExtraActions/RequestModalChooseTareExtraActions";
import {
  AcidTare,
  FormValues,
  FormValuesKeys,
  RequestModalChooseTareProps,
} from "@pages/RequestModal/components/RequestModalChooseTare/RequestModalChooseTareTypes";
import {
  StyledInputElement,
  StyledInputBoxWrapper,
  StyledTareImageBoxWrapper,
  StyledRequestModalChooseTareBoxWrapper,
  StyledTareImage,
  StyledChooseTareTypographyBody2,
  StyledChooseTareTypographyTitle,
  StyledRequestModalChooseTareInput,
  StyledRequestModalChooseTareCustomGridContainer,
} from "@pages/RequestModal/components/RequestModalChooseTare/StyledRequestModalChooseTare";
import RequestModalMobileNavButtons from "@pages/RequestModal/components/RequestModalMobileNavButtons/RequestModalMobileNavButtons";
import RequestModalNavButtonsBoxWrapper from "@pages/RequestModal/components/RequestModalNavButtonsBoxWrapper/RequestModalNavButtonsBoxWrapper";
import RequestModalNavigateButtons from "@pages/RequestModal/components/RequestModalNavigateButtons/RequestModalNavigateButtons";
import { useGetExtraActionsQuery } from "@redux/api/extraActions/extraActionApi";
import {
  createFormalizedAcids,
  formalizedAcidIdIncrement,
} from "@redux/slices/requestModal/requestModalSlice";
import declOfNum from "@utils/declOfNum";
import generateImageSrc from "@utils/generateImageSrc";
import imageOnErrorHandler from "@utils/handleImageError";
import { FormProvider, useForm } from "react-hook-form";

const RequestModalChooseTare = ({
  handleBackButton,
  handleNexButton,
  handleCloseRequestModal,
}: RequestModalChooseTareProps) => {
  /**
   * Theme and screen values
   */
  const theme = useTheme();

  const isSmallMobileScreen = useMediaQuery("(max-width:420px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();

  const methods = useForm<FormValues>({
    defaultValues: {
      "1": false,
      "2": false,
      "3": false,
    },
  });

  const { handleSubmit, watch } = methods;

  /**
   * Local State
   */
  const selectedAcid = useAppSelector(
    (state) => state.requestModal.selectedAcid
  );

  const acidPrice = selectedAcid ? selectedAcid.prices[0].price : null;

  const formalizedAcidId = useAppSelector(
    (state) => state.requestModal.formalizedAcidId
  );

  const extraActionsValues = watch();

  const [activeTare, setActiveTare] = useState<AcidTare | null>(null);
  const [tareCount, setTareCount] = useState<string | number>("1");

  /**
   * Services
   */
  const {
    data: fetchedExtraActions,
    isLoading: isExtraActionsLoading,
    isError: isExtraActionsError,
  } = useGetExtraActionsQuery();

  /**
   * Transform ExtraActions to Object
   */
  const fetchedExtraActionsObj = useMemo(
    () =>
      fetchedExtraActions?.reduce<{
        [key: string]: ExtraAction;
      }>((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {}),
    [fetchedExtraActions]
  );

  /**
   * Component`s useEffects
   */
  useEffect(() => {
    if (!selectedAcid) return;
    setActiveTare(selectedAcid.availableTares[0]);
  }, [selectedAcid]);

  /**
   * Calculating price and weight
   */

  const totalAcidWeight = useMemo(() => {
    if (!selectedAcid || !activeTare?.volume) return;
    return Math.ceil(
      Number(tareCount) * activeTare.volume * selectedAcid.acidDensity
    );
  }, [activeTare?.volume, selectedAcid, tareCount]);

  //?NOTE: if the price of extraAction = 0, it is circulating tare and we return 0
  const tarePrice = useMemo(() => {
    if (
      activeTare?.tarePrice.price === null ||
      activeTare?.tarePrice.price === undefined
    )
      return;

    if (!fetchedExtraActionsObj) return;

    for (let key in extraActionsValues) {
      if (!extraActionsValues[key as FormValuesKeys]) continue;

      if (fetchedExtraActionsObj[key].price.price === 0) return 0;
    }

    return Number(tareCount) * activeTare.tarePrice.price;
  }, [
    activeTare?.tarePrice.price,
    extraActionsValues,
    fetchedExtraActionsObj,
    tareCount,
  ]);

  const extraActionsSum: number | undefined = useMemo(() => {
    if (!activeTare) return;
    if (!fetchedExtraActionsObj) return;

    let totalSum = 0;

    for (let key in extraActionsValues) {
      if (!extraActionsValues[key as FormValuesKeys]) continue;

      totalSum += fetchedExtraActionsObj[key].price.price;
    }

    if (activeTare.name.includes(AcidTareType.Cube)) {
      return Number(tareCount) * totalSum;
    }

    if (activeTare.name.includes(AcidTareType.Canister)) {
      return Math.ceil(Number(tareCount) / 30) * totalSum;
    }

    return totalAcidWeight ? Math.ceil(totalAcidWeight / 1000) * totalSum : 0;
  }, [
    activeTare,
    extraActionsValues,
    fetchedExtraActionsObj,
    tareCount,
    totalAcidWeight,
  ]);

  const totalPrice = useMemo(() => {
    if (
      typeof extraActionsSum === "undefined" ||
      typeof totalAcidWeight === "undefined" ||
      typeof tarePrice === "undefined" ||
      !acidPrice
    )
      return;
    return acidPrice * totalAcidWeight + tarePrice + extraActionsSum;
  }, [acidPrice, extraActionsSum, tarePrice, totalAcidWeight]);

  const declOfActiveTare = () => {
    if (activeTare?.name.includes(AcidTareType.Cube)) {
      return declOfNum(Number(tareCount), ["куб", "куба", "кубов"], true);
    }

    if (activeTare?.name.includes(AcidTareType.Canister)) {
      return declOfNum(
        Number(tareCount),
        ["канистра", "канистры", "канистр"],
        true
      );
    }

    return declOfNum(Number(tareCount), ["мешок", "мешка", "мешков"], true);
  };

  const tarePriceTitle = () => {
    if (Number(tareCount) === 0 || tarePrice === 0)
      return `Стоимость: ${tarePrice} ₽`;
    return `Стоимость ${tareCount + " " + declOfActiveTare()}: ${tarePrice} ₽`;
  };

  /**
   * Handlers
   */
  const handleChooseTare = (index: number) => {
    if (!selectedAcid) return;
    setActiveTare(selectedAcid.availableTares[index]);
  };

  const handleAddTareCount = () => {
    setTareCount((prev) => Number(prev) + 1);
  };

  const handleRemoveTareCount = () => {
    if (tareCount === "1" || tareCount === 1) return;
    setTareCount((prev) => Number(prev) - 1);
  };

  const handleBlurInputTareCount = () => {
    if (tareCount === "" || tareCount === "0" || Number(tareCount) < 0) {
      setTareCount("1");
    }
  };

  const handleInputTareCountChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let newValue = event.currentTarget.value;
    newValue = newValue.replace(/\D/g, "");
    setTareCount(newValue);
  };

  const handleSubmitFormData = useCallback(
    (extraActions: FormValues) => {
      const extraActionsKeys = Object.keys(extraActions);
      const formalizedAcidExtraActions = extraActionsKeys
        .filter((key) => extraActions[key as FormValuesKeys] === true)
        .map((item) => {
          return { id: Number(item) };
        });
      if (
        !selectedAcid ||
        !activeTare ||
        typeof totalPrice === "undefined" ||
        typeof totalAcidWeight === "undefined"
      )
        return;
      const acidIdOfFormalizedAcids = { id: selectedAcid.id };
      const acidTareIdOfFormalizedAcids = { id: activeTare.id };
      dispatch(formalizedAcidIdIncrement());
      dispatch(
        createFormalizedAcids({
          acid: acidIdOfFormalizedAcids,
          image: selectedAcid.image,
          formalizedAcidId: formalizedAcidId,
          name: selectedAcid.name,
          extraActions: formalizedAcidExtraActions,
          sum: totalPrice,
          mass: totalAcidWeight,
          tareCount: Number(tareCount),
          tare: acidTareIdOfFormalizedAcids,
        })
      );
      handleNexButton();
    },
    [
      activeTare,
      dispatch,
      formalizedAcidId,
      handleNexButton,
      selectedAcid,
      tareCount,
      totalAcidWeight,
      totalPrice,
    ]
  );

  /**
   * Layout
   */
  const tareHelperText = () => {
    if (!isMobileScreen) {
      return (
        <>
          <StyledChooseTareTypographyBody2
            variant="body2"
            isMobileScreen={isMobileScreen}
          >
            В одном еврокубе 1000 литров.
          </StyledChooseTareTypographyBody2>

          <StyledChooseTareTypographyBody2
            variant="body2"
            isMobileScreen={isMobileScreen}
          >
            В одной канистре 21,5 литр.
          </StyledChooseTareTypographyBody2>
        </>
      );
    }

    if (activeTare?.name === AcidTareType.Cube) {
      return (
        <StyledChooseTareTypographyBody2
          variant="body2"
          isMobileScreen={isMobileScreen}
        >
          В одном еврокубе 1000 литров.
        </StyledChooseTareTypographyBody2>
      );
    }
    if (activeTare?.name === AcidTareType.Canister) {
      return (
        <StyledChooseTareTypographyBody2
          variant="body2"
          isMobileScreen={isMobileScreen}
        >
          В одной канистре 21,5 литр.
        </StyledChooseTareTypographyBody2>
      );
    }
  };

  const tareImages = selectedAcid?.availableTares.map((tare, index) => {
    const chosenTare = activeTare?.id === tare.id ? true : false;

    const tareImage =
      tare.file && tare.file.name
        ? generateImageSrc(tare.file.name)
        : imageError;

    return (
      <StyledTareImageBoxWrapper
        key={tare.id}
        isMobileScreen={isMobileScreen}
        chosenTare={chosenTare}
        onClick={() => handleChooseTare(index)}
      >
        <StyledTareImage
          alt={tare.name}
          src={tareImage}
          onError={(event) => imageOnErrorHandler(event)}
        />
      </StyledTareImageBoxWrapper>
    );
  });

  const mobileNavigationButtonsConfig: MobileButtonConfig[] = useMemo(
    () => [
      {
        icon: <ArrowBackIcon />,
        onClick: handleBackButton,
        background: theme.palette.secondary.main,
        textColor: "#F6F7F8",
      },
      {
        icon: <ArrowForwardIcon />,
        onClick: () => {},
        background: theme.palette.secondary.main,
        textColor: "#F6F7F8",
        type: "submit",
        form: "extraActions",
      },
    ],
    [handleBackButton, theme.palette.secondary.main]
  );

  return (
    <>
      <Grid2 container spacing={isMobileScreen ? 2 : 4} disableEqualOverflow>
        <Grid2 xs={12} lg={6} xl={5}>
          <StyledRequestModalChooseTareBoxWrapper
            isMobileScreen={isMobileScreen}
          >
            <Stack
              direction="column"
              spacing={isMobileScreen ? "20px" : "47px"}
            >
              <StyledChooseTareTypographyTitle
                variant="h5"
                isMobileScreen={isMobileScreen}
              >
                Выберите тару:
              </StyledChooseTareTypographyTitle>

              <StyledRequestModalChooseTareCustomGridContainer
                isMobileScreen={isMobileScreen}
              >
                {tareImages}
              </StyledRequestModalChooseTareCustomGridContainer>

              <Stack
                direction="column"
                spacing={isMobileScreen ? "5px" : " 47px"}
              >
                <Typography
                  variant={isMobileScreen ? "body2" : "requestModalWindowBody1"}
                >
                  {tarePriceTitle()}
                </Typography>

                <div>
                  {tareHelperText()}

                  <StyledChooseTareTypographyBody2
                    variant="body2"
                    isMobileScreen={isMobileScreen}
                  >
                    Конечный вес не включает в себя вес тары.
                  </StyledChooseTareTypographyBody2>
                </div>
              </Stack>
            </Stack>
          </StyledRequestModalChooseTareBoxWrapper>
        </Grid2>

        <Grid2 container direction="column" xs={12} lg={6} xl={7}>
          <Grid2 xs={12}>
            <StyledRequestModalChooseTareBoxWrapper
              isMobileScreen={isMobileScreen}
            >
              <Stack
                direction="column"
                spacing={isMobileScreen ? "10px" : "30px"}
              >
                <StyledChooseTareTypographyTitle
                  variant="h5"
                  isMobileScreen={isMobileScreen}
                >
                  Укажите объём:
                </StyledChooseTareTypographyTitle>

                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <StyledInputBoxWrapper isMobileScreen={isMobileScreen}>
                    <StyledRequestModalChooseTareInput
                      value={tareCount}
                      onChange={handleInputTareCountChange}
                      onBlur={handleBlurInputTareCount}
                      disableUnderline
                      type="text"
                      slotProps={{ input: { maxLength: 4 } }}
                      slots={{ input: StyledInputElement }}
                      startAdornment={
                        <IconButton
                          onClick={handleRemoveTareCount}
                          sx={{ padding: isMobileScreen ? 0 : "8px" }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      }
                      endAdornment={
                        <IconButton
                          onClick={handleAddTareCount}
                          sx={{ padding: isMobileScreen ? 0 : "8px" }}
                        >
                          <AddIcon />
                        </IconButton>
                      }
                      isMobileScreen={isMobileScreen}
                    />
                  </StyledInputBoxWrapper>
                  <StyledChooseTareTypographyTitle
                    variant="h4"
                    isMobileScreen={isMobileScreen}
                  >
                    {declOfActiveTare()} ={" "}
                    {isMobileScreen && (
                      <>
                        <br />
                      </>
                    )}
                    {totalAcidWeight} кг
                  </StyledChooseTareTypographyTitle>
                </Stack>

                <StyledChooseTareTypographyBody2
                  variant="body2"
                  isMobileScreen={isMobileScreen}
                >
                  Объём тары х плотность продукта = конечный вес.
                </StyledChooseTareTypographyBody2>
              </Stack>
            </StyledRequestModalChooseTareBoxWrapper>
          </Grid2>

          <Grid2 xs={12}>
            <StyledRequestModalChooseTareBoxWrapper
              isMobileScreen={isMobileScreen}
              sx={{ marginBottom: isTabletScreen ? 10 : 0 }}
            >
              <Stack direction="column" spacing={"30px"}>
                <StyledChooseTareTypographyTitle
                  isMobileScreen={isMobileScreen}
                  variant="h5"
                >
                  {isMobileScreen
                    ? "Укажите доп. услуги:"
                    : "Дополнительные услуги:"}
                </StyledChooseTareTypographyTitle>

                <WithLoader isLoading={isExtraActionsLoading}>
                  <WithErrorMessage isError={isExtraActionsError}>
                    {fetchedExtraActions && (
                      <FormProvider {...methods}>
                        <form
                          id="extraActions"
                          onSubmit={handleSubmit(handleSubmitFormData)}
                        >
                          <Stack
                            direction="column"
                            spacing={isMobileScreen ? "15px" : 0}
                          >
                            <RequestModalChooseTareExtraActions
                              items={fetchedExtraActions}
                            />
                          </Stack>
                        </form>
                      </FormProvider>
                    )}
                  </WithErrorMessage>
                </WithLoader>
              </Stack>
            </StyledRequestModalChooseTareBoxWrapper>
          </Grid2>
        </Grid2>
      </Grid2>

      {!isTabletScreen && (
        <Stack sx={{ marginTop: "30px" }} spacing={2}>
          <Typography variant="h4">Итого: {totalPrice} ₽ с НДС</Typography>
          <Typography variant="body2" color="text.secondary">
            Кол-во продукта х цена продукта + доп. услуги = итоговая стоимость.
          </Typography>
        </Stack>
      )}

      <RequestModalNavButtonsBoxWrapper
        isTabletScreen={isTabletScreen}
        isSmallMobileScreen={isSmallMobileScreen}
      >
        {isTabletScreen ? (
          <RequestModalMobileNavButtons
            handleCloseRequestModal={handleCloseRequestModal}
            buttonsConfig={mobileNavigationButtonsConfig}
          />
        ) : (
          <RequestModalNavigateButtons
            handleBackButton={handleBackButton}
            nextButtonTitle="Продолжить"
            withBackButton
            nextButtonType="submit"
            nextButtonForm="extraActions"
          />
        )}
      </RequestModalNavButtonsBoxWrapper>
    </>
  );
};
export default RequestModalChooseTare;
