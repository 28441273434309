import Link from "@components/Link/Link";
import Table from "@components/Table/Table";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";

export const StyledAcidPageTable = styled(Table, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${borderRadius};
  padding: ${({ theme, isMobileScreen }) =>
    isMobileScreen ? theme.spacing(4) : theme.spacing(7)};
  max-width: 1140px;
`;

export const StyledAcidPageDownloadLink = styled(Link)`
  cursor: pointer;
`;
