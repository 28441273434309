import { useMemo } from "react";

import Typography from "@components/Typography/Typography";
import { FormControlLabel, useTheme, useMediaQuery } from "@mui/material";
import { RequestModalChooseTareExtraActionsProps } from "@pages/RequestModal/components/RequestModalChooseTare/components/RequestModalChooseTareExtraActions/RequestModalChooseTareExtraActionsTypes";
import {
  StyledChooseTareExtraActionsCheckbox,
  StyledChooseTareExtraActionsCheckboxMobileLabel,
} from "@pages/RequestModal/components/RequestModalChooseTare/components/RequestModalChooseTareExtraActions/StyledRequestModalChooseTareExtraActions";
import { FormValuesKeys } from "@pages/RequestModal/components/RequestModalChooseTare/RequestModalChooseTareTypes";
import { Controller, useFormContext } from "react-hook-form";

const MuiControlLabelStyles = {
  "&.MuiFormControlLabel-root": {
    marginLeft: 0,
  },
};

const RequestModalChooseTareExtraActions = ({
  items,
}: RequestModalChooseTareExtraActionsProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { control, watch } = useFormContext();

  const extraActionsValues = watch();

  const extraActions = useMemo(() => {
    return items.map((action) => {
      const isActiveCheckbox =
        extraActionsValues[String(action.id) as FormValuesKeys];

      return (
        <FormControlLabel
          key={action.id}
          control={
            <Controller
              name={String(action.id) as FormValuesKeys}
              control={control}
              render={({ field }) => {
                return (
                  <StyledChooseTareExtraActionsCheckbox
                    isMobileScreen={isMobileScreen}
                    {...field}
                  />
                );
              }}
            />
          }
          label={
            isMobileScreen ? (
              <StyledChooseTareExtraActionsCheckboxMobileLabel
                isActiveCheckbox={isActiveCheckbox}
              >
                {action.name}
              </StyledChooseTareExtraActionsCheckboxMobileLabel>
            ) : (
              <Typography variant="body2" color="text.primary">
                {action.name}
              </Typography>
            )
          }
          sx={{
            ...MuiControlLabelStyles,
          }}
        />
      );
    });
  }, [control, extraActionsValues, isMobileScreen, items]);

  return <>{extraActions}</>;
};
export default RequestModalChooseTareExtraActions;
