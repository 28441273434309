import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Button, Stack, styled } from "@mui/material";

export const StyledRequestModalOrderVerificationTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `font-weight: 700;
  font-size: 24px;
  line-height: 28px;`}
`;

export const StyledRequestModalOrderVerificationButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  border-radius: ${borderRadius};
  background: rgba(26, 115, 232, 0.05);
  border: none;
  font-weight: 400;
  z-index: 999;
  font-size: ${({ isMobileScreen }) => (isMobileScreen ? "16px" : "20px")};
  line-height: ${({ isMobileScreen }) => (isMobileScreen ? "19px" : "23px")};
  padding: 15px;

  :hover {
    border: none;
    background: rgba(26, 115, 232, 0.05);
  }
`;

export const StyledOrderVerificationSelectedAcidsStackContainer = styled(Stack)`
  margin: 30px 0;
  max-height: 400px;
  overflow-y: auto;
`;
