import CategoryCardsGroup from "@components/CategoryCardsGroup/CategoryCardsGroup";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Box, Stack, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useGetCategoriesQuery } from "@redux/api/categories/categoriesApi";

const MainProducts = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: fetchedCategories,
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
  } = useGetCategoriesQuery();

  return (
    <Box sx={{ marginTop: isMobileScreen ? "60px" : "80px" }}>
      <Stack direction="column">
        <Box>
          <Typography
            variant="h2"
            fontWeight={theme.typography.fontWeightBold}
            fontSize={isMobileScreen ? "32px" : "48px"}
            lineHeight={isMobileScreen ? "38px" : "56px"}
          >
            Основная продукция
          </Typography>
        </Box>

        <Box sx={{ marginTop: isMobileScreen ? "20px" : "60px" }}>
          <WithLoader isLoading={isCategoriesLoading}>
            <WithErrorMessage
              isError={isCategoriesError}
              stackProps={{
                style: { margin: isMobileScreen ? "90px 0" : "200px 0" },
              }}
            >
              {fetchedCategories && (
                <CategoryCardsGroup
                  categories={fetchedCategories.slice(0, 4)}
                />
              )}
            </WithErrorMessage>
          </WithLoader>
        </Box>
      </Stack>
    </Box>
  );
};
export default MainProducts;
