import CustomImage from "@components/CustomImage/CustomImage";
import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Box, styled } from "@mui/material";

export const StyledRequestModalSelectedAcidBlock = styled(Box)`
  border-radius: ${borderRadius};
  padding: 25px;
  background: ${({ theme }) => theme.palette.common.white};
`;

export const StyledRequestModalSelectedAcidBlockWeightTitle = styled(
  Typography,
  { shouldForwardProp: (prop) => prop !== "isTabletScreen" }
)<{ isTabletScreen: boolean }>`
  text-decoration: underline;
  ${({ isTabletScreen, theme }) =>
    isTabletScreen &&
    `font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-decoration: none;
  color: ${theme.palette.text.primary};`}
`;

export const StyledRequestModalSelectedAcidBlockImgWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  width: 150px;
  height: 150px;
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `width: 90px;
  height: 90px;`}
`;

export const StyledRequestModalSelectedAcidBlockImage = styled(CustomImage, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ isMobileScreen }) =>
    isMobileScreen ? "10px" : borderRadius};
`;

export const StyledRequestModalSelectedAcidBlockAcidTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `font-weight: 400;
  font-size: 16px;
  line-height: 19px;`}
`;

export const StyledRequestModalSelectedAcidBlockAdditionalInfo = styled(
  Typography,
  { shouldForwardProp: (prop) => prop !== "isMobileScreen" }
)<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `font-size: 14px;
  line-height: 16px;`}
`;
