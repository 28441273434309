import newsBlock from "@assets/images/newsBlock.png";
import newsBlockSmall from "@assets/images/newsBlockSmall.png";
import StyledMobileScreenTitle from "@components/MobileScreenTitle/StyledMobileScreenTitle";
import Typography from "@components/Typography/Typography";
import { Box, Stack, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  StyledNewsBlock,
  StyledNewsBlockImage,
  StyledNewsBlockDivider,
  StyledNewsBlockImageContainer,
  StyledNewsBlockInDevelopedButton,
} from "@pages/MainPage/components/NewsBlock/StyledNewsBlock";

const newsItems = [1, 2];

const NewsBlock = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const newsBlockImage = isMobileScreen ? (
    <StyledNewsBlockImage src={newsBlockSmall} alt="Рупор" />
  ) : (
    <StyledNewsBlockImage src={newsBlock} alt="Рупор" />
  );

  const newsBlockTitle = isMobileScreen ? (
    <StyledMobileScreenTitle variant="h3">Новости</StyledMobileScreenTitle>
  ) : (
    <Typography variant="h3">Новости</Typography>
  );

  const newsBlockContent = newsItems.map((item, index) => {
    const isLastChilde = newsItems.length - 1 !== index;
    return (
      <Grid2
        container
        direction="column"
        spacing={2}
        key={item}
        sx={{ paddingLeft: 0 }}
        xs={12}
      >
        <Grid2 xs={4}>
          <Box
            sx={{
              background: "#C7C7C7",
              borderRadius: "10px",
              width: "100%",
              height: "20px",
            }}
          />
        </Grid2>

        <Grid2 xs={12}>
          <Box
            sx={{
              background: "#C7C7C7",
              borderRadius: "10px",
              width: "100%",
              height: "40px",
            }}
          />
        </Grid2>
        {isLastChilde && (
          <Grid2>
            <StyledNewsBlockDivider />
          </Grid2>
        )}
      </Grid2>
    );
  });

  return (
    <>
      <StyledNewsBlock isMobileScreen={isMobileScreen}>
        <Stack direction="column" spacing={isMobileScreen ? "15px" : "30px"}>
          <div>{newsBlockTitle}</div>

          <div>{newsBlockContent}</div>
        </Stack>

        <StyledNewsBlockImageContainer isMobileScreen={isMobileScreen}>
          {newsBlockImage}
        </StyledNewsBlockImageContainer>
      </StyledNewsBlock>

      <StyledNewsBlockInDevelopedButton
        isMobileScreen={isMobileScreen}
        isLaptopScreen={isLaptopScreen}
      >
        Раздел в разработке
      </StyledNewsBlockInDevelopedButton>
    </>
  );
};
export default NewsBlock;
