import { GetExtraActionsApiResponse } from "@customTypes/apiTypes/models/extraActions.model";
import { ExtraActionApiPaths } from "@redux/api/extraActions/extraActionApiPaths";
import generalApi from "@redux/api/initialApi";

export const extraActionApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getExtraActions: builder.query<GetExtraActionsApiResponse, void>({
      query: () => ExtraActionApiPaths.ExtraActionsAll,
    }),
  }),
});

export const { useGetExtraActionsQuery } = extraActionApi;
