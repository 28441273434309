import {
  RequestModalState,
  SelectedAcid,
  FormalizedAcid,
  OrderElement,
} from "@redux/slices/requestModal/requestModalSliceTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//?NOTE formalizedAcidId created, because we don`t have cart
//? and we need to have any id to differ the same selected acids

const initialState: RequestModalState = {
  acidIdFromPage: null,
  requestModalOpenValue: false,
  selectedAcid: null,
  formalizedAcids: null,
  formalizedAcidId: 1,
  orderBody: {
    orderElements: [],
    sum: 0,
  },
};

const requestModalSlice = createSlice({
  name: "requestModalSlice",
  initialState,
  reducers: {
    openRequestModal: (state) => {
      state.requestModalOpenValue = true;

      if (window.innerWidth < 1536) {
        const scrollY =
          document.documentElement.style.getPropertyValue("--scroll-y");
        const body = document.body;
        body.style.position = "fixed";
        body.style.top = `-${scrollY}`;
      }
    },
    closeRequestModal: (state) => {
      if (window.innerWidth < 1536) {
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = "";
        body.style.top = "";
        body.style.right = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }

      state.requestModalOpenValue = false;
    },
    selectAcid: (state, action: PayloadAction<SelectedAcid | null>) => {
      state.selectedAcid = action.payload;
    },
    createFormalizedAcids: (state, action: PayloadAction<FormalizedAcid>) => {
      state.formalizedAcids =
        state.formalizedAcids === null
          ? [action.payload]
          : [...state.formalizedAcids, action.payload];
    },
    deleteFormalizedAcid: (state, action: PayloadAction<number>) => {
      state.formalizedAcids =
        state.formalizedAcids === null
          ? state.formalizedAcids
          : [
              ...state.formalizedAcids.filter(
                (acid) => acid.formalizedAcidId !== action.payload
              ),
            ];
    },
    deleteFormalizedAcids: (state) => {
      state.formalizedAcids = null;
    },
    createOrderBodyOrderElements: (
      state,
      action: PayloadAction<OrderElement[]>
    ) => {
      state.orderBody.orderElements = action.payload;
    },
    createOrderBodyTotalSum: (state, action: PayloadAction<number>) => {
      state.orderBody.sum = action.payload;
    },
    formalizedAcidIdIncrement: (state) => {
      state.formalizedAcidId = ++state.formalizedAcidId;
    },
    clearFormalizedAcidId: (state) => {
      state.formalizedAcidId = 1;
    },
    setAcidIdFromPage: (state, action: PayloadAction<number>) => {
      state.acidIdFromPage = action.payload;
    },
    clearAcidIdFromPage: (state) => {
      state.acidIdFromPage = null;
    },
  },
});

export const {
  openRequestModal,
  closeRequestModal,
  selectAcid,
  createFormalizedAcids,
  deleteFormalizedAcid,
  deleteFormalizedAcids,
  formalizedAcidIdIncrement,
  clearFormalizedAcidId,
  createOrderBodyOrderElements,
  createOrderBodyTotalSum,
  setAcidIdFromPage,
  clearAcidIdFromPage,
} = requestModalSlice.actions;

export default requestModalSlice.reducer;
