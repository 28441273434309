import Button from "@components/Button/Button";
import Drawer from "@components/Drawer/Drawer";
import Link from "@components/Link/Link";
import { borderRadius } from "@constants/constants.style";
import { styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export const StyledDrawerNavItemsContainer = styled(Grid)`
  margin-bottom: 48px;
`;

export const StyledHeaderNavigationDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    overflow: hidden;
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
  }
`;

export const StyledButtonLinksContainer = styled(Grid)`
  margin-bottom: 16px;
`;

export const StyledButtonLink = styled(Button)`
  border-radius: 50px;
`;

export const StyledLink = styled(Link)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: 20px;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledHeaderNavigationButtonsContainer = styled(Grid)`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0 12px;
`;

export const StyledHeaderDrawerNavigationButtonsContainer = styled(Grid)`
  margin-bottom: 20px;
  padding: 0 12px;
`;
