import { StyledHeaderTopInfoLink } from "@components/Header/components/HeaderTopInfo/StyledHeaderTopInfo";
import Typography from "@components/Typography/Typography";
import { INN, MAIL, OGRN, TELEPHONE } from "@constants/constants.common";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/system";

const HeaderTopInfo = () => {
  const theme = useTheme();

  return (
    <Grid container justifyContent="space-between">
      <Grid container spacing={"20px"}>
        <Grid>
          <Typography variant="body1" color={theme.palette.text.secondary}>
            {INN}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body1" color={theme.palette.text.secondary}>
            {OGRN}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid>
          <StyledHeaderTopInfoLink
            href={`tel: ${TELEPHONE}`}
            variant="body1"
            fontWeight={700}
            underline="none"
          >
            {TELEPHONE}
          </StyledHeaderTopInfoLink>
        </Grid>
        <Grid>
          <StyledHeaderTopInfoLink
            href={`mailto: ${MAIL}`}
            fontWeight={700}
            variant="body1"
            underline="none"
          >
            {MAIL}
          </StyledHeaderTopInfoLink>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HeaderTopInfo;
