import logoPlate from "@assets/images/logoPlate.png";
import logoPlateWebp from "@assets/images/logoPlate.webp";
import Typography from "@components/Typography/Typography";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  StyledAboutUsPageTextBlockWrapper,
  StyledAboutUsPageSubText,
  StyledAboutUsPageLogoImage,
  StyledAboutUsPageLogoImageGridWrapper,
  StyledAboutUsPageTitle,
  StyledAboutUsPageContainer,
  StyledAboutUsPageDownloadLink,
  StyledAboutUsPageGridContainerRelative,
} from "@pages/AboutUsPage/StyledAboutUsPage";

const textAboutQualityOurProduct = `Мы — сертифицированный дилер ТОО “Казфосфат”. Вся продукция проходит проверку качества в нашей лаборатории, что гарантирует высокое качество импортируемых кислот и кислот собственного производства. Качество товаров подтверждается не только актуальной разрешительной документацией, но и десятками постоянных клиентов.`;

const textAboutOurProduct = `В нашем каталоге представлен весь ассортимент фосфоросодержащих продуктов казахского производства по самым низким ценам. Мы также производим растворы кислот любой концентрации и очистку кислот до нужной степени. \n Поставка доступна для всех регионов России.`;

const AboutUsPage = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledAboutUsPageContainer>
      <Box
        sx={{
          marginTop: isMobileScreen ? "30px" : "50px",
          marginBottom: isMobileScreen ? "30px" : "60px",
        }}
      >
        <StyledAboutUsPageTitle isMobileScreen={isMobileScreen} variant="h2">
          О компании
        </StyledAboutUsPageTitle>
      </Box>

      <StyledAboutUsPageGridContainerRelative container alignItems="center">
        <Grid2 md={7} lg={8}>
          <StyledAboutUsPageTextBlockWrapper>
            <Typography
              variant={isMobileScreen ? "h5" : "h4"}
              fontWeight="fontWeightRegular"
            >
              Нижегородский завод фосфорных кислот — крупнейший поставщик
              фосфорных кислот в ПФО, дочерняя организация Воскресенского завода
              фосфорных кислот.
            </Typography>
          </StyledAboutUsPageTextBlockWrapper>
        </Grid2>

        <StyledAboutUsPageLogoImageGridWrapper
          isMobileScreen={isMobileScreen}
          isTabletScreen={isTabletScreen}
          md={6}
          lg={5}
        >
          <picture>
            <source srcSet={logoPlateWebp} type="image/webp" />
            <StyledAboutUsPageLogoImage
              isMobileScreen={isMobileScreen}
              isTabletScreen={isTabletScreen}
              src={logoPlate}
              alt="Logo"
              width={509}
              height={509}
            />
          </picture>
        </StyledAboutUsPageLogoImageGridWrapper>
      </StyledAboutUsPageGridContainerRelative>

      <Box sx={{ marginTop: isMobileScreen ? "30px" : "60px" }}>
        <Grid2 container spacing={"30px"}>
          <Grid2 lg={6}>
            <StyledAboutUsPageTextBlockWrapper background="rgba(26, 115, 232, 0.05);">
              <StyledAboutUsPageSubText
                isMobileScreen={isMobileScreen}
                variant={isMobileScreen ? "body2" : "body1"}
              >
                {textAboutQualityOurProduct}
              </StyledAboutUsPageSubText>
            </StyledAboutUsPageTextBlockWrapper>
          </Grid2>

          <Grid2 lg={6}>
            <StyledAboutUsPageTextBlockWrapper>
              <StyledAboutUsPageSubText
                isMobileScreen={isMobileScreen}
                variant={isMobileScreen ? "body2" : "body1"}
              >
                {textAboutOurProduct}
              </StyledAboutUsPageSubText>
            </StyledAboutUsPageTextBlockWrapper>
          </Grid2>
        </Grid2>
      </Box>

      <Box sx={{ marginTop: isMobileScreen ? "30px" : "90px" }}>
        <Typography variant={isMobileScreen ? "h5" : "h3"}>
          Документация
        </Typography>

        <Box sx={{ marginTop: isMobileScreen ? "30px" : "60px" }}>
          <StyledAboutUsPageDownloadLink
            variant={isMobileScreen ? "body2" : "body1"}
            color="primary.light"
            underline="none"
            download="Карта партнера"
            href="../../../Карта партнера НЗФК.pdf"
            startIcon={
              <DescriptionOutlinedIcon
                fontSize={isMobileScreen ? "small" : "medium"}
              />
            }
          >
            Скачать карту партнёра
          </StyledAboutUsPageDownloadLink>
        </Box>
      </Box>
    </StyledAboutUsPageContainer>
  );
};
export default AboutUsPage;
