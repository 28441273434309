const TwoGisIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="25" fill="#272727" fillOpacity="0.05" />
      <path
        d="M37.7593 33.74C31.9823 33.7694 31.0478 37.3771 30.7363 40.4275L30.5947 41.7767H29.4336L29.292 40.4275C28.9806 37.3771 28.0177 33.7694 22.4389 33.74C21.5044 31.7454 21.108 30.1322 21.108 28.3137C21.108 23.7674 24.7045 19.5143 30.0283 19.5143C35.3522 19.5143 38.892 23.7379 38.892 28.3431C38.892 30.1322 38.7221 31.7454 37.7593 33.74ZM29.9717 14C21.193 14 14 21.4502 14 30.5721C14 39.7235 21.193 47.1736 29.9717 47.1736C38.8353 47.1736 46 39.7235 46 30.5721C46 21.4502 38.8353 14 29.9717 14Z"
        fill="#29B24A"
      />
    </svg>
  );
};
export default TwoGisIcon;
