import { useCallback, useEffect, useMemo, useState } from "react";

import { BreadcrumbsProps } from "@components/Breadcrumbs/BreadcrumbsTypes";
import { StyledBreadcrumbsRouterLink } from "@components/Breadcrumbs/StyledBreadcrumbs";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import { useLazyGetCategoryByFrontNameQuery } from "@redux/api/categories/categoriesApi";
import RoutePaths from "@routes/routePaths";
import { useLocation } from "react-router-dom";

const Breadcrumbs = ({ className, ...rest }: BreadcrumbsProps) => {
  let location = useLocation();

  const [getCategoryByFrontName] = useLazyGetCategoryByFrontNameQuery();

  const [entityName, setEntityName] = useState("");

  const pathnames = useMemo(() => {
    return location.pathname.split("/").filter((x) => x);
  }, [location.pathname]);

  useEffect(() => {
    const fetchedCategory = async () => {
      const fetchedCategory = await getCategoryByFrontName(
        pathnames[1]
      ).unwrap();
      setEntityName(fetchedCategory.productCategories);
    };

    fetchedCategory();
  }, [getCategoryByFrontName, pathnames]);

  const breadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      [RoutePaths.CatalogPage]: "Каталог",
    }),
    []
  );

  /**
   *
   * @param link Route to navigate
   * @param name pathname
   * @returns breadcrumb title
   */
  const breadcrumbLinkTitle = useCallback(
    (link: string, name: string) => {
      if (breadcrumbNameMap[link]) return breadcrumbNameMap[link];

      if (entityName) return entityName;

      return;
    },
    [breadcrumbNameMap, entityName]
  );

  const breadcrumbsLinks = useMemo(() => {
    return pathnames.slice(0, pathnames.length - 1).map((name, index) => {
      const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;

      return (
        <StyledBreadcrumbsRouterLink key={routeTo} to={routeTo}>
          {breadcrumbLinkTitle(routeTo, name)}
        </StyledBreadcrumbsRouterLink>
      );
    });
  }, [breadcrumbLinkTitle, pathnames]);

  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      className={className}
      separator={<KeyboardArrowRightIcon />}
      {...rest}
    >
      <StyledBreadcrumbsRouterLink to="/">Главная</StyledBreadcrumbsRouterLink>
      {breadcrumbsLinks}
    </MUIBreadcrumbs>
  );
};
export default Breadcrumbs;
