import { EventSubscriptionApiPaths } from "@redux/api/eventSubscription/eventSubscriptionApiPaths";
import generalApi from "@redux/api/initialApi";

export const eventSubscription = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    addEmail: builder.mutation<void, string>({
      query: (email) => ({
        url: EventSubscriptionApiPaths.MailingAdd,
        method: "POST",
        body: email,
      }),
    }),
  }),
});

export const { useAddEmailMutation } = eventSubscription;
