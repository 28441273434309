import Link from "@components/Link/Link";
import Typography from "@components/Typography/Typography";
import { PHONE_REGEXP } from "@constants/constants.common";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { ContactInfoBlockProps } from "@pages/ContactPage/components/ContactInfoBlock/ContactInfoBlockTypes";
import { StyledContactInfoBlock } from "@pages/ContactPage/components/ContactInfoBlock/StyledContactInfoBlock";
import formatPhoneNumber from "@utils/formatPhoneNumber";

const ContactInfoBlock = ({
  description,
  title,
  href,
  variant,
  color,
  underline,
  className,
}: ContactInfoBlockProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * If title is phone number return formatPhone(title) else title
   */
  const transformTitle = title.match(PHONE_REGEXP)
    ? formatPhoneNumber(title)
    : title;

  const mainInfo = href ? (
    <Link href={href} variant={variant} color={color} underline={underline}>
      {transformTitle}
    </Link>
  ) : (
    <Typography variant={variant} color={color}>
      {transformTitle}
    </Typography>
  );
  return (
    <StyledContactInfoBlock className={className}>
      <Stack
        spacing={{ xs: 0, sm: 1 }}
        sx={{ padding: isMobileScreen ? "15px" : "25px" }}
      >
        <Typography variant="body2" color={theme.palette.grey[600]}>
          {description}
        </Typography>
        {mainInfo}
      </Stack>
    </StyledContactInfoBlock>
  );
};
export default ContactInfoBlock;
