import { useEffect, useLayoutEffect } from "react";

import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import GoodCard from "@components/GoodCard/GoodCard";
import MainContainer from "@components/MainContainer/MainContainer";
import StyledMobileScreenTitle from "@components/MobileScreenTitle/StyledMobileScreenTitle";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useLazyGetCategoryByFrontNameQuery } from "@redux/api/categories/categoriesApi";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import RoutePaths from "@routes/routePaths";
import { useNavigate, useParams } from "react-router-dom";

const CategoryPage = () => {
  const navigate = useNavigate();

  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isXLScreen = useMediaQuery(theme.breakpoints.down("xl"));

  let { categoryFrontName } = useParams();

  const [
    getCategoryByFrontName,
    {
      data: fetchedCategory,
      isLoading: isCategoryLoading,
      isError: isCategoryError,
      error,
    },
  ] = useLazyGetCategoryByFrontNameQuery();

  useLayoutEffect(() => {
    if (fetchedCategory !== null || undefined) return;

    if (!fetchedCategory || (error as FetchBaseQueryError).status === 404) {
      navigate(RoutePaths.NotFoundPage);
    }
  }, [error, fetchedCategory, navigate]);

  useEffect(() => {
    if (!categoryFrontName) {
      navigate(RoutePaths.NotFoundPage);
      return;
    }

    getCategoryByFrontName(categoryFrontName);
  }, [categoryFrontName, getCategoryByFrontName, navigate]);

  const goods = fetchedCategory?.acids.map((acid) => {
    const acidFile =
      acid.acidFiles.length === 0 ? null : acid.acidFiles[0].name;
    return (
      <Grid key={acid.id} xs={6} sm={12} md={6} lg={4} xl={3}>
        <GoodCard
          acidId={acid.id}
          acidFrontName={acid.frontName}
          name={acid.name}
          prices={acid.prices}
          image={acidFile}
        />
      </Grid>
    );
  });

  const categoryPageTitle = isMobileScreen ? (
    <StyledMobileScreenTitle variant="h2">
      {fetchedCategory?.productCategories}
    </StyledMobileScreenTitle>
  ) : (
    <Typography variant="h2">{fetchedCategory?.productCategories}</Typography>
  );

  return (
    <MainContainer
      disableGutters={!isXLScreen}
      sx={{ padding: isMobileScreen ? "0 15px 0 7.5px" : "24px" }}
    >
      <WithLoader isLoading={isCategoryLoading}>
        <WithErrorMessage
          isError={isCategoryError}
          stackProps={{
            style: { margin: isMobileScreen ? "90px 0" : "200px 0" },
          }}
        >
          <Box sx={{ marginTop: isMobileScreen ? "30px" : "26px" }}>
            <Box sx={{ marginBottom: "15px" }}>
              <Breadcrumbs />
            </Box>

            {categoryPageTitle}
          </Box>

          <Box sx={{ marginTop: isMobileScreen ? "30px" : "60px" }}>
            <Grid
              container
              columnSpacing={isMobileScreen ? "15px" : "30px"}
              rowSpacing={isMobileScreen ? "30px" : "60px"}
            >
              {goods}
            </Grid>
          </Box>
        </WithErrorMessage>
      </WithLoader>

      <Box sx={{ marginTop: isMobileScreen ? "60px" : "85px" }}>
        <Typography
          variant={isMobileScreen ? "body2" : "body1"}
          color={theme.palette.text.secondary}
        >
          Все цены на сайте представлены без учёта доставки. Для ближнего
          зарубежья отгружаем с НДС 0%. Растворы изготавливаются на объем не
          менее 1000 литров.
        </Typography>
      </Box>
    </MainContainer>
  );
};
export default CategoryPage;
