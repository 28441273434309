import {
  CreateFileBody,
  CreateFileResponse,
} from "@customTypes/apiTypes/models/file.model";
import { FileApiPaths } from "@redux/api/file/fileApiPaths";
import generalApi from "@redux/api/initialApi";

export const fileApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    createFile: builder.mutation<CreateFileResponse, CreateFileBody>({
      query: ({ frontName, ...fileBody }) => ({
        url: FileApiPaths.CreateFile,
        method: "POST",
        body: fileBody.file,
        params: { frontName },
      }),
    }),
  }),
});

export const { useCreateFileMutation } = fileApi;
