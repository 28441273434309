import { CreateOrderBodyServerModel } from "@customTypes/apiTypes/models/order.model";
import generalApi from "@redux/api/initialApi";
import { OrderApiPaths } from "@redux/api/order/orderApiPaths";

export const orderApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrder: builder.mutation<void, CreateOrderBodyServerModel>({
      query: ({ ...orderBody }) => ({
        url: OrderApiPaths.Order,
        method: "POST",
        body: orderBody,
      }),
    }),
  }),
});

export const { useCreateOrderMutation } = orderApi;
