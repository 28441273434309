import { useEffect, useLayoutEffect } from "react";
import React from "react";

import BasicAcidBrief from "@components/BasicAcidBrief/BasicAcidBrief";
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import MainContainer from "@components/MainContainer/MainContainer";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { AcidCertificateServerModel } from "@customTypes/apiTypes/models/acid.model";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import {
  StyledAcidPageTable,
  StyledAcidPageDownloadLink,
} from "@pages/AcidPage/StyledAcidPage";
import { useLazyGetAcidByFrontNameQuery } from "@redux/api/acid/acidApi";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import RoutePaths from "@routes/routePaths";
import getDownloadFile from "@utils/getDownloadFile";
import { useNavigate, useParams } from "react-router-dom";

const AcidPage = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const { acidFrontName } = useParams();

  const [
    getAcidByFrontName,
    {
      data: fetchedAcid,
      isLoading: isAcidLoading,
      isError: isAcidError,
      error,
    },
  ] = useLazyGetAcidByFrontNameQuery();

  useLayoutEffect(() => {
    if (fetchedAcid !== null || undefined) return;

    if (!fetchedAcid || (error as FetchBaseQueryError).status === 404) {
      navigate(RoutePaths.NotFoundPage);
    }
  }, [error, fetchedAcid, navigate]);

  useEffect(() => {
    if (!acidFrontName) return;

    getAcidByFrontName(acidFrontName);
  }, [acidFrontName, getAcidByFrontName]);

  const descriptionParagraphs = fetchedAcid?.description.split("\n");

  const handleClickDownloadCertificate = (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    certificate: AcidCertificateServerModel
  ) => {
    event.stopPropagation();
    getDownloadFile(certificate.name, certificate.frontName);
  };

  const description = descriptionParagraphs?.map((item) => (
    <React.Fragment key={item}>
      <Typography
        variant={isMobileScreen ? "body2" : "body1"}
        color={
          isMobileScreen
            ? theme.palette.text.secondary
            : theme.palette.text.primary
        }
      >
        {item}
      </Typography>
    </React.Fragment>
  ));

  const certificatesLinks = () => {
    if (fetchedAcid?.certificates.length === 0) return;

    return fetchedAcid?.certificates.map((elem) => {
      return (
        <React.Fragment key={elem.id}>
          <StyledAcidPageDownloadLink
            onClick={(event) => handleClickDownloadCertificate(event, elem)}
            variant={isMobileScreen ? "body2" : "body1"}
            color="primary.light"
            underline="none"
            startIcon={
              <DescriptionOutlinedIcon
                fontSize={isMobileScreen ? "small" : "medium"}
              />
            }
          >
            Скачать {elem.frontName}
          </StyledAcidPageDownloadLink>
        </React.Fragment>
      );
    });
  };

  return (
    <MainContainer>
      <WithLoader
        isLoading={isAcidLoading}
        stackProps={{
          style: { margin: isMobileScreen ? "90px 0" : "300px 0" },
        }}
      >
        <WithErrorMessage
          isError={isAcidError}
          stackProps={{
            style: { margin: isMobileScreen ? "90px 0" : "300px 0" },
          }}
        >
          <Box
            sx={{
              marginBottom: isMobileScreen ? "30px" : "60px",
              marginTop: isMobileScreen ? "30px" : "50px",
            }}
          >
            <Box sx={{ marginBottom: "15px" }}>
              <Breadcrumbs />
            </Box>

            <Stack direction="column" spacing={1}>
              {fetchedAcid?.name && (
                <Typography variant={isMobileScreen ? "h5" : "h3"}>
                  {fetchedAcid?.name}
                </Typography>
              )}
              <Typography
                variant={isMobileScreen ? "body2" : "body1"}
                color={theme.palette.text.secondary}
              >
                {fetchedAcid?.gost}
              </Typography>
            </Stack>
          </Box>

          <Box>
            <BasicAcidBrief
              acidId={fetchedAcid?.id}
              characteristics={fetchedAcid?.characteristics}
              prices={fetchedAcid?.prices}
              count={fetchedAcid?.count}
              images={fetchedAcid?.acidFiles}
              manufacturer={fetchedAcid?.manufacturer}
            />
          </Box>

          <Box sx={{ marginTop: isMobileScreen ? 4 : 10 }}>
            <Stack direction="column" spacing={isMobileScreen ? 1 : 7}>
              <Typography variant={isMobileScreen ? "h5" : "h3"}>
                Описание
              </Typography>

              <Stack direction="column" spacing={2}>
                {description}
              </Stack>
            </Stack>
          </Box>

          <Box sx={{ marginTop: 11 }}>
            {fetchedAcid?.characteristics && (
              <StyledAcidPageTable
                isMobileScreen={isMobileScreen}
                tableData={fetchedAcid.characteristics}
                title="Характеристики"
              />
            )}
          </Box>

          {certificatesLinks() && (
            <Box sx={{ marginTop: isMobileScreen ? 4 : 11 }}>
              <Stack spacing={isMobileScreen ? 4 : 7}>
                <Typography variant={isMobileScreen ? "h5" : "h3"}>
                  Документация
                </Typography>

                <Stack spacing={isMobileScreen ? 2 : 4}>
                  {certificatesLinks()}
                </Stack>
              </Stack>
            </Box>
          )}
        </WithErrorMessage>
      </WithLoader>
    </MainContainer>
  );
};
export default AcidPage;
