const RequestIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.12 4.21L14.29 1.38C13.7279 0.81723 12.9654 0.5007 12.17 0.5H5.83C5.03462 0.5007 4.27207 0.81723 3.71 1.38L0.88 4.21C0.31723 4.77207 0.000700208 5.53462 0 6.33V15.5C0 16.2956 0.316071 17.0587 0.87868 17.6213C1.44129 18.1839 2.20435 18.5 3 18.5H15C15.7956 18.5 16.5587 18.1839 17.1213 17.6213C17.6839 17.0587 18 16.2956 18 15.5V6.33C17.9993 5.53462 17.6828 4.77207 17.12 4.21ZM5.12 2.79C5.31163 2.60727 5.56523 2.50368 5.83 2.5H12.17C12.4348 2.50368 12.6884 2.60727 12.88 2.79L14.59 4.5H3.41L5.12 2.79ZM15 16.5H3C2.73478 16.5 2.48043 16.3946 2.29289 16.2071C2.10536 16.0196 2 15.7652 2 15.5V6.5H16V15.5C16 15.7652 15.8946 16.0196 15.7071 16.2071C15.5196 16.3946 15.2652 16.5 15 16.5Z"
        fill="#F6F7F8"
      />
      <path
        d="M12 8.50002C11.7348 8.50002 11.4804 8.60537 11.2929 8.79291C11.1054 8.98045 11 9.2348 11 9.50002C11 10.0304 10.7893 10.5392 10.4142 10.9142C10.0391 11.2893 9.53043 11.5 9 11.5C8.46957 11.5 7.96086 11.2893 7.58579 10.9142C7.21071 10.5392 7 10.0304 7 9.50002C7 9.2348 6.89464 8.98045 6.70711 8.79291C6.51957 8.60537 6.26522 8.50002 6 8.50002C5.73478 8.50002 5.48043 8.60537 5.29289 8.79291C5.10536 8.98045 5 9.2348 5 9.50002C5 10.5609 5.42143 11.5783 6.17157 12.3284C6.92172 13.0786 7.93913 13.5 9 13.5C10.0609 13.5 11.0783 13.0786 11.8284 12.3284C12.5786 11.5783 13 10.5609 13 9.50002C13 9.2348 12.8946 8.98045 12.7071 8.79291C12.5196 8.60537 12.2652 8.50002 12 8.50002Z"
        fill="#F6F7F8"
      />
    </svg>
  );
};
export default RequestIcon;
