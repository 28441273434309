import AvatarBodyIcon from "@assets/icons/AvatarBodyIcon/AvatarBodyIcon";
import AvatarHeadIcon from "@assets/icons/AvatarHeadIcon/AvatarHeadIcon";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export const StyledMessengersBlockGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  border-radius: ${borderRadius};
  position: relative;
  background: #2727270d;
  padding: ${({ isMobileScreen }) => (isMobileScreen ? "32px" : "60px 81px")};
`;

export const StyledMessengersBlockAvatarHeadIcon = styled(AvatarHeadIcon)`
  position: absolute;
  left: 0;
`;

export const StyledMessengersBlockAvatarBodyIcon = styled(AvatarBodyIcon)`
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom-left-radius: ${borderRadius};
`;

export const StyledMessengersBlockMobileScreenImageGrid = styled(Grid)`
  position: absolute;
  right: -4%;
  bottom: -14%;
`;
