const GMapsIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="25" fill="#272727" fillOpacity="0.05" />
      <path
        d="M25.1163 37.7056C26.0997 38.9404 27.1014 40.493 27.6256 41.4308C28.2639 42.65 28.5286 43.4731 29.0035 44.9476C29.2811 45.7551 29.5458 46 30.1011 46C30.7083 46 30.986 45.5884 31.1988 44.9476C31.6425 43.5643 31.985 42.5119 32.5274 41.509C34.6189 37.5493 38.0286 34.7359 39.9489 30.8805C39.9489 30.8805 41.2126 28.5256 41.2126 25.2276C41.2126 22.1537 39.967 20.0176 39.967 20.0176L25.124 37.7317L25.1163 37.7056Z"
        fill="#34A853"
      />
      <path
        d="M20.0821 30.4636C21.2783 33.1989 23.5593 35.5955 25.1162 37.7056L33.3681 27.8847C33.3681 27.8847 32.203 29.4164 30.0985 29.4164C27.7527 29.4164 25.8428 27.5408 25.8428 25.1703C25.8428 23.5395 26.8107 22.4089 26.8107 22.4089C20.7386 23.3155 21.0759 24.7925 20.0639 30.4584L20.0821 30.4636Z"
        fill="#FBBC04"
      />
      <path
        d="M33.4719 14.5132C36.2225 15.4015 38.558 17.2745 39.9592 20.0098L33.3681 27.903C33.3681 27.903 34.336 26.7672 34.336 25.1416C34.336 22.7216 32.3016 20.8955 30.1063 20.8955C28.0226 20.8955 26.8367 22.409 26.8367 22.409C27.3427 21.2524 32.5715 14.9065 33.4797 14.5158L33.4719 14.5132Z"
        fill="#4285F4"
      />
      <path
        d="M21.5872 17.9857C23.2272 16.0267 26.1023 14 30.0726 14C31.9902 14 33.446 14.5106 33.446 14.5106L26.8289 22.4038C26.3826 22.1607 22.0283 18.7568 21.5872 17.9753V17.9857Z"
        fill="#1A73E8"
      />
      <path
        d="M20.0821 30.4637C20.0821 30.4637 19 28.3067 19 25.2016C19 22.2579 20.147 19.6789 21.5949 18.0117L26.8367 22.4402L20.0899 30.4637H20.0821Z"
        fill="#EA4335"
      />
    </svg>
  );
};
export default GMapsIcon;
