import { TooltipTextProps } from "@components/TooltipText/TooltipTextTypes";
import Typography from "@components/Typography/Typography";
import Tooltip from "@mui/material/Tooltip";
import stringTransformationForTooltip from "@utils/stringTransformationForTooltip";

const TooltipText = ({
  text,
  variant,
  color,
  maxTextLength,
  fontSize,
  lineHeight,
}: TooltipTextProps) => {
  return (
    <Tooltip title={text}>
      <Typography
        variant={variant}
        color={color}
        fontSize={fontSize}
        lineHeight={lineHeight}
      >
        {stringTransformationForTooltip(text, maxTextLength)}
      </Typography>
    </Tooltip>
  );
};
export default TooltipText;
