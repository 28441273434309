import Typography from "@components/Typography/Typography";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled, Checkbox } from "@mui/material";

export const StyledChooseTareExtraActionsCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  padding: ${({ theme }) => theme.spacing(1)};
  ${({ isMobileScreen }) => isMobileScreen && "display: none;"}
`;

export const StyledChooseTareExtraActionsCheckboxMobileLabel = styled(
  Typography,
  { shouldForwardProp: (prop) => prop !== "isActiveCheckbox" }
)<{ isActiveCheckbox: boolean }>`
  background: rgba(26, 115, 232, 0.05);
  border-radius: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 15px 30px;
  ${({ isActiveCheckbox }) =>
    isActiveCheckbox && "background: #1766ce; color: #fff;"}
`;
