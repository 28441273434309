import { NavigationItemsProps } from "@components/NavigationItems/NavigationItemsTypes";
import { StyledNavigationItem } from "@components/NavigationItems/StyledNavigationItems";
import RouterLink from "@components/RouterLink/RouterLink";
import Grid from "@mui/material/Unstable_Grid2";

const NavigationItems = ({
  navigationItems,
  color,
  underline,
}: NavigationItemsProps) => {
  const navigationContent = navigationItems.map((item) => (
    <Grid key={item.title}>
      <StyledNavigationItem
        variant="body1"
        color={color}
        withUnderline={underline}
      >
        <RouterLink color={color} to={item.to}>
          {item.title}
        </RouterLink>
      </StyledNavigationItem>
    </Grid>
  ));

  return <>{navigationContent}</>;
};
export default NavigationItems;
