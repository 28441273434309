import Button from "@components/Button/Button";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";

export const StyledFooter = styled("footer", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme }) => theme.palette.secondary.main};
  margin-top: ${({ isMobileScreen }) => (isMobileScreen ? "90px" : "120px")};
  padding: ${({ isMobileScreen }) =>
    isMobileScreen ? "32px 15px 128px 15px" : "96px"};
`;

export const StyledFooterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${borderRadius};
  border-color: ${({ theme }) => theme.palette.common.white};
  border-width: 2px;
  font-size: ${({ isMobileScreen }) => (isMobileScreen ? "16px" : "20px")};
  line-height: ${({ isMobileScreen }) => (isMobileScreen ? "19px" : "23px")};
  font-weight: 400;
  max-width: 360px;
  padding: 20px 4px;
  :hover {
    border-color: inherit;
    background-color: inherit;
    border-width: 2px;
  }
`;
