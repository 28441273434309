import React, { useEffect, useState } from "react";

import CustomPopup from "@components/CustomPopup/CustomPopup";
import Footer from "@components/Footer/Footer";
import Header from "@components/Header";
import ScrollToTop from "@components/ScrollToTop/ScrollToTop";
import theme from "@config/theme";
import { GLOBAL_STYLES } from "@constants/constants.style";
import { InfoMessageServerModel } from "@customTypes/apiTypes/models/infoMessage.model";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";
import RequestModal from "@pages/RequestModal/RequestModal";
import { YMaps } from "@pbe/react-yandex-maps";
import { useGetLastInfoMessageQuery } from "@redux/api/infoMessage/infoMessageApi";
import routes from "@routes/routes";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const pages = routes.map((route) => (
  <Route key={route.path} path={route.path} element={route.element}></Route>
));

function App() {
  // Local State
  const [infoMessage, setInfoMessage] = useState<InfoMessageServerModel | null>(
    null
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Requesting a new message every 60 minutes
  const { data: fetchedInfoMessage } = useGetLastInfoMessageQuery(undefined, {
    pollingInterval: 1000 * 60 * 60,
  });

  const getSessionInfoMessage = (): InfoMessageServerModel | null => {
    const sessionStorageInfoMessageJson =
      window.sessionStorage.getItem("infoMessage");

    if (sessionStorageInfoMessageJson === null) {
      return null;
    }

    return JSON.parse(sessionStorageInfoMessageJson);
  };

  // Checking if the Popup needs to be shown
  useEffect(() => {
    const isInfoMessageClosed = window.sessionStorage.getItem(
      "isInfoMessageClosed"
    );

    setIsPopupOpen(!isInfoMessageClosed);
  }, [infoMessage]);

  // Save the message to sessionStorage and update the state
  useEffect(() => {
    if (!fetchedInfoMessage) return;

    const sessionStorageInfoMessage = getSessionInfoMessage();

    if (
      sessionStorageInfoMessage &&
      sessionStorageInfoMessage.id !== fetchedInfoMessage.id
    ) {
      window.sessionStorage.removeItem("isInfoMessageClosed");
    }

    window.sessionStorage.setItem(
      "infoMessage",
      JSON.stringify(fetchedInfoMessage)
    );

    setInfoMessage(fetchedInfoMessage);
  }, [fetchedInfoMessage]);

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    window.sessionStorage.setItem("isInfoMessageClosed", "true");
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalStyles styles={GLOBAL_STYLES} />
        <div className="app-container">
          <YMaps
            query={{
              lang: "en_RU",
              apikey: process.env.REACT_APP_YANDEX_MAPS_API_KEY,
            }}
          >
            <ScrollToTop />
            <Header />
            <Routes>{pages}</Routes>
            <Footer />
          </YMaps>
          <RequestModal />

          <CustomPopup
            isOpen={isPopupOpen}
            message={infoMessage?.message || null}
            onClose={handleClosePopup}
          />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
