import Typography from "@components/Typography/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { StepIcon } from "@mui/material";
import { RequestModalStepperProps } from "@pages/RequestModal/components/RequestModalStepper/RequestModalStepperTypes";
import {
  StyledStep,
  StyledRequestModalStepper,
  StyledRequestModalStepperBoxWrapper,
} from "@pages/RequestModal/components/RequestModalStepper/StyledRequestModalStepper";

const RequestModalStepper = ({
  steps,
  activeStep,
}: RequestModalStepperProps) => {
  return (
    <StyledRequestModalStepperBoxWrapper>
      <StyledRequestModalStepper activeStep={activeStep} connector={<></>}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const isActiveBackground =
            index < activeStep || index === 0 || index === activeStep;
          const isCompleted = index < activeStep;
          const isLastChild = index === steps.length - 1;
          return (
            <StyledStep
              key={label}
              isLastChild={isLastChild}
              isCompleted={isCompleted}
              isActiveBackground={isActiveBackground}
              {...stepProps}
            >
              <Typography variant="body1">{label}</Typography>
              {isCompleted && (
                <StepIcon
                  icon={<CheckCircleIcon sx={{ color: "#50D890" }} />}
                ></StepIcon>
              )}
            </StyledStep>
          );
        })}
      </StyledRequestModalStepper>
    </StyledRequestModalStepperBoxWrapper>
  );
};
export default RequestModalStepper;
