import Typography from "@components/Typography/Typography";
import styled from "@emotion/styled";

export const StyledNavigationItem = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "withUnderline",
})<{
  withUnderline?: boolean;
}>`
  cursor: pointer;
  width: fit-content;
  text-decoration: ${({ withUnderline }) =>
    withUnderline ? "underline" : "none"};
`;
