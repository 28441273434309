import RoutePaths from "@routes/routePaths";

export type navigationItemType = {
  title: string;
  to: string;
};

export const navigationItems: navigationItemType[] = [
  {
    title: "Главная",
    to: RoutePaths.MainPage,
  },
  {
    title: "Каталог",
    to: RoutePaths.CatalogPage,
  },
  {
    title: "Новости",
    to: RoutePaths.NewsPage,
  },
  {
    title: "О компании",
    to: RoutePaths.AboutUs,
  },
  {
    title: "Контакты",
    to: RoutePaths.ContactPage,
  },
];
