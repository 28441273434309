const TelegramIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="25" fill="#272727" fillOpacity="0.05" />
      <path
        d="M13.1562 29.4512C23.0031 25.1193 35.7458 19.8378 37.5047 19.1068C42.1187 17.1935 43.5344 17.5602 42.8285 21.7973C42.3216 24.8427 40.8588 34.9254 39.693 41.2012C39.0012 44.9228 37.4491 45.3639 35.0085 43.7538C33.8349 42.9789 27.9109 39.0615 26.6252 38.1417C25.4515 37.3033 23.8328 36.295 25.8629 34.3088C26.5852 33.6014 31.3206 29.0798 35.0101 25.5604C35.4935 25.0982 34.8863 24.339 34.3284 24.7095C29.3556 28.0072 22.461 32.5844 21.5834 33.1806C20.2578 34.0808 18.9846 34.4937 16.6992 33.8372C14.9724 33.3412 13.2855 32.7497 12.629 32.5241C10.1006 31.656 10.7008 30.5316 13.1562 29.4512Z"
        fill="#10A5EF"
      />
    </svg>
  );
};
export default TelegramIcon;
