import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";

export const StyledCategoryCardsGroupGridContainer = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isTabletScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTabletScreen: boolean }>>`
  display: grid;
  grid-auto-rows: ${({ isMobileScreen }) =>
    isMobileScreen ? "173px" : "256px"};
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: ${({ isMobileScreen }) =>
    isMobileScreen ? "15px" : "30px"};
  grid-row-gap: ${({ isMobileScreen }) => (isMobileScreen ? "15px" : "30px")};

  .category-card-wrapper:nth-of-type(6n + 6) {
    grid-column: 4 / 7;

    ${({ isTabletScreen }) =>
      isTabletScreen && `grid-row: auto; grid-column: 4 / 7;`}

    ${({ isMobileScreen }) => isMobileScreen && `grid-column: 1 / 7;`}
  }

  .category-card-wrapper:nth-of-type(6n + 5) {
    grid-column: 1 / 4;

    ${({ isTabletScreen }) =>
      isTabletScreen && `grid-row: auto; grid-column: 1 / 4;`}

    ${({ isMobileScreen }) => isMobileScreen && `grid-column: 4 / 7;`}
  }

  .category-card-wrapper:nth-of-type(6n + 4) {
    grid-column: 5 / 7;

    ${({ isTabletScreen }) =>
      isTabletScreen && `grid-row: auto; grid-column: 4 / 7;`}

    ${({ isMobileScreen }) => isMobileScreen && `grid-column: 1 / 4;`}
  }

  .category-card-wrapper:nth-of-type(6n + 4):last-child {
    ${({ isMobileScreen }) => isMobileScreen && `grid-column: 1 / 7;`}
  }

  .category-card-wrapper:nth-of-type(6n + 3) {
    grid-column: 5 / 7;

    ${({ isTabletScreen }) =>
      isTabletScreen && `grid-row: auto; grid-column: 1 / 4;`}

    ${({ isMobileScreen }) => isMobileScreen && `grid-column: 4 / 7;`}
  }

  .category-card-wrapper:nth-of-type(6n + 2) {
    grid-row-end: span 2;
    grid-column: 3 / 5;

    ${({ isTabletScreen }) =>
      isTabletScreen && `grid-row: auto; grid-column: 4 / 7;`}

    ${({ isMobileScreen }) =>
      isMobileScreen && `grid-row: auto; grid-column: 1 / 4;`}
  }

  .category-card-wrapper:nth-of-type(6n + 1) {
    grid-row-end: span 2;
    grid-column: 1 / 3;

    ${({ isTabletScreen }) =>
      isTabletScreen && `grid-row: auto; grid-column: 1 / 4;`}

    ${({ isMobileScreen }) =>
      isMobileScreen && `grid-row: auto; grid-column: 1 / 7;`}
  }
`;
