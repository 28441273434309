import Typography from "@components/Typography/Typography";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";

export const StyledNewsPageTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
    `}
`;
