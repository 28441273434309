import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Map } from "@pbe/react-yandex-maps";

export const StyledLocationBlockGridContainer = styled(Grid2)`
  border-radius: ${borderRadius};
  position: relative;
`;

export const StyledLocationBlockGridItemImage = styled(Grid2, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  position: absolute;
  ${({ isMobileScreen }) =>
    isMobileScreen
      ? `
        top: -45px; 
        left: -18px;
      `
      : `
        right: 0;
        top: 0;
      `}
`;

export const StyledLocationBlockBookmarkImage = styled("img")`
  border-top-right-radius: ${borderRadius};
  height: 100px;
`;

export const StyledLocationBlockMapsGridItem = styled(Grid2, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  height: ${({ isMobileScreen }) => (isMobileScreen ? "174px" : "350px")};
`;

export const StyledLocationBlockInfoGridContainer = styled(Grid2, {
  shouldForwardProp: (prop) =>
    prop !== "isTabletScreen" && prop !== "isMobileScreen",
})<
  TypeStyledComponentWithMobileScreenProp<{
    isTabletScreen?: boolean;
  }>
>`
  background: #2727270d;
  border-top-right-radius: ${borderRadius};
  padding: ${({ isMobileScreen }) =>
    isMobileScreen ? "32px 30px 20px" : "56px"};
  ${({ isTabletScreen }) =>
    isTabletScreen
      ? `border-top-left-radius: ${borderRadius}`
      : `border-bottom-right-radius: ${borderRadius}`}
`;

export const StyledLocationBlockTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isTabletScreen",
})<{
  isTabletScreen?: boolean;
}>`
  line-height: 36px;
  max-width: ${({ isTabletScreen }) => (isTabletScreen ? "100%" : "272px")};
`;

export const StyledLocationBlockMap = styled(Map, {
  shouldForwardProp: (prop) => prop !== "isTabletScreen",
})<{ isTabletScreen: boolean }>`
  width: 100%;
  height: 100%;

  .ymaps-2-1-79-map {
    border-top-left-radius: ${({ isTabletScreen }) =>
      isTabletScreen ? "initial" : borderRadius};
    border-bottom-left-radius: ${({ isTabletScreen }) =>
      isTabletScreen ? "initial" : borderRadius};

    ${({ isTabletScreen }) =>
      isTabletScreen &&
      `border-bottom-left-radius: ${borderRadius}; border-bottom-right-radius: ${borderRadius};`}
  }

  .ymaps-2-1-79-inner-panes {
    border-top-left-radius: ${({ isTabletScreen }) =>
      isTabletScreen ? "initial" : borderRadius};
    border-bottom-left-radius: ${({ isTabletScreen }) =>
      isTabletScreen ? "initial" : borderRadius};

    ${({ isTabletScreen }) =>
      isTabletScreen &&
      `border-bottom-left-radius: ${borderRadius}; border-bottom-right-radius: ${borderRadius};`}
  }
`;
