import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";
import {
  getRandomColorFromPalette,
  generateRandomGradient,
} from "@utils/randomGradient";

export const StyledCategoryCard = styled("div")<{ background?: string | null }>`
  background: ${({ background }) =>
    background
      ? `url("${background}") center no-repeat ${getRandomColorFromPalette()}`
      : generateRandomGradient()};

  background-size: cover;
  border-radius: ${borderRadius};
  height: 100%;
  cursor: pointer;
`;

export const StyledCategoryCardTitle = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== "titleMaxWidth" &&
    prop !== "isLaptopScreen" &&
    prop !== "isMobileScreen",
})<
  TypeStyledComponentWithMobileScreenProp<{
    titleMaxWidth?: string;
    isLaptopScreen: boolean;
  }>
>`
  max-width: ${({ titleMaxWidth }) => titleMaxWidth};
  ${({ isLaptopScreen }) => isLaptopScreen && "font-size: 30px"}
  ${({ isMobileScreen }) => isMobileScreen && "font-size: 20px"}
`;
