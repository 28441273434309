import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Stack } from "@mui/material";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import {
  StyledRequestModalNavigateBackButton,
  StyledRequestModalNavigateNextButton,
} from "@pages/RequestModal/components/RequestModalNavigateButtons/StyledRequestModalNavigateButtons";

type RequestModalNavigateButtonsProps = {
  withBackButton?: boolean;
  handleNexButton?: () => void;
  handleBackButton?: () => void;
  nextButtonTitle: string;
  nextButtonType?: MuiButtonProps["type"];
  nextButtonForm?: MuiButtonProps["form"];
} & MuiButtonProps;

const RequestModalNavigateButtons = ({
  withBackButton,
  handleNexButton,
  handleBackButton,
  nextButtonTitle,
  nextButtonType = "button",
  nextButtonForm,
  ...restButtonProps
}: RequestModalNavigateButtonsProps) => {
  return (
    <Stack direction="row" spacing={2}>
      {withBackButton && (
        <StyledRequestModalNavigateBackButton onClick={handleBackButton}>
          <ArrowBackIcon />
        </StyledRequestModalNavigateBackButton>
      )}

      <StyledRequestModalNavigateNextButton
        variant="contained"
        color="secondary"
        endIcon={<ArrowForwardIcon />}
        onClick={handleNexButton}
        type={nextButtonType}
        form={nextButtonForm}
        {...restButtonProps}
      >
        {nextButtonTitle}
      </StyledRequestModalNavigateNextButton>
    </Stack>
  );
};
export default RequestModalNavigateButtons;
