import {
  GetCategoriesApiResponse,
  GetCategoriesByIdApiResponse,
  GetCategoriesTitlesApiResponse,
} from "@customTypes/apiTypes/models/category.model";
import { CategoriesApiPaths } from "@redux/api/categories/categoriesApiPaths";
import generalApi from "@redux/api/initialApi";

export const categoriesApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategoriesTitles: builder.query<GetCategoriesTitlesApiResponse, void>({
      query: () => {
        return {
          url: CategoriesApiPaths.CategoriesTitles,
        };
      },
    }),
    getCategories: builder.query<GetCategoriesApiResponse, void>({
      query: () => {
        return {
          url: CategoriesApiPaths.CategoriesAll,
        };
      },
    }),
    getCategoryByFrontName: builder.query<GetCategoriesByIdApiResponse, string>(
      {
        query: (frontName) => {
          return {
            url: CategoriesApiPaths.CategoriesName + frontName,
          };
        },
      }
    ),
  }),
});

export const {
  useGetCategoriesTitlesQuery,
  useGetCategoriesQuery,
  useLazyGetCategoryByFrontNameQuery,
} = categoriesApi;
