import { DrawerProps } from "@components/Drawer/DrawerTypes";
import { Drawer as MuiDrawer } from "@mui/material";

const Drawer = ({
  children,
  open,
  anchor,
  className,
  ...rest
}: DrawerProps) => {
  return (
    <MuiDrawer className={className} anchor={anchor} open={open} {...rest}>
      {children}
    </MuiDrawer>
  );
};
export default Drawer;
