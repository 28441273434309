import { useEffect, useState } from "react";

import preloadImage from "@assets/images/preloadImage.png";

type CustomImageProps = {
  src: string;
  placeHolderSrc?: string;
  alt: string;
  className?: string;
} & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const CustomImage = ({
  src,
  placeHolderSrc = preloadImage,
  alt,
  className,
  ...rest
}: CustomImageProps) => {
  const [imageSrc, setImageSrc] = useState(placeHolderSrc);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setImageSrc(src);
    };
  }, [src]);
  return <img src={imageSrc} alt={alt} className={className} {...rest} />;
};
export default CustomImage;
