import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Box, styled } from "@mui/material";
import Input from "@mui/material/Input";

export const StyledChooseTareTypographyTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `font-size: 20px;
  line-height: 23px;`}
`;

export const StyledChooseTareTypographyBody2 = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  font-size: ${({ isMobileScreen }) => (isMobileScreen ? "15px" : "16px")};
  color: rgba(39, 39, 39, 0.3);
  line-height: ${({ isMobileScreen }) => (isMobileScreen ? "18px" : "19px")};
`;

export const StyledTareImageBoxWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "chosenTare" && prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp<{ chosenTare: boolean }>>`
  padding: ${({ isMobileScreen }) => (isMobileScreen ? "23px" : "37px")};
  background: rgba(39, 39, 39, 0.05);
  cursor: pointer;
  border: ${({ chosenTare }) => (chosenTare ? "3px solid #1A73E8" : "none")};
  border-radius: ${borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isMobileScreen }) => isMobileScreen && "width: 110px; height: 110px;"}
`;

export const StyledTareImage = styled("img")`
  width: 100%;
  height: 100%;
`;

export const StyledRequestModalChooseTareBoxWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  background: #fff;
  padding: ${({ isMobileScreen }) => (isMobileScreen ? "15px" : "30px")};
  border-radius: ${borderRadius};
`;

export const StyledInputBoxWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  background: rgba(39, 39, 39, 0.05);
  border-radius: ${({ isMobileScreen }) =>
    isMobileScreen ? "20px" : borderRadius};
  padding: 10px;
  max-width: ${({ isMobileScreen }) => (isMobileScreen ? "138px" : "230px")};
`;

export const StyledInputElement = styled("input")`
  border: none;
  background: inherit;
  width: auto;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  max-width: 100px;
  text-align: center;

  &:focus {
    border: none;
    outline: none;
  }
`;

export const StyledRequestModalChooseTareInput = styled(Input, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .MuiInput-input {
    font-size: ${({ isMobileScreen }) => (isMobileScreen ? "20px" : "32px")};
    line-height: ${({ isMobileScreen }) => (isMobileScreen ? "23px" : "42px")};
    width: ${({ isMobileScreen }) => (isMobileScreen ? "100%" : "auto")};
    text-align: center;
  }
`;

export const StyledRequestModalChooseTareCustomGridContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  display: grid;
  grid-auto-rows: ${({ isMobileScreen }) =>
    isMobileScreen ? "110px" : "180px"};
  grid-template-columns: ${({ isMobileScreen }) =>
    isMobileScreen ? "repeat(2, 110px)" : "repeat(2, 180px)"};
  grid-column-gap: ${({ isMobileScreen }) =>
    isMobileScreen ? "15px" : "30px"};
  grid-row-gap: ${({ isMobileScreen }) => (isMobileScreen ? "15px" : "30px")};
`;
