import {
  CreateUserBody,
  CreateUserResponse,
  GetUserByEmailResponse,
} from "@customTypes/apiTypes/models/user.model";
import generalApi from "@redux/api/initialApi";
import { UseApiPaths } from "@redux/api/user/userApiPaths";

export const userApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserByEmail: builder.query<GetUserByEmailResponse, string>({
      query: (email) => `${UseApiPaths.User}/${email}`,
    }),
    createUser: builder.mutation<CreateUserResponse, CreateUserBody>({
      query: ({ ...userBody }) => ({
        url: UseApiPaths.User,
        method: "POST",
        body: userBody,
      }),
    }),
  }),
});

export const { useCreateUserMutation, useLazyGetUserByEmailQuery } = userApi;
