import { borderRadius } from "@constants/constants.style";
import { Box, Step, Stepper, styled } from "@mui/material";

export const StyledRequestModalStepperBoxWrapper = styled(Box)`
  width: 100%;
`;

export const StyledRequestModalStepper = styled(Stepper)`
  justify-content: space-between;
`;

export const StyledStep = styled(Step, {
  shouldForwardProp: (prop) =>
    prop !== "isActiveBackground" &&
    prop !== "isLastChild" &&
    prop !== "isCompleted",
})<{
  isActiveBackground?: boolean;
  isLastChild: boolean;
  isCompleted?: boolean;
}>`
  width: 50%;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 40px;
  border-width: 1px 1px 0px 0px;
  border-style: solid;
  display: flex;
  justify-content: space-between;
  border-color: rgba(39, 39, 39, 0.05);
  border-radius: ${({ isLastChild }) =>
    isLastChild
      ? `0px 0px ${borderRadius} 0px`
      : `0px 0px 0px ${borderRadius}`};
  background: ${({ isActiveBackground }) =>
    isActiveBackground ? "#fff" : "rgba(39, 39, 39, 0.1);"};
  ${({ isCompleted }) => isCompleted && "background: rgba(39, 39, 39, 0.1);"}
`;
