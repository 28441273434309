import GMapsIcon from "@assets/icons/GMapsIcon/GMapsIcon";
import TwoGisIcon from "@assets/icons/TwoGisIcon/TwoGisIcon";
import YMapsIcon from "@assets/icons/YMapsIcon/YMapsIcon";
import bookmarkMobileImage from "@assets/images/bookmark-mobile.png";
import bookmarkImage from "@assets/images/bookmark.png";
import Link from "@components/Link/Link";
import Typography from "@components/Typography/Typography";
import { Stack, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  StyledLocationBlockTitle,
  StyledLocationBlockMapsGridItem,
  StyledLocationBlockBookmarkImage,
  StyledLocationBlockGridContainer,
  StyledLocationBlockGridItemImage,
  StyledLocationBlockInfoGridContainer,
  StyledLocationBlockMap,
} from "@pages/MainPage/components/LocationBlock/StyledLocationBlock";
import { Placemark } from "@pbe/react-yandex-maps";

const LocationBlock = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  const locationBlockTitle = isMobileScreen ? (
    <Typography variant="h5" lineHeight={"24px"} align="center">
      Постройте маршрут до логистического склада
    </Typography>
  ) : (
    <StyledLocationBlockTitle
      variant="h3"
      align={isTabletScreen ? "center" : "inherit"}
      isTabletScreen={isTabletScreen}
    >
      Постройте маршрут до логистического склада
    </StyledLocationBlockTitle>
  );

  return (
    <StyledLocationBlockGridContainer
      container
      alignItems="center"
      spacing={0}
      direction={isTabletScreen ? "column-reverse" : "row"}
    >
      <StyledLocationBlockGridItemImage isMobileScreen={isMobileScreen}>
        <StyledLocationBlockBookmarkImage
          src={isMobileScreen ? bookmarkMobileImage : bookmarkImage}
          alt="Красная закладка"
        />
      </StyledLocationBlockGridItemImage>

      <StyledLocationBlockMapsGridItem
        xs={12}
        md={7}
        isMobileScreen={isMobileScreen}
      >
        <StyledLocationBlockMap
          isTabletScreen={isTabletScreen}
          defaultState={{ center: [56.258738, 43.887669], zoom: 14 }}
        >
          <Placemark defaultGeometry={[56.258738, 43.887669]} />
        </StyledLocationBlockMap>
      </StyledLocationBlockMapsGridItem>

      <StyledLocationBlockInfoGridContainer
        container
        direction="column"
        wrap="nowrap"
        xs={12}
        md={5}
        alignItems={isTabletScreen ? "center" : "flex-start"}
        justifyContent={isTabletScreen ? "center" : "flex-start"}
        isMobileScreen={isMobileScreen}
        isTabletScreen={isTabletScreen}
      >
        <Grid2>{locationBlockTitle}</Grid2>

        <Stack
          direction="row"
          spacing={"20px"}
          sx={{ marginTop: isMobileScreen ? "20px" : "30px" }}
        >
          <Grid2>
            <Link
              href="https://yandex.ru/maps/47/nizhny-novgorod/?ll=43.887876%2C56.258604&mode=routes&rtext=~56.258738%2C43.887669&rtt=mt&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1Njk5NDc3MxKaAdCg0L7RgdGB0LjRjywg0J3QuNC20L3QuNC5INCd0L7QstCz0L7RgNC-0LQsINCQ0LLRgtC-0LfQsNCy0L7QtNGB0LrQuNC5INGA0LDQudC-0L0sINC80LjQutGA0L7RgNCw0LnQvtC9INCh0LXQstC10YDQvdGL0LksINCR0L7RgNGB0LrQsNGPINGD0LvQuNGG0LAsIDE30JQiCg35jC9CFfIIYUI%2C&z=19.18"
              target="_blank"
            >
              <YMapsIcon />
            </Link>
          </Grid2>

          <Grid2>
            <Link
              href="https://2gis.ru/n_novgorod/directions/points/%7C43.887669%2C56.258738%3B2674647933954356?m=43.888594%2C56.258168%2F18.1"
              target="_blank"
            >
              <TwoGisIcon />
            </Link>
          </Grid2>

          <Grid2>
            <Link
              href="https://www.google.ru/maps/dir//%D0%9B%D0%BE%D0%B3%D0%BE%D1%81%D0%BA%D0%BB%D0%B0%D0%B4,+%D1%83%D0%BB.+%D0%91%D0%BE%D1%80%D1%81%D0%BA%D0%B0%D1%8F,+17+%D0%BB%D0%B8%D1%82%D0%B5%D1%80+%D0%94,+%D0%9D%D0%B8%D0%B6%D0%BD%D0%B8%D0%B9+%D0%9D%D0%BE%D0%B2%D0%B3%D0%BE%D1%80%D0%BE%D0%B4,+%D0%9D%D0%B8%D0%B6%D0%B5%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB.,+603053/@56.258779,43.8872739,18z/data=!4m9!4m8!1m0!1m5!1m1!1s0x414e2a53d69e18b9:0xfbbe801421743e5d!2m2!1d43.8891048!2d56.2587416!3e2?entry=ttu"
              target="_blank"
            >
              <GMapsIcon />
            </Link>
          </Grid2>
        </Stack>
      </StyledLocationBlockInfoGridContainer>
    </StyledLocationBlockGridContainer>
  );
};
export default LocationBlock;
