import { StaticApiPaths } from "@constants/constants.staticApiPaths";
import getErrorMessage from "@utils/handleCatchBlockError";

const getCertificateInBlob = async (fileNamePath: string) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_URL + StaticApiPaths.GetStaticFile + fileNamePath
  );
  return response.blob();
};

const getDownloadFile = async (
  certificateName: string,
  certificateFrontName: string
) => {
  try {
    const certificateFileInBlob = await getCertificateInBlob(certificateName);
    const downloadUrl = URL.createObjectURL(certificateFileInBlob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = certificateFrontName;
    document.body.appendChild(link);
    link.style.display = "none";
    link.click();
    link.remove();
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export default getDownloadFile;
