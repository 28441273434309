import { GoodPriceBlockProps } from "@components/GoodPriceBlock/GoodPriceBlockTypes";
import {
  StyledNotificationGridContainer,
  StyledGoodPriceBlock,
} from "@components/GoodPriceBlock/StyledGoodPriceBlock";
import PriceBlock from "@components/PriceBlock/PriceBlock";
import Typography from "@components/Typography/Typography";
import muiTheme from "@config/theme";
import NotificationsIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const GoodPriceBlock = ({
  prices,
  className,
  button,
  background = muiTheme.palette.common.white,
  ...boxProps
}: GoodPriceBlockProps) => {
  return (
    <StyledGoodPriceBlock
      background={background}
      className={className}
      {...boxProps}
    >
      <Grid container direction="column" spacing={"30px"}>
        <Grid>
          <Stack spacing={"15px"}>
            <PriceBlock prices={prices} />

            <StyledNotificationGridContainer container alignItems="center">
              <NotificationsIcon color="primary" />
              <Typography variant="body2" color={muiTheme.palette.primary.main}>
                Узнать о снижении цены
              </Typography>
            </StyledNotificationGridContainer>
          </Stack>
        </Grid>

        {button && <Grid>{button}</Grid>}
      </Grid>
    </StyledGoodPriceBlock>
  );
};
export default GoodPriceBlock;
