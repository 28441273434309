import {
  StyledTableRow,
  StyledTableCell,
  StyledTableTypography,
} from "@components/Table/StyledTable";
import { TableProps } from "@components/Table/TableTypes";
import Typography from "@components/Typography/Typography";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

const Table = ({ tableData, title, className, withoutBorder }: TableProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (tableData.length === 0) {
    return <Typography variant="h3">Нет характеристик</Typography>;
  }

  const tableContent = tableData.map((row, index) => (
    <StyledTableRow key={row.name + index}>
      <StyledTableCell withoutBorder={withoutBorder}>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {row.name}
        </Typography>
      </StyledTableCell>
      <StyledTableCell withoutBorder={withoutBorder}>
        <Typography
          variant="body2"
          fontWeight={isMobileScreen ? 600 : theme.typography.fontWeightRegular}
        >
          {row.value}
        </Typography>
      </StyledTableCell>
    </StyledTableRow>
  ));

  return (
    <TableContainer>
      <Box className={className}>
        {title && (
          <StyledTableTypography variant={isMobileScreen ? "h5" : "h3"}>
            {title}
          </StyledTableTypography>
        )}
        <MuiTable aria-label="characteristic table">
          <TableBody>{tableContent}</TableBody>
        </MuiTable>
      </Box>
    </TableContainer>
  );
};
export default Table;
