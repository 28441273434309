import AboutUsPage from "@pages/AboutUsPage/AboutUsPage";
import AcidPage from "@pages/AcidPage/AcidPage";
import CatalogPage from "@pages/CatalogPage/CatalogPage";
import CategoryPage from "@pages/CategoryPage/CategoryPage";
import ContactPage from "@pages/ContactPage/ContactPage";
import MainPage from "@pages/MainPage/MainPage";
import NewsPage from "@pages/NewsPage/NewsPage";
import NotFoundPage from "@pages/NotFoundPage/NotFoundPage";
import RoutePaths from "@routes/routePaths";

const routes = [
  {
    path: RoutePaths.MainPage,
    element: <MainPage />,
  },
  {
    path: RoutePaths.CatalogPage,
    element: <CatalogPage />,
  },
  {
    path: RoutePaths.ContactPage,
    element: <ContactPage />,
  },
  {
    path: RoutePaths.CategoryPage,
    element: <CategoryPage />,
  },
  {
    path: RoutePaths.AcidPage,
    element: <AcidPage />,
  },
  {
    path: RoutePaths.NewsPage,
    element: <NewsPage />,
  },
  {
    path: RoutePaths.AboutUs,
    element: <AboutUsPage />,
  },
  {
    path: RoutePaths.NotFoundPage,
    element: <NotFoundPage />,
  },
];

export default routes;
