const formatPhoneNumber = (phoneNumber: string): string => {
  const regex = /^(\+7|8|7)?(\d{3})(\d{3})(\d{2})(\d{2})$/;
  const match = phoneNumber.match(regex);
  if (!match) {
    return phoneNumber;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, _plus, a, b, c, d] = match;
  return `+7 (${a}) ${b}-${c}-${d}`;
};

export default formatPhoneNumber;
