import { LinkProps } from "@components/Link/LinkTypes";
import { Stack } from "@mui/material";
import MuiLink from "@mui/material/Link";

const Link = ({
  children,
  className,
  startIcon,
  endIcon,
  ...rest
}: LinkProps) => {
  if (!startIcon && !endIcon) {
    return (
      <MuiLink className={className} {...rest}>
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink className={className} {...rest}>
      <Stack direction="row" sx={{ alignItems: "end" }}>
        {startIcon}
        {children}
        {endIcon}
      </Stack>
    </MuiLink>
  );
};
export default Link;
