import { FooterMailBlockProps } from "@components/Footer/components/FooterMailBlock/FooterMailBlockTypes";
import Link from "@components/Link/Link";
import Typography from "@components/Typography/Typography";
import { MAIL } from "@constants/constants.common";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const FooterMailBlock = ({ mailLinkVariant = "h3" }: FooterMailBlockProps) => {
  const theme = useTheme();

  return (
    <div>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="body2" color={theme.palette.grey[600]}>
            Адрес для направления заявок
          </Typography>
        </Grid>
        <Grid>
          <Link
            href={`mailto: ${MAIL}`}
            variant={mailLinkVariant}
            underline="none"
            color={theme.palette.common.white}
          >
            {MAIL}
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};
export default FooterMailBlock;
