import SecondaryLogo from "@assets/icons/SecondaryLogo/SecondaryLogo";
import FooterMailBlock from "@components/Footer/components/FooterMailBlock/FooterMailBlock";
import FooterMessengersBlock from "@components/Footer/components/FooterMessengersBlock/FooterMessengersBlock";
import FooterTelephoneBlock from "@components/Footer/components/FooterTelephoneBlock/FooterTelephoneBlock";
import {
  StyledFooter,
  StyledFooterButton,
} from "@components/Footer/StyledFooter";
import Link from "@components/Link/Link";
import MainContainer from "@components/MainContainer/MainContainer";
import NavigationItems from "@components/NavigationItems/NavigationItems";
import RouterLink from "@components/RouterLink/RouterLink";
import Typography from "@components/Typography/Typography";
import { navigationItems } from "@config/navigationItems";
import {
  INN,
  LOGISTICS_ADDRESS,
  MAIL,
  OGRN,
  SHIPPING_ADDRESS,
} from "@constants/constants.common";
import { Box, Stack, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import RoutePaths from "@routes/routePaths";

const Footer = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isBigTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  const typographyVariant = isMobileScreen ? "body2" : "body1";

  const mailAndTelephoneVariant = isMobileScreen ? "h5" : "h3";

  const rulesBlock = (
    <Grid2>
      <Typography variant="body2" color={theme.palette.grey[600]}>
        Все права защищены © {new Date().getFullYear()}
      </Typography>
    </Grid2>
  );

  const infoFooterContent = () => {
    if (isBigTabletScreen) {
      return (
        <Grid2>
          <Box sx={{ marginTop: "44px" }}>
            <Grid2 container direction="column" spacing={2}>
              <Grid2>
                <FooterTelephoneBlock
                  telephoneLinkVariant={mailAndTelephoneVariant}
                />
              </Grid2>
              <Grid2>
                <FooterMailBlock mailLinkVariant={mailAndTelephoneVariant} />
              </Grid2>
              <Grid2 container direction="column" spacing={1}>
                <Grid2>
                  <Typography variant="body2" color={theme.palette.grey[600]}>
                    Адрес логистического склада
                  </Typography>
                </Grid2>
                <Grid2>
                  <Typography
                    variant={typographyVariant}
                    color={theme.palette.common.white}
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    {LOGISTICS_ADDRESS}
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2 container direction="column" spacing={1}>
                <Grid2>
                  <Typography variant="body2" color={theme.palette.grey[600]}>
                    Адрес для отправки корреспонденции
                  </Typography>
                </Grid2>
                <Grid2>
                  <Typography
                    variant={typographyVariant}
                    color={theme.palette.common.white}
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    {SHIPPING_ADDRESS}
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2 container direction="column" spacing={1}>
                <Grid2>
                  <Typography variant="body2" color={theme.palette.grey[600]}>
                    По любым вопросам и предложениям
                  </Typography>
                </Grid2>
                <Grid2>
                  <Link href={`mailto: ${MAIL}`}>
                    <StyledFooterButton
                      variant="outlined"
                      fullWidth
                      isMobileScreen={isMobileScreen}
                    >
                      Написать руководству
                    </StyledFooterButton>
                  </Link>
                </Grid2>
              </Grid2>
              <Grid2>
                <FooterMessengersBlock />
              </Grid2>
              {rulesBlock}
            </Grid2>
          </Box>
        </Grid2>
      );
    }
    return (
      <>
        <Grid2>
          <div>
            <Grid2
              container
              direction="column"
              justifyContent="space-between"
              spacing={"40px"}
            >
              <Grid2>
                <FooterMailBlock mailLinkVariant={mailAndTelephoneVariant} />
              </Grid2>
              <Grid2 container direction="column" spacing={1}>
                <Grid2>
                  <Typography variant="body2" color={theme.palette.grey[600]}>
                    Адрес логистического склада
                  </Typography>
                </Grid2>
                <Grid2>
                  <Typography
                    variant={typographyVariant}
                    color={theme.palette.common.white}
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    {LOGISTICS_ADDRESS}
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2 container direction="column" spacing={1}>
                <Grid2>
                  <Typography variant="body2" color={theme.palette.grey[600]}>
                    Адрес для отправки корреспонденции
                  </Typography>
                </Grid2>
                <Grid2>
                  <Typography
                    variant={typographyVariant}
                    color={theme.palette.common.white}
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    {SHIPPING_ADDRESS}
                  </Typography>
                </Grid2>
              </Grid2>
              {rulesBlock}
            </Grid2>
          </div>
        </Grid2>

        <Grid2>
          <div>
            <Grid2
              container
              direction="column"
              justifyContent="space-between"
              spacing={"69px"}
            >
              <Grid2>
                <FooterTelephoneBlock
                  telephoneLinkVariant={mailAndTelephoneVariant}
                />
              </Grid2>
              <Grid2 container direction="column" spacing={1}>
                <Grid2>
                  <Typography variant="body2" color={theme.palette.grey[600]}>
                    По любым вопросам и предложениям
                  </Typography>
                </Grid2>
                <Grid2>
                  <Link href={`mailto: ${MAIL}`}>
                    <StyledFooterButton
                      variant="outlined"
                      fullWidth
                      isMobileScreen={isMobileScreen}
                    >
                      Написать руководству
                    </StyledFooterButton>
                  </Link>
                </Grid2>
              </Grid2>
              <Grid2>
                <FooterMessengersBlock />
              </Grid2>
            </Grid2>
          </div>
        </Grid2>
      </>
    );
  };

  return (
    <StyledFooter isMobileScreen={isMobileScreen}>
      <MainContainer>
        <Grid2
          container
          justifyContent="space-between"
          spacing={"30px"}
          disableEqualOverflow
        >
          <Grid2>
            <div>
              <Grid2 container direction="column" spacing={"25px"}>
                <Grid2>
                  <RouterLink to={RoutePaths.MainPage}>
                    <SecondaryLogo
                      width={isMobileScreen ? 162 : 187}
                      height={isMobileScreen ? 49 : 57}
                    />
                  </RouterLink>
                </Grid2>
                <Grid2>
                  <Typography
                    variant={typographyVariant}
                    color={theme.palette.grey[600]}
                  >
                    ООО “НЗФК”
                  </Typography>
                  <Stack direction="row" spacing={"15px"}>
                    <Typography
                      variant={typographyVariant}
                      color={theme.palette.grey[600]}
                    >
                      {INN}
                    </Typography>
                    <Typography
                      variant={typographyVariant}
                      color={theme.palette.grey[600]}
                    >
                      {OGRN}
                    </Typography>
                  </Stack>
                </Grid2>
                {!isBigTabletScreen && (
                  <Grid2>
                    <div>
                      <Grid2 container direction="column" spacing={"15px"}>
                        <NavigationItems
                          navigationItems={navigationItems}
                          color={theme.palette.grey[600]}
                          underline
                        />
                      </Grid2>
                    </div>
                  </Grid2>
                )}
              </Grid2>
            </div>
          </Grid2>

          {infoFooterContent()}
        </Grid2>
      </MainContainer>
    </StyledFooter>
  );
};
export default Footer;
