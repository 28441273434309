import canister from "@assets/images/canister.png";
import canisterWebp from "@assets/images/canister.webp";
import smallEuroCube from "@assets/images/euroCubeSmall.png";
import smallEuroCubeWebp from "@assets/images/euroCubeSmall.webp";
import logoPlate from "@assets/images/logoPlate.png";
import logoPlateWebp from "@assets/images/logoPlate.webp";
import notFound1 from "@assets/images/notFound1.png";
import notFound3 from "@assets/images/notFound3.png";
import notFound5 from "@assets/images/notFound5.png";
import Typography from "@components/Typography/Typography";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import AnimatedNotFoundPageComponent from "@pages/NotFoundPage/components/AnimatedNotFoundPageComponent/AnimatedNotFoundPageComponent";
import {
  StyledNotFoundPageLink,
  StyledNotFoundPageImage,
  StyledNotFoundImage1Wrapper,
  StyledNotFoundImage2Wrapper,
  StyledNotFoundImage3Wrapper,
  StyledNotFoundImage4Wrapper,
  StyledNotFoundImage5Wrapper,
  StyledNotFoundImage6Wrapper,
  StyledNotFoundPageMainContainer,
} from "@pages/NotFoundPage/StyledNotFoundPage";
import RoutePaths from "@routes/routePaths";

const NotFoundPage = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTableScreen = useMediaQuery(theme.breakpoints.down("md"));

  const notFondPageTitle =
    "Такой страницы нет,\n но вы можете посмотреть,\n что есть в ";

  return (
    <StyledNotFoundPageMainContainer isMobileScreen={isMobileScreen}>
      <Stack direction="column">
        <Typography
          fontSize={isMobileScreen ? "128px" : "256px"}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightBold}
          lineHeight={isMobileScreen ? "150px" : "300px"}
        >
          404
        </Typography>
        <Typography
          variant={isMobileScreen ? "h5" : "h4"}
          fontWeight={theme.typography.fontWeightRegular}
          sx={{ whiteSpace: "pre-line" }}
        >
          {notFondPageTitle}
          <StyledNotFoundPageLink
            to={RoutePaths.CatalogPage}
            color={theme.palette.primary.main}
            underline={true}
          >
            каталоге...
          </StyledNotFoundPageLink>
        </Typography>
      </Stack>

      <StyledNotFoundImage1Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(0)",
            "transform: translateY(12px)",
          ]}
        >
          <StyledNotFoundPageImage src={notFound1} alt="Плоскодонная колба" />
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage1Wrapper>

      <StyledNotFoundImage2Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(-12px)",
            "transform: translateY(0)",
          ]}
          delay={1}
        >
          <picture>
            <source srcSet={smallEuroCubeWebp} type="image/webp" />
            <StyledNotFoundPageImage
              src={smallEuroCube}
              alt="Пластиковый еврокуб"
              isNegativeRotate
            />
          </picture>
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage2Wrapper>

      <StyledNotFoundImage3Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(0)",
            "transform: translateY(12px)",
          ]}
        >
          <StyledNotFoundPageImage src={notFound3} alt="Мерный цилиндр" />
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage3Wrapper>

      <StyledNotFoundImage4Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(-12px)",
            "transform: translateY(0)",
          ]}
          delay={1}
        >
          <picture>
            <source srcSet={logoPlateWebp} type="image/webp" />
            <StyledNotFoundPageImage
              src={logoPlate}
              alt="3D Лого"
              isPositiveRotate
            />
          </picture>
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage4Wrapper>

      <StyledNotFoundImage5Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(0)",
            "transform: translateY(12px)",
          ]}
        >
          <StyledNotFoundPageImage src={notFound5} alt="Лабораторный стакан" />
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage5Wrapper>

      <StyledNotFoundImage6Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(-12px)",
            "transform: translateY(0)",
          ]}
          delay={1}
        >
          <picture>
            <source srcSet={canisterWebp} type="image/webp" />
            <StyledNotFoundPageImage
              src={canister}
              alt="Пластиковая тара"
              isNegativeRotate
            />
          </picture>
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage6Wrapper>
    </StyledNotFoundPageMainContainer>
  );
};
export default NotFoundPage;
