import Link from "@components/Link/Link";
import Typography from "@components/Typography/Typography";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { StyledTeamMemberBlock } from "@pages/ContactPage/components/TeamMemberBlock/StyledTeamMemberBlock";
import { TeamMemberBlockProps } from "@pages/ContactPage/components/TeamMemberBlock/TeamMemberBlockTypes";

const TeamMemberBlock = ({
  name,
  jobTitle,
  phone,
  email,
  underline = "none",
}: TeamMemberBlockProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fontSizeName = isMobileScreen ? "20px" : "24px";

  return (
    <StyledTeamMemberBlock>
      <Stack direction="column" spacing={{ xs: 2, sm: 3 }}>
        <Stack direction="column" spacing={"5px"}>
          <Typography variant="h5" sx={{ fontSize: fontSizeName }}>
            {name}
          </Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {jobTitle}
          </Typography>
        </Stack>

        <Stack direction="column" spacing={"5px"}>
          <Link
            href={`tel: ${phone}`}
            variant="body2"
            color={theme.palette.primary.main}
            underline={underline}
          >
            {phone}
          </Link>
          <Link
            href={`mailto: ${email}`}
            variant="body2"
            color={theme.palette.primary.main}
            underline={underline}
          >
            {email}
          </Link>
        </Stack>
      </Stack>
    </StyledTeamMemberBlock>
  );
};
export default TeamMemberBlock;
