import { useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import MailingBlock from "@pages/MainPage/components/MailingBlock/MailingBlock";
import { StyledMainInfoSectionNewsBlockGridItem } from "@pages/MainPage/components/MainInfoSection/StyledMainInfoSection";
import NewsBlock from "@pages/MainPage/components/NewsBlock/NewsBlock";

const MainInfoSection = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid2
      container
      justifyContent="space-between"
      spacing={3}
      direction={isMobileScreen ? "column-reverse" : "row"}
      sx={{ position: "relative" }}
    >
      <Grid2 xs={12} lg={6}>
        <MailingBlock />
      </Grid2>

      <StyledMainInfoSectionNewsBlockGridItem
        xs={12}
        lg={6}
        isMobileScreen={isMobileScreen}
      >
        <NewsBlock />
      </StyledMainInfoSectionNewsBlockGridItem>
    </Grid2>
  );
};
export default MainInfoSection;
