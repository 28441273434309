import { useMemo } from "react";

import imageError from "@assets/images/imageError.png";
import Typography from "@components/Typography/Typography";
import { AcidTareType, ExtraAction } from "@customTypes/requestModalTypes";
import useAppDispatch from "@hooks/useAppDispatch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, IconButton, Stack, useTheme, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  ObjectConfigExtraAction,
  RequestModalSelectedAcidBlockProps,
} from "@pages/RequestModal/components/RequestModalSelectedAcidBlock/RequestModalSelectedAcidBlockTypes";
import {
  StyledRequestModalSelectedAcidBlock,
  StyledRequestModalSelectedAcidBlockAcidTitle,
  StyledRequestModalSelectedAcidBlockAdditionalInfo,
  StyledRequestModalSelectedAcidBlockImage,
  StyledRequestModalSelectedAcidBlockImgWrapper,
  StyledRequestModalSelectedAcidBlockWeightTitle,
} from "@pages/RequestModal/components/RequestModalSelectedAcidBlock/StyledRequestModalSelectedAcidBlock";
import { useGetAcidTaresQuery } from "@redux/api/acidTare/acidTareApi";
import { useGetExtraActionsQuery } from "@redux/api/extraActions/extraActionApi";
import { deleteFormalizedAcid } from "@redux/slices/requestModal/requestModalSlice";
import declOfNum from "@utils/declOfNum";
import generateImageSrc from "@utils/generateImageSrc";
import imageOnErrorHandler from "@utils/handleImageError";

/**
 * Function that transform array to object
 * @param items: ExtraAction[]
 * @returns ObjectConfigExtraAction
 */
const formattingExtraActionsArrayToObject = (items: ExtraAction[]) => {
  return items.reduce<ObjectConfigExtraAction>((acc, item) => {
    const { id, ...restItem } = item;
    acc[id] = restItem;
    return acc;
  }, {});
};

const RequestModalSelectedAcidBlock = ({
  acidWeight,
  acidName,
  acidImage,
  tareCount,
  tareId,
  extraActions,
  formalizedAcidId,
}: RequestModalSelectedAcidBlockProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();

  /**
   * Services
   */
  const { data: fetchedExtraActions } = useGetExtraActionsQuery();
  const { data: fetchedAcidTares } = useGetAcidTaresQuery();

  /**
   * Calculated bag weight
   */
  const tareWeight = acidWeight / tareCount;

  /**
   * Transform ExtraActions to Object
   */
  const formattingFetchedExtraActions = useMemo(() => {
    if (!fetchedExtraActions) return;
    return formattingExtraActionsArrayToObject(fetchedExtraActions);
  }, [fetchedExtraActions]);

  /**
   * Determine the current tare by filtering all tares by id
   */
  const currentTare = useMemo(() => {
    return fetchedAcidTares?.filter((item) => item.id === tareId);
  }, [fetchedAcidTares, tareId]);

  const extraActionsTitles = useMemo(() => {
    if (!extraActions || !formattingFetchedExtraActions) return;
    return extraActions.map((elem) => {
      return formattingFetchedExtraActions[
        String(elem.id) as keyof typeof formattingFetchedExtraActions
      ].name.toLowerCase();
    });
  }, [extraActions, formattingFetchedExtraActions]);

  const declOfTare = useMemo(() => {
    if (!currentTare) return;
    if (currentTare[0].name.includes(AcidTareType.Cube)) {
      return declOfNum(tareCount, ["кубе", "кубах", "кубах"], false);
    }

    if (currentTare[0].name.includes(AcidTareType.Canister)) {
      return declOfNum(
        tareCount,
        ["канистре", "канистрах", "канистрах"],
        false
      );
    }

    return `${declOfNum(
      Number(tareCount),
      ["мешке", "мешках", "мешках"],
      false
    )} (${tareWeight} кг)`;
  }, [currentTare, tareCount, tareWeight]);

  const extraActionsToString = () => {
    if (!extraActionsTitles || extraActionsTitles.length === 0) return "";
    return ", " + extraActionsTitles.join(", ");
  };

  const additionalRequestInfo = `в ${tareCount} ${declOfTare}${extraActionsToString()}`;

  /**
   * Handlers
   */
  const handleRemoveButton = () => {
    dispatch(deleteFormalizedAcid(formalizedAcidId));
  };

  /**
   * Layout
   */
  return (
    <StyledRequestModalSelectedAcidBlock>
      <Grid2
        container
        spacing={isMobileScreen ? "15px" : "30px"}
        disableEqualOverflow
      >
        <Grid2 md={"auto"} lg={"auto"}>
          <StyledRequestModalSelectedAcidBlockImgWrapper
            isMobileScreen={isMobileScreen}
          >
            <StyledRequestModalSelectedAcidBlockImage
              src={acidImage ? generateImageSrc(acidImage) : imageError}
              alt="acid"
              isMobileScreen={isMobileScreen}
              onError={(event) => imageOnErrorHandler(event)}
            />
          </StyledRequestModalSelectedAcidBlockImgWrapper>
        </Grid2>

        <Grid2 container justifyContent="space-between" xs md lg>
          <Grid2 xs md lg>
            <Stack direction="column" spacing={"15px"}>
              <StyledRequestModalSelectedAcidBlockAcidTitle
                variant="h5"
                isMobileScreen={isMobileScreen}
              >
                {acidName}
              </StyledRequestModalSelectedAcidBlockAcidTitle>
              {!isTabletScreen ? (
                <Typography
                  variant="requestModalWindowBody1"
                  color="primary.main"
                >
                  {additionalRequestInfo}
                </Typography>
              ) : (
                <StyledRequestModalSelectedAcidBlockWeightTitle
                  isTabletScreen={isTabletScreen}
                  variant="requestModalWindowBody1"
                  color="text.secondary"
                >
                  {acidWeight} кг
                </StyledRequestModalSelectedAcidBlockWeightTitle>
              )}
            </Stack>
          </Grid2>

          {!isTabletScreen && (
            <Grid2
              container
              direction="column"
              justifyContent="space-between"
              alignItems="end"
              md={"auto"}
            >
              <Grid2>
                <StyledRequestModalSelectedAcidBlockWeightTitle
                  variant="requestModalWindowBody1"
                  color="text.secondary"
                  isTabletScreen={isTabletScreen}
                >
                  {acidWeight} кг
                </StyledRequestModalSelectedAcidBlockWeightTitle>
              </Grid2>
              <Grid2>
                <IconButton onClick={handleRemoveButton}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid2>
            </Grid2>
          )}
        </Grid2>
      </Grid2>

      {isTabletScreen && (
        <Box sx={{ marginTop: "15px" }}>
          <Grid2 container justifyContent="space-between" alignItems="center">
            <Grid2 xs={11}>
              <StyledRequestModalSelectedAcidBlockAdditionalInfo
                variant="requestModalWindowBody1"
                color="primary.main"
                isMobileScreen={isMobileScreen}
              >
                {additionalRequestInfo}
              </StyledRequestModalSelectedAcidBlockAdditionalInfo>
            </Grid2>

            <Grid2 xs={1}>
              <IconButton onClick={handleRemoveButton}>
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Grid2>
          </Grid2>
        </Box>
      )}
    </StyledRequestModalSelectedAcidBlock>
  );
};
export default RequestModalSelectedAcidBlock;
