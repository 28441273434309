import { borderRadius } from "@constants/constants.style";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";

export const StyledTeamMemberBlock = styled(Box)`
  background: #f3f3f3;
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${borderRadius};
  max-width: 490px;
  width: 100%;
`;
