import RequestIcon from "@assets/icons/RequestIcon/RequestIcon";
import { NavigationButtonsProps } from "@components/Header/components/NavigationButtons/NavigationButtonsTypes";
import {
  StyledRequestButton,
  StyledNavigationButtonsMenuButton,
} from "@components/Header/components/NavigationButtons/StyledNavigationButtons";
import useAppDispatch from "@hooks/useAppDispatch";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Unstable_Grid2";
import { openRequestModal } from "@redux/slices/requestModal/requestModalSlice";

const NavigationButtons = ({
  handleMenuClick,
  isClose = false,
}: NavigationButtonsProps) => {
  const dispatch = useAppDispatch();

  const menuButtonIcon = isClose ? <CloseIcon /> : <MenuIcon />;

  return (
    <>
      <Grid xs={6}>
        <StyledNavigationButtonsMenuButton
          variant="contained"
          endIcon={menuButtonIcon}
          onClick={handleMenuClick}
          isClose={isClose}
        >
          {isClose ? "Закрыть" : "Меню"}
        </StyledNavigationButtonsMenuButton>
      </Grid>

      <Grid xs={6}>
        <StyledRequestButton
          variant="contained"
          endIcon={<RequestIcon />}
          onClick={() => dispatch(openRequestModal())}
        >
          Заявка
        </StyledRequestButton>
      </Grid>
    </>
  );
};
export default NavigationButtons;
