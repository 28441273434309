import ViberIcon from "@assets/icons/ViberIcon/ViberIcon";
import Link from "@components/Link/Link";
import Typography from "@components/Typography/Typography";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Stack, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

const FooterMessengersBlock = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const typographyVariant = isMobileScreen ? "body2" : "body1";

  return (
    <div>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography
            variant={typographyVariant}
            color={theme.palette.common.white}
          >
            Оперативно отвечаем
          </Typography>
        </Grid>
        <Grid>
          <Stack direction="row" spacing={"5px"}>
            <Typography
              variant={typographyVariant}
              color={theme.palette.common.white}
              variantMapping={{ body1: "span", body2: "span" }}
            >
              в
            </Typography>
            <Link
              href="https://wa.me/message/7YMD6ZKBIRWXG1?src=qr"
              target="_blank"
              variant={typographyVariant}
              color="#25D366"
            >
              <Stack direction="row" spacing={"3px"} alignItems="center">
                <WhatsAppIcon sx={{ fontSize: isMobileScreen ? 16 : 20 }} />
                <span>
                  Вотсаппе
                  <Typography
                    variant={typographyVariant}
                    color={theme.palette.common.white}
                    variantMapping={{ body1: "span", body2: "span" }}
                  >
                    ,
                  </Typography>
                </span>
              </Stack>
            </Link>
            <Link
              href="https://t.me/nzfk_bot"
              target="_blank"
              variant={typographyVariant}
              color="#10A5EF"
            >
              <Stack direction="row" spacing={"3px"} alignItems="center">
                <TelegramIcon sx={{ fontSize: isMobileScreen ? 16 : 20 }} />
                <span>Телеграме</span>
              </Stack>
            </Link>
            <Typography
              variant={typographyVariant}
              color={theme.palette.common.white}
              variantMapping={{ body1: "span", body2: "span" }}
            >
              и
            </Typography>
            <Link variant={typographyVariant} color="#9036D7">
              <Stack direction="row" spacing={"3px"} alignItems="center">
                <ViberIcon size={isMobileScreen ? "small" : "medium"} />
                <span>Вайбере</span>
              </Stack>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
export default FooterMessengersBlock;
