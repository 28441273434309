import imageError from "@assets/images/imageError.png";
import GoodPriceBlock from "@components/GoodPriceBlock/GoodPriceBlock";
import QuantityIndicator from "@components/QuantityIndicator/QuantityIndicator";
import RouterLink from "@components/RouterLink/RouterLink";
import Table from "@components/Table/Table";
import Typography from "@components/Typography/Typography";
import { useTheme, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { RequestModalShortAcidInfoProps } from "@pages/RequestModal/components/RequestModalShortAcidInfo/RequestModalShortAcidInfoTypes";
import {
  StyledRequestModalShortAcidInfoBigImage,
  StyledRequestModalShortAcidInfoImageWrapper,
  StyledRequestModalShortAcidInfoBaseInfoStackWrapper,
} from "@pages/RequestModal/components/RequestModalShortAcidInfo/StyledRequestModalShortAcidInfo";
import RoutePaths from "@routes/routePaths";
import generateImageSrc from "@utils/generateImageSrc";
import imageOnErrorHandler from "@utils/handleImageError";

const RequestModalShortAcidInfo = ({
  characteristics,
  prices,
  count,
  acidTitle,
  acidImage,
  requestButton,
  category,
  acidFrontName,
  handleCloseRequestModal,
}: RequestModalShortAcidInfoProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const characteristicsTableContent = () => {
    if (
      isMobileScreen ||
      characteristics === undefined ||
      characteristics.length === 0
    )
      return;
    return (
      <Grid2>
        <Table tableData={characteristics.slice(0, 3)} />
      </Grid2>
    );
  };

  return (
    <Grid2
      container
      justifyContent={isLaptopScreen ? "center" : "space-between"}
      spacing={"15px"}
    >
      <Grid2
        container
        direction={isMobileScreen ? "column-reverse" : "row"}
        justifyContent="space-between"
        alignItems="center"
        spacing={"30px"}
        xs={12}
        lg={8}
      >
        <Grid2 xs={12} md={6}>
          <StyledRequestModalShortAcidInfoImageWrapper
            isTabletScreen={isTabletScreen}
          >
            <StyledRequestModalShortAcidInfoBigImage
              isTabletScreen={isTabletScreen}
              src={acidImage ? generateImageSrc(acidImage) : imageError}
              alt={acidTitle ? acidTitle : "Кислота"}
              onError={(event) => imageOnErrorHandler(event)}
            />
          </StyledRequestModalShortAcidInfoImageWrapper>
        </Grid2>

        <Grid2
          container
          xs={12}
          md={6}
          lg={6}
          direction="column"
          justifyContent="space-between"
          sx={{ height: isTabletScreen ? "auto" : "100%" }}
          flexWrap="nowrap"
        >
          <StyledRequestModalShortAcidInfoBaseInfoStackWrapper
            isMobileScreen={isMobileScreen}
            direction="column"
            spacing={isMobileScreen ? "15px" : "30px"}
          >
            {acidTitle && (
              <Grid2>
                <Typography
                  variant={isMobileScreen ? "body2" : "h5"}
                  fontWeight={theme.typography.fontWeightBold}
                  lineHeight={isMobileScreen ? "19px" : "28px"}
                >
                  {acidTitle}
                </Typography>
              </Grid2>
            )}
            {characteristicsTableContent()}
          </StyledRequestModalShortAcidInfoBaseInfoStackWrapper>
          {!isMobileScreen && category?.id && (
            <Grid2 sx={{ zIndex: 1 }}>
              <RouterLink
                to={`${RoutePaths.CatalogPage}/${category.frontName}/${acidFrontName}`}
                onClick={handleCloseRequestModal}
              >
                <Typography variant="body2" color="primary.main">
                  Подробнее на странице с товаром...
                </Typography>
              </RouterLink>
            </Grid2>
          )}
        </Grid2>
      </Grid2>

      <Grid2
        container
        direction="column"
        xs={12}
        sm={8}
        md={6}
        lg={4}
        xl={3}
        alignItems="center"
        spacing={3}
      >
        <Grid2 sx={{ width: "100%", maxWidth: "450px" }}>
          <GoodPriceBlock
            sx={{ padding: isMobileScreen ? "15px" : "30px" }}
            prices={prices}
            button={requestButton}
            background="rgba(26, 115, 232, 0.05)"
          />
        </Grid2>

        {count && (
          <Grid2>
            <QuantityIndicator count={count} />
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  );
};
export default RequestModalShortAcidInfo;
