import { useEffect, useState } from "react";

import RequestIcon from "@assets/icons/RequestIcon/RequestIcon";
import { HeaderNavigationProps } from "@components/Header/components/HeaderNavigation/HeaderNavigationTypes";
import {
  StyledLink,
  StyledButtonLink,
  StyledButtonLinksContainer,
  StyledHeaderNavigationDrawer,
  StyledDrawerNavItemsContainer,
  StyledHeaderNavigationButtonsContainer,
  StyledHeaderDrawerNavigationButtonsContainer,
} from "@components/Header/components/HeaderNavigation/StyledHeaderNavigation";
import NavigationButtons from "@components/Header/components/NavigationButtons/NavigationButtons";
import NavigationItems from "@components/NavigationItems/NavigationItems";
import Portal from "@components/Portal/Portal";
import RequestButton from "@components/RequestButton/RequestButton";
import { navigationItems } from "@config/navigationItems";
import { MAIL, TELEPHONE } from "@constants/constants.common";
import useAppDispatch from "@hooks/useAppDispatch";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { openRequestModal } from "@redux/slices/requestModal/requestModalSlice";
import { useLocation } from "react-router-dom";

const HeaderNavigation = ({
  portalContainer,
  isTableScreen,
  isLaptopScreen,
}: HeaderNavigationProps) => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const infoButtonLinks = (
    <StyledButtonLinksContainer container direction="column" spacing={2}>
      <Grid>
        <StyledButtonLink variant="contained">
          <StyledLink
            href={`tel: ${TELEPHONE}`}
            variant="subtitle2"
            underline="none"
          >
            Позвонить {TELEPHONE}
          </StyledLink>
        </StyledButtonLink>
      </Grid>

      <Grid>
        <StyledButtonLink variant="contained">
          <StyledLink
            href={`mailto: ${MAIL}`}
            variant="subtitle2"
            underline="none"
          >
            Написать на {MAIL}
          </StyledLink>
        </StyledButtonLink>
      </Grid>
    </StyledButtonLinksContainer>
  );

  if (isLaptopScreen) {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid container spacing={2}>
          <NavigationItems navigationItems={navigationItems} />
        </Grid>

        <Grid>
          <RequestButton
            endIcon={<RequestIcon />}
            variant="contained"
            onClick={() => dispatch(openRequestModal())}
          >
            Заявка
          </RequestButton>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <>
        <Portal container={portalContainer.current}>
          <StyledHeaderNavigationButtonsContainer
            container
            justifyContent="space-between"
            spacing={2}
          >
            <NavigationButtons handleMenuClick={toggleDrawer(true)} />
          </StyledHeaderNavigationButtonsContainer>
        </Portal>

        <StyledHeaderNavigationDrawer
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
        >
          <Box sx={{ padding: "60px 0 32px 16px" }}>
            <StyledDrawerNavItemsContainer
              container
              direction="column"
              spacing={2}
              sx={{ paddingLeft: "14px" }}
            >
              <NavigationItems navigationItems={navigationItems} />
            </StyledDrawerNavItemsContainer>

            {!isTableScreen && infoButtonLinks}
          </Box>

          <StyledHeaderDrawerNavigationButtonsContainer
            container
            justifyContent="space-between"
            spacing={2}
          >
            <NavigationButtons handleMenuClick={toggleDrawer(false)} isClose />
          </StyledHeaderDrawerNavigationButtonsContainer>
        </StyledHeaderNavigationDrawer>
      </>
    );
  }
};
export default HeaderNavigation;
