import { useCallback, useEffect, useMemo, useState } from "react";

import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, useMediaQuery } from "@mui/material";
import RequestModalChooseGood from "@pages/RequestModal/components/RequestModalChooseGood/RequestModalChooseGood";
import RequestModalChooseTare from "@pages/RequestModal/components/RequestModalChooseTare/RequestModalChooseTare";
import RequestModalConfirmationDialog from "@pages/RequestModal/components/RequestModalConfirmationDialog/RequestModalConfirmationDialog";
import RequestModalFillingUserInfo from "@pages/RequestModal/components/RequestModalFillingUserInfo/RequestModalFillingUserInfo";
import RequestModalOrderVerification from "@pages/RequestModal/components/RequestModalOrderVerification/RequestModalOrderVerification";
import RequestModalSentRequest from "@pages/RequestModal/components/RequestModalSentRequest/RequestModalSentRequest";
import RequestModalStepper from "@pages/RequestModal/components/RequestModalStepper/RequestModalStepper";
import {
  StyledRequestModalDialog,
  StyledRequestModalCloseButton,
  StyledRequestModalDialogContent,
} from "@pages/RequestModal/StyledRequestModal";
import {
  closeRequestModal,
  clearFormalizedAcidId,
  deleteFormalizedAcids,
  selectAcid,
  clearAcidIdFromPage,
} from "@redux/slices/requestModal/requestModalSlice";

const steps = ["1. Выбрать товар", "2. Указать контактные данные"];

const RequestModal = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const dispatch = useAppDispatch();

  /**
   * Local State
   */
  const openModalValue = useAppSelector(
    (state) => state.requestModal.requestModalOpenValue
  );

  const [activeStep, setActiveStep] = useState(0);

  const [isChooseTare, setIsChooseTare] = useState(false);
  const [isOrderVerify, setIsOrderVerify] = useState(false);

  const [openConfirmationWindow, setOpenConfirmationWindow] = useState(false);

  /**
   * Handlers
   */

  const handleCloseRequestModal = useCallback(() => {
    dispatch(closeRequestModal());
    dispatch(clearAcidIdFromPage());
    dispatch(deleteFormalizedAcids());
    dispatch(clearFormalizedAcidId());
    dispatch(selectAcid(null));
    setIsChooseTare(false);
    setIsOrderVerify(false);
    setActiveStep(0);
  }, [dispatch]);

  const handleCloseConfirmationWindow = useCallback(() => {
    setOpenConfirmationWindow(false);
  }, []);

  const handleOpenConfirmationWindow = useCallback(() => {
    setOpenConfirmationWindow(true);
  }, []);

  const handleClosingConfirmation = useCallback(() => {
    handleCloseRequestModal();
    setOpenConfirmationWindow(false);
  }, [handleCloseRequestModal]);

  const handleChooseTare = useCallback(() => {
    setIsChooseTare(true);
    setIsOrderVerify(false);
  }, []);

  const handleReturningOnChooseGood = useCallback(() => {
    setIsChooseTare(false);
  }, []);

  const handleIsOrderVerify = useCallback(() => {
    setIsChooseTare(false);
    setIsOrderVerify(true);
  }, []);

  const handleReturningOnOrderVerify = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsOrderVerify(true);
  }, []);

  const handleNextStep = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const windowScrollHandler = useCallback(() => {
    if (!isLaptopScreen) {
      document.documentElement.style.removeProperty("--scroll-y");
      return;
    }
    document.documentElement.style.setProperty(
      "--scroll-y",
      `${window.scrollY}px`
    );
  }, [isLaptopScreen]);

  /**
   * component`s useEffects
   */
  useEffect(() => {
    if (!isLaptopScreen) {
      window.removeEventListener("scroll", windowScrollHandler);
    }

    window.addEventListener("scroll", windowScrollHandler);
  }, [isLaptopScreen, windowScrollHandler]);

  /**
   * Layout
   */
  const dialogContent = useMemo(() => {
    if (activeStep === 1) {
      return (
        <RequestModalFillingUserInfo
          handleCloseRequestModal={handleOpenConfirmationWindow}
          handleNextStep={handleNextStep}
          handleReturningOnOrderVerify={handleReturningOnOrderVerify}
        />
      );
    }
    if (isChooseTare)
      return (
        <RequestModalChooseTare
          handleCloseRequestModal={handleOpenConfirmationWindow}
          handleBackButton={handleReturningOnChooseGood}
          handleNexButton={handleIsOrderVerify}
        />
      );
    if (isOrderVerify)
      return (
        <RequestModalOrderVerification
          handleCloseRequestModal={handleOpenConfirmationWindow}
          setIsChooseTare={setIsChooseTare}
          setIsOrderVerify={setIsOrderVerify}
          handleNextStep={handleNextStep}
        />
      );
    return (
      <RequestModalChooseGood
        handleCloseRequestModal={handleOpenConfirmationWindow}
        handleChooseTare={handleChooseTare}
      />
    );
  }, [
    activeStep,
    handleChooseTare,
    handleIsOrderVerify,
    handleNextStep,
    handleOpenConfirmationWindow,
    handleReturningOnChooseGood,
    handleReturningOnOrderVerify,
    isChooseTare,
    isOrderVerify,
  ]);

  return (
    <>
      <StyledRequestModalDialog
        isMobileScreen={isMobileScreen}
        open={openModalValue}
        onClose={handleOpenConfirmationWindow}
      >
        {!isTabletScreen && (
          <StyledRequestModalCloseButton onClick={handleOpenConfirmationWindow}>
            <CloseIcon fontSize="large" />
          </StyledRequestModalCloseButton>
        )}

        {activeStep === 2 ? (
          <RequestModalSentRequest
            setActiveStep={setActiveStep}
            setIsChooseTare={setIsChooseTare}
            setIsOrderVerify={setIsOrderVerify}
          />
        ) : (
          <>
            <StyledRequestModalDialogContent
              isMobileScreen={isMobileScreen}
              isTabletScreen={isTabletScreen}
            >
              {dialogContent}
            </StyledRequestModalDialogContent>

            {!isTabletScreen && (
              <RequestModalStepper steps={steps} activeStep={activeStep} />
            )}
          </>
        )}
      </StyledRequestModalDialog>

      <RequestModalConfirmationDialog
        handleCloseConfirmationWindow={handleCloseConfirmationWindow}
        openConfirmationWindow={openConfirmationWindow}
        handleClosingConfirmation={handleClosingConfirmation}
      />
    </>
  );
};
export default RequestModal;
