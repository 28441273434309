import Typography from "@components/Typography/Typography";
import { styled } from "@mui/material";

const StyledMobileScreenTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "titleFontWeight",
})<{ titleFontWeight?: React.CSSProperties["fontWeight"] }>`
  font-style: normal;
  font-weight: ${({ titleFontWeight }) => titleFontWeight || 700};
  font-size: 32px;
  line-height: 38px;
`;

export default StyledMobileScreenTitle;
