import Button from "@components/Button/Button";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";

export const StyledInDevelopedButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  border-radius: ${borderRadius};
  font-weight: 400;
  padding: 8px 15px;
  font-size: ${({ isMobileScreen }) => (isMobileScreen ? "16px" : "20px")};
`;
