export const TELEPHONE = "8 (831) 212-92-22";

export const MAIL = "info@nzfk.ru";

export const LOGO_TITLE = "Нижегородский завод \n фосфорных кислот";

export const INN = "ИНН 5257205062";

export const OGRN = "ОГРН 1215200039010";

export const SHIPPING_ADDRESS =
  "603002, г. Нижний Новгород, \nул. Советская, 19, а/я 52";

export const LOGISTICS_ADDRESS =
  "603065, г. Нижний Новгород, \n ул. Борская, 17Д";

export const WAREHOUSE_ADDRESS = "г. Нижний Новгород, ул. Борская, д. 17Д";

export const LEGAL_ADDRESS = "г. Нижний Новгород, ул. Витебская, д. 39, пом. 6";

export const OFFICE_ADDRESS = "г. Нижний Новгород, ул. Советская д. 5";

export const EMAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

export const PHONE_REGEXP = /((\+7|7|8)+([0-9]){10})$/;

export const NUMBER_CODES = [
  { value: "+994", label: "+994" },
  { value: "+374", label: "+374" },
  { value: "+375", label: "+375" },
  { value: "+7", label: "+7" },
  { value: "+996", label: "+996" },
  { value: "+373", label: "+373" },
  { value: "+992", label: "+992" },
  { value: "+993", label: "+993" },
  { value: "+998", label: "+998" },
];
