import Link from "@components/Link/Link";
import MainContainer from "@components/MainContainer/MainContainer";
import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Box, styled } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export const StyledAboutUsPageContainer = styled(MainContainer)`
  overflow: hidden;
`;

export const StyledAboutUsPageTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `font-weight: 700;
  font-size: 32px;
  line-height: 38px;`}
`;

export const StyledAboutUsPageSubText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  line-height: ${({ isMobileScreen }) => (isMobileScreen ? "130%" : "30px")};
  white-space: pre-line;
  letter-spacing: 0;
`;

export const StyledAboutUsPageGridContainerRelative = styled(Grid2)`
  position: relative;
`;

export const StyledAboutUsPageLogoImageGridWrapper = styled(Grid2, {
  shouldForwardProp: (prop) =>
    prop !== "isTabletScreen" && prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTabletScreen: boolean }>>`
  position: absolute;
  right: -5%;
  transform: rotate(15deg);

  ${({ isTabletScreen }) =>
    isTabletScreen &&
    `right: calc(-200px / 4 + 6px);
  top: calc(-200px / 2);`}
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `right: calc(-143px / 4 + 6px);
  top: calc(-143px / 2);`}
`;

export const StyledAboutUsPageLogoImage = styled("img", {
  shouldForwardProp: (prop) =>
    prop !== "isTabletScreen" && prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTabletScreen: boolean }>>`
  max-width: 509px;
  width: 100%;
  height: auto;
  object-fit: cover;
  ${({ isTabletScreen }) =>
    isTabletScreen &&
    `width: 200px;
  height: 200px;`}
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `width: 143px;
  height: 143px;`}
`;

export const StyledAboutUsPageTextBlockWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "background",
})<{ background?: string }>`
  padding: 30px;
  background: ${({ theme, background }) =>
    background ? background : theme.palette.common.white};
  border-radius: ${borderRadius};
`;

export const StyledAboutUsPageDownloadLink = styled(Link)`
  cursor: pointer;
`;
