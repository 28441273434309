import { borderRadius } from "@constants/constants.style";
import { Box, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export const StyledGoodPriceBlock = styled(Box, {
  shouldForwardProp: (prop) => prop !== "background",
})<{
  background?: string;
}>`
  background: ${({ background }) => background};
  border-radius: ${borderRadius};
`;

export const StyledNotificationGridContainer = styled(Grid)`
  width: fit-content;
  cursor: pointer;
`;
