import { ExtraActionServerModel } from "@customTypes/apiTypes/models/extraActions.model";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";

export type MobileButtonConfig = {
  title?: string;
  icon: React.ReactNode;
  background: string;
  textColor: string;
  form?: string;
} & MuiButtonProps;

export interface ExtraAction extends ExtraActionServerModel {}

export enum AcidTareType {
  Cube = "Еврокуб",
  Canister = "Канистра",
  Bag = "Мешок",
}
