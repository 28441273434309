import MainContainer from "@components/MainContainer/MainContainer";
import StyledMobileScreenTitle from "@components/MobileScreenTitle/StyledMobileScreenTitle";
import Typography from "@components/Typography/Typography";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import MainContactsInfo from "@pages/ContactPage/components/MainContactsInfo/MainContactsInfo";
import OurTeamBlock from "@pages/ContactPage/components/OurTeamBlock/OurTeamBlock";
import {
  useGetContactsQuery,
  useGetTeamContactsQuery,
} from "@redux/api/contacts/contactsApi";

const ContactPage = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: fetchedContacts,
    isLoading: isContactsLoading,
    isError: isContactsError,
  } = useGetContactsQuery();

  const {
    data: fetchedTeamContacts,
    isLoading: isTeamContactsLoading,
    isError: isTeamContactsError,
  } = useGetTeamContactsQuery();

  const contactPageTitle = isMobileScreen ? (
    <StyledMobileScreenTitle variant="h2">Контакты</StyledMobileScreenTitle>
  ) : (
    <Typography variant="h2">Контакты</Typography>
  );

  return (
    <MainContainer>
      <Stack
        direction="column"
        spacing={{ xs: "30px", lg: "60px" }}
        sx={{ marginTop: isMobileScreen ? "30px" : "50px" }}
      >
        {contactPageTitle}

        <MainContactsInfo
          contacts={fetchedContacts}
          isContactsLoading={isContactsLoading}
          isContactsError={isContactsError}
        />

        <OurTeamBlock
          teamContacts={fetchedTeamContacts}
          isTeamContactsLoading={isTeamContactsLoading}
          isTeamContactsError={isTeamContactsError}
        />
      </Stack>
    </MainContainer>
  );
};
export default ContactPage;
