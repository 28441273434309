const RoutePaths = {
  MainPage: "/",
  CatalogPage: "/catalog",
  ContactPage: "/contacts",
  CategoryPage: "/catalog/:categoryFrontName",
  AcidPage: "/catalog/:categoryId/:acidFrontName",
  NewsPage: "/news",
  AboutUs: "/about",
  NotFoundPage: "/*",
};

export default RoutePaths;
