export const getRandomColorFromPalette = () => {
  const CATEGORY_COLOR_PALETTE = ["#EDF2F8", "#EEEEEE", "#F1E9E9", "#F8F4ED"];
  const rand = Math.floor(Math.random() * CATEGORY_COLOR_PALETTE.length);
  return CATEGORY_COLOR_PALETTE[rand];
};

export function generateRandomGradient() {
  let deg = Math.floor(Math.random() * 360);

  let gradient =
    "linear-gradient(" +
    deg +
    "deg, " +
    getRandomColorFromPalette() +
    ", " +
    getRandomColorFromPalette() +
    ")";

  return gradient;
}
