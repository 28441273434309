import { FooterTelephoneBlockProps } from "@components/Footer/components/FooterTelephoneBlock/FooterTelephoneBlockTypes";
import Link from "@components/Link/Link";
import Typography from "@components/Typography/Typography";
import { TELEPHONE } from "@constants/constants.common";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const FooterTelephoneBlock = ({
  telephoneLinkVariant = "h3",
}: FooterTelephoneBlockProps) => {
  const theme = useTheme();

  return (
    <div>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="body2" color={theme.palette.grey[600]}>
            Единый номер для всех клиентов
          </Typography>
        </Grid>
        <Grid>
          <Link
            href={`tel: ${TELEPHONE}`}
            variant={telephoneLinkVariant}
            color={theme.palette.common.white}
            underline="none"
          >
            {TELEPHONE}
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default FooterTelephoneBlock;
