import theme from "@config/theme";

//TODO: figure out how to improve the view when windows scaling is 125%
export const GLOBAL_STYLES = `
  * {
    box-sizing: border-box;
  };

  html, body, #root, .app-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  };

  html {
    height: 100%;
  };

  body {
    background: ${theme.palette.background.default};
    margin: 0px;
  };

  @media (-webkit-device-pixel-ratio: 1.25) {
    html, body, #root, .app-container > div {
      zoom: 98%;
    };
  }
`;

export const borderRadius = "25px";

export const IconSize = {
  small: "small",
  medium: "medium",
} as const;
