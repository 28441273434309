import { PortalProps } from "@components/Portal/PortalTypes";
import MuiPortal from "@mui/material/Portal";

const Portal = ({ children, container, ...rest }: PortalProps) => {
  return (
    <MuiPortal container={container} {...rest}>
      {children}
    </MuiPortal>
  );
};
export default Portal;
