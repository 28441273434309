import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import LocationBlock from "@pages/MainPage/components/LocationBlock/LocationBlock";
import MessengersBlock from "@pages/MainPage/components/MessengersBlock/MessengersBlock";

const MainWithUsSection = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={"30px"}
    >
      <Grid sm={12} lg={8}>
        <LocationBlock />
      </Grid>

      <Grid xs={12} lg={4} sx={{ marginTop: isMobileScreen ? "30px" : "0" }}>
        <MessengersBlock />
      </Grid>
    </Grid>
  );
};
export default MainWithUsSection;
