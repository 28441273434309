import TelegramIcon from "@assets/icons/TelegramIcon/TelegramIcon";
import ViberIcon from "@assets/icons/ViberIcon/ViberIcon";
import WAppIcon from "@assets/icons/WAppIcon/WAppIcon";
import mobileMessengersBlock from "@assets/images/mobileMessengersBlock.png";
import Link from "@components/Link/Link";
import StyledMobileScreenTitle from "@components/MobileScreenTitle/StyledMobileScreenTitle";
import Typography from "@components/Typography/Typography";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  StyledMessengersBlockGridContainer,
  StyledMessengersBlockAvatarHeadIcon,
  StyledMessengersBlockAvatarBodyIcon,
  StyledMessengersBlockMobileScreenImageGrid,
} from "@pages/MainPage/components/MessengersBlock/StyledMessengersBlock";

const MessengersBlock = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const description =
    "Уведомления, просьбы и предложения в любом удобном для Вас мессенджере";

  const messengersBlockTitle = isMobileScreen ? (
    <StyledMobileScreenTitle variant="h3" align="center">
      Будем на связи
    </StyledMobileScreenTitle>
  ) : (
    <Typography variant="h3" align="center">
      Будем на связи
    </Typography>
  );

  const messengersBlockAbsoluteImage = isMobileScreen ? (
    <StyledMessengersBlockMobileScreenImageGrid>
      <img src={mobileMessengersBlock} alt="Мальчик неваляшка" />
    </StyledMessengersBlockMobileScreenImageGrid>
  ) : (
    <>
      <StyledMessengersBlockAvatarHeadIcon />
      <StyledMessengersBlockAvatarBodyIcon />
    </>
  );

  return (
    <StyledMessengersBlockGridContainer
      isMobileScreen={isMobileScreen}
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      wrap="nowrap"
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid>{messengersBlockTitle}</Grid>

        <Grid sx={{ zIndex: 1 }}>
          <Typography
            variant={isMobileScreen ? "body2" : "body1"}
            align="center"
          >
            {description}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: isMobileScreen ? "20px" : "30px" }}
      >
        <Grid>
          <Link
            href="https://wa.me/message/7YMD6ZKBIRWXG1?src=qr"
            target="_blank"
          >
            <WAppIcon />
          </Link>
        </Grid>

        <Grid>
          <Link href="https://t.me/nzfk_bot" target="_blank">
            <TelegramIcon />
          </Link>
        </Grid>

        <Grid>
          <Link>
            <ViberIcon withCircleBackground />
          </Link>
        </Grid>
      </Grid>

      {messengersBlockAbsoluteImage}
    </StyledMessengersBlockGridContainer>
  );
};
export default MessengersBlock;
