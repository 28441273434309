import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import {
  LEGAL_ADDRESS,
  OFFICE_ADDRESS,
  WAREHOUSE_ADDRESS,
} from "@constants/constants.common";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import ContactInfoBlock from "@pages/ContactPage/components/ContactInfoBlock/ContactInfoBlock";
import { MainContactsInfoTypesProps } from "@pages/ContactPage/components/MainContactsInfo/MainContactsInfoTypes";
import {
  StyledMainContactsInfoAddressBlock,
  StyledMainContactsMainAddressBoxWrapper,
  StyledMainContactsMap,
} from "@pages/ContactPage/components/MainContactsInfo/StyledMainContactsInfo";
import { Placemark } from "@pbe/react-yandex-maps";

const MainContactsInfo = ({
  contacts,
  isContactsLoading,
  isContactsError,
}: MainContactsInfoTypesProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const baseContactsContent = contacts?.map((contact) => (
    <Grid2 xs={6} key={contact.id}>
      <ContactInfoBlock
        description={contact.name}
        href={"tel:" + contact.number}
        title={contact.number}
        variant={isMobileScreen ? "body2" : "body1"}
        color={theme.palette.primary.main}
        underline="none"
      />
    </Grid2>
  ));

  return (
    <Box>
      <Grid2
        container
        direction={{ xs: "column-reverse", lg: "row" }}
        spacing={"30px"}
        wrap="nowrap"
        disableEqualOverflow
      >
        <Grid2 lg={8}>
          <StyledMainContactsMap
            defaultState={{ center: [56.32, 43.95], zoom: 14 }}
          >
            <Placemark defaultGeometry={[56.323694, 43.956273]} />
          </StyledMainContactsMap>
        </Grid2>

        <Grid2 xs={12} md={12} lg={4}>
          <div>
            <Grid2 container spacing={{ xs: "15px", md: "30px" }}>
              <WithLoader
                isLoading={isContactsLoading}
                stackProps={{ style: { width: "100%" } }}
              >
                <WithErrorMessage
                  isError={isContactsError}
                  stackProps={{ style: { width: "100%" } }}
                >
                  {baseContactsContent}
                </WithErrorMessage>
              </WithLoader>
            </Grid2>
          </div>
        </Grid2>
      </Grid2>

      <StyledMainContactsMainAddressBoxWrapper>
        <Grid2
          container
          justifyContent="space-between"
          spacing={{ xs: 2, sm: "30px" }}
        >
          <Grid2 xs={12} md={6} lg={4}>
            <StyledMainContactsInfoAddressBlock
              description="Адрес склада"
              title={WAREHOUSE_ADDRESS}
              variant={isMobileScreen ? "body2" : "body1"}
              underline="none"
            />
          </Grid2>
          <Grid2 xs={12} md={6} lg={4}>
            <StyledMainContactsInfoAddressBlock
              description="Юридический адрес"
              title={LEGAL_ADDRESS}
              variant={isMobileScreen ? "body2" : "body1"}
              underline="none"
            />
          </Grid2>
          <Grid2 xs={12} md={6} lg={4}>
            <StyledMainContactsInfoAddressBlock
              description="Адрес офиса"
              title={OFFICE_ADDRESS}
              variant={isMobileScreen ? "body2" : "body1"}
              underline="none"
            />
          </Grid2>
        </Grid2>
      </StyledMainContactsMainAddressBoxWrapper>
    </Box>
  );
};
export default MainContactsInfo;
