import Button from "@components/Button/Button";
import ReactHookFormInput from "@components/form-components/ReactHookFormInput/ReactHookFormInput";
import ReactHookFormSelect from "@components/form-components/ReactHookFormSelect/ReactHookFormSelect";
import ReactHookFormTextArea from "@components/form-components/ReactHookFormTextArea/ReactHookFormTextArea";
import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Box, MenuItem, Stack, styled, TextField } from "@mui/material";

export const StyledModalFillingUserInfoBlocksBoxWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "background" && prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp<{ background?: string }>>`
  border-radius: ${borderRadius};
  padding: ${({ isMobileScreen }) => (isMobileScreen ? "15px" : "30px")};
  background: ${({ background, theme }) =>
    background ? background : theme.palette.common.white};
`;

export const StyledRequestModalFillingUserInfoTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `font-size: 20px;
  line-height: 23px;`}
`;

export const StyledRequestModalFillingUserInfoReactHookFormInput = styled(
  ReactHookFormInput,
  { shouldForwardProp: (prop) => prop !== "isMobileScreen" }
)<TypeStyledComponentWithMobileScreenProp>`
  .MuiInputBase-root {
    background: rgba(255, 255, 255, 0.3);
    border-radius: ${borderRadius};
    font-size: ${({ isMobileScreen }) => (isMobileScreen ? "16px" : "18px")};

    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(39, 39, 39, 0.1);
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(39, 39, 39, 0.1);
  }

  .Mui-focused {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  .Mui-error {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.error.main};
      }
    }
  }
`;

export const StyledRequestModalFillingUserInfoInputFile = styled(TextField)`
  opacity: 0;
  position: absolute;
  z-index: -1;
  max-width: 600px;
  width: 100%;
`;

export const StyledRequestModalFillingUserInfoInputFileLabel = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  text-align: center;
  border-radius: ${borderRadius};
  border: 1px dashed black;
  padding: ${({ isMobileScreen }) =>
    isMobileScreen ? "15px 6px" : "19.5px 0"};
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledRequestModalFillingUserInfoInputFileLabelTypography = styled(
  Typography,
  { shouldForwardProp: (prop) => prop !== "isMobileScreen" }
)<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `font-size: 16px;
    line-height: 19px;
    `}
`;

export const StyledRequestModalFillingUserInfoReactHookFormSelect = styled(
  ReactHookFormSelect,
  { shouldForwardProp: (prop) => prop !== "isMobileScreen" }
)<TypeStyledComponentWithMobileScreenProp>`
  max-width: 106px;
  min-width: 74px;
  text-align: center;
  width: ${({ isMobileScreen }) => (isMobileScreen ? "auto" : "100%")};
  .MuiInputBase-root {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(39, 39, 39, 0.1);
    border-radius: ${borderRadius};
    .MuiSelect-select {
      display: flex;
      align-items: center;
      justify-content: center;
      ${({ isMobileScreen }) =>
        isMobileScreen && "padding: 14px 34px 13px 17px;"}
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const StyledRequestModalFillingUserInfoSelectItem = styled(MenuItem)`
  padding: 15px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(39, 39, 39, 0.05);
  :last-child {
    border-bottom: none;
  }
`;

export const StyledRequestModalFillingUserInfoSelectItemLabel = styled(
  Typography,
  { shouldForwardProp: (prop) => prop !== "isMobileScreen" }
)<TypeStyledComponentWithMobileScreenProp>`
  font-size: ${({ isMobileScreen }) => (isMobileScreen ? "16px" : "18px")};
  line-height: ${({ isMobileScreen }) => (isMobileScreen ? "19px" : "21px")};
`;

export const StyledRequestModalFillingUserInfoTextArea = styled(
  ReactHookFormTextArea
)`
  width: 100%;
  height: 90px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(39, 39, 39, 0.1);
  border-radius: ${borderRadius};
  padding: 16px;
  resize: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  overflow-y: hidden;

  color: ${({ theme }) => theme.palette.text.primary};
  :focus-visible {
    outline: none;
  }
`;

export const StyledFillingUserInfoAttachFileDescription = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  color: rgba(39, 39, 39, 0.3);
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `font-size: 15px;
    line-height: 18px;`}
`;

export const StyledFillingUserInfoAttachFileError = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  color: red;
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `font-size: 15px;
    line-height: 18px;`};
`;

export const StyledModalFillingUserInfoButtonBoxWrapper = styled(Box)`
  display: flex;
  justify-content: end;
  margin-top: 60px;
`;

export const StyledModalFillingUserInfoButtonStackContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isLaptopScreen",
})<{ isLaptopScreen: boolean }>`
  max-width: ${({ isLaptopScreen }) => (isLaptopScreen ? "none" : "660px")};
  ${({ isLaptopScreen }) => isLaptopScreen && "width: 100%"}
`;

export const StyledRequestModalFillingUserInfoButton = styled(Button)`
  border-radius: ${borderRadius};
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  padding: 18.5px 0;

  .MuiLoadingButton-loadingIndicator {
    position: relative;
    left: 0;
  }
`;
