import Button from "@components/Button/Button";
import RequestButton from "@components/RequestButton/RequestButton";
import { styled } from "@mui/material";

export const StyledRequestButton = styled(RequestButton)`
  padding: 8px 20px;
  justify-content: space-between;
  width: 100%;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;

export const StyledNavigationButtonsMenuButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isClose",
})<{
  isClose?: boolean;
}>`
  font-size: 20px;
  padding: 8px 20px;
  justify-content: space-between;
  width: 100%;
  border-radius: 50px;
  background: ${({ isClose }) => (isClose ? "#D0D0D0" : "#EBECED")};
  box-shadow: none;
  color: ${({ theme }) => theme.palette.text.primary};
  &:hover {
    background-color: ${({ isClose }) => (isClose ? "#D0D0D0" : "#EBECED")};
  }
`;
