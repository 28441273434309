import Button from "@components/Button/Button";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";

export const StyledCustomPopupWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "isLaptopScreen",
})<{ isLaptopScreen: boolean }>`
  position: fixed;
  bottom: ${({ isLaptopScreen }) => (isLaptopScreen ? "80px" : "20px")};
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${({ isLaptopScreen }) => (isLaptopScreen ? "0 12px" : "0 24px")};
`;

export const StyledCustomPopup = styled("div", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  border-radius: ${borderRadius};
  background: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ isMobileScreen }) => (isMobileScreen ? "12px" : "15px 20px")};
  max-width: 1536px;
  width: 100%;
`;

export const StyledCustomPopupButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 15px 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: #f6f7f8;
  border-radius: 15px;
  min-width: 80px;
  &:hover {
    background: #f6f7f8;
  }
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    `
  font-size: 16px;
  line-height: 19px;`};
`;
