import React, { useRef } from "react";

import PrimaryLogo from "@assets/icons/PrimaryLogo/PrimaryLogo";
import HeaderNavigation from "@components/Header/components/HeaderNavigation/HeaderNavigation";
import HeaderTopInfo from "@components/Header/components/HeaderTopInfo/HeaderTopInfo";
import { StyledHeader } from "@components/Header/StyledHeader";
import MainContainer from "@components/MainContainer/MainContainer";
import RouterLink from "@components/RouterLink/RouterLink";
import { useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import RoutePaths from "@routes/routePaths";

const Header = () => {
  const theme = useTheme();

  const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const isTableScreen = useMediaQuery("(min-width:768px)");

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const navButtonContainer = useRef(null);

  const justifyContentLogo = isTableScreen ? "space-between" : "center";

  return (
    <MainContainer>
      <StyledHeader isMobileScreen={isMobileScreen}>
        <Grid2 container spacing={2} direction="column" disableEqualOverflow>
          {isTableScreen && (
            <Grid2>
              <HeaderTopInfo />
            </Grid2>
          )}
          <Grid2>
            <Grid2
              container
              justifyContent={justifyContentLogo}
              alignItems="center"
            >
              <Grid2>
                <RouterLink to={RoutePaths.MainPage}>
                  <PrimaryLogo
                    width={isMobileScreen ? 298 : 416}
                    height={isMobileScreen ? 59 : 82}
                  />
                </RouterLink>
              </Grid2>
              <Grid2>
                <HeaderNavigation
                  portalContainer={navButtonContainer}
                  isLaptopScreen={isLaptopScreen}
                  isTableScreen={isTableScreen}
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
        {!isLaptopScreen && <div ref={navButtonContainer}></div>}
      </StyledHeader>
    </MainContainer>
  );
};

export default Header;
