import CategoryCard from "@components/CategoryCard/CategoryCard";
import { CategoryCardsGroupProps } from "@components/CategoryCardsGroup/CategoryCardsGroupTypes";
import { StyledCategoryCardsGroupGridContainer } from "@components/CategoryCardsGroup/StyledCategoryCardsGroup";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import RoutePaths from "@routes/routePaths";
import generateImageSrc from "@utils/generateImageSrc";
import { useNavigate } from "react-router-dom";

const CategoryCardsGroup = ({ categories }: CategoryCardsGroupProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCategoryClick = (frontName: string) => {
    navigate(RoutePaths.CatalogPage + `/${frontName}`);
  };

  const categoriesContent = categories?.map((category) => {
    const categoryImage = category.file && generateImageSrc(category.file.name);

    return (
      <div key={category.id} className="category-card-wrapper">
        <CategoryCard
          title={category.productCategories}
          backgroundImage={categoryImage}
          onClick={() => handleCategoryClick(category.frontName)}
        />
      </div>
    );
  });

  return (
    <StyledCategoryCardsGroupGridContainer
      isMobileScreen={isMobileScreen}
      isTabletScreen={isTabletScreen}
    >
      {categoriesContent}
    </StyledCategoryCardsGroupGridContainer>
  );
};
export default CategoryCardsGroup;
