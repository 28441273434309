import { useCallback, useState, useMemo, useEffect } from "react";

import Typography from "@components/Typography/Typography";
import { EMAIL_REGEXP, NUMBER_CODES } from "@constants/constants.common";
import { borderRadius } from "@constants/constants.style";
import { CreateUserBody } from "@customTypes/apiTypes/models/user.model";
import { MobileButtonConfig } from "@customTypes/requestModalTypes";
import useAppSelector from "@hooks/useAppSelector";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckIcon from "@mui/icons-material/Check";
import {
  FormControlLabel,
  Stack,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { RequestModalFillingUserInfoProps } from "@pages/RequestModal/components/RequestModalFillingUserInfo/RequestModalFillingUserInfoTypes";
import {
  StyledRequestModalFillingUserInfoButton,
  StyledRequestModalFillingUserInfoTextArea,
  StyledRequestModalFillingUserInfoReactHookFormInput,
  StyledRequestModalFillingUserInfoReactHookFormSelect,
  StyledModalFillingUserInfoBlocksBoxWrapper,
  StyledModalFillingUserInfoButtonBoxWrapper,
  StyledRequestModalFillingUserInfoInputFile,
  StyledRequestModalFillingUserInfoInputFileLabel,
  StyledModalFillingUserInfoButtonStackContainer,
  StyledRequestModalFillingUserInfoSelectItem,
  StyledRequestModalFillingUserInfoTitle,
  StyledRequestModalFillingUserInfoSelectItemLabel,
  StyledRequestModalFillingUserInfoInputFileLabelTypography,
  StyledFillingUserInfoAttachFileDescription,
  StyledFillingUserInfoAttachFileError,
} from "@pages/RequestModal/components/RequestModalFillingUserInfo/StyledRequestModalFillingUserInfo";
import RequestModalMobileNavButtons from "@pages/RequestModal/components/RequestModalMobileNavButtons/RequestModalMobileNavButtons";
import RequestModalNavButtonsBoxWrapper from "@pages/RequestModal/components/RequestModalNavButtonsBoxWrapper/RequestModalNavButtonsBoxWrapper";
import { useCreateFileMutation } from "@redux/api/file/fileApi";
import { useCreateOrderMutation } from "@redux/api/order/orderApi";
import {
  useCreateUserMutation,
  useLazyGetUserByEmailQuery,
} from "@redux/api/user/userApi";
import { UserId } from "@redux/slices/requestModal/requestModalSliceTypes";
import getErrorMessage from "@utils/handleCatchBlockError";
import validateFileSize from "@utils/validateFileSize";
import { FormProvider, useForm } from "react-hook-form";

export type UserInfoFormValues = {
  email: string;
  numberCode: string;
  number: string;
  name?: string;
  companyName?: string;
  inn?: string;
  description: string;
};

const selectMenuStyleProps = {
  MenuProps: {
    PaperProps: {
      style: {
        boxShadow: "none",
        border: "1px solid rgba(39, 39, 39, 0.05)",
        borderRadius: borderRadius,
      },
    },
  },
};

const RequestModalFillingUserInfo = ({
  handleNextStep,
  handleReturningOnOrderVerify,
  handleCloseRequestModal,
}: RequestModalFillingUserInfoProps) => {
  const theme = useTheme();

  const isSmallMobileScreen = useMediaQuery("(max-width:420px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptopScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const orderBody = useAppSelector((state) => state.requestModal.orderBody);

  const [createUser] = useCreateUserMutation();
  const [createFile] = useCreateFileMutation();
  const [createOrder, { isLoading: isCreateOrderLoading }] =
    useCreateOrderMutation();

  const [fetchUserByEmail, { data: fetchedUser }] =
    useLazyGetUserByEmailQuery();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileError, setSelectedFileError] = useState<string>("");

  const methods = useForm<UserInfoFormValues>({
    defaultValues: {
      numberCode: "+7",
      email: "",
      number: "",
      name: "",
      companyName: "",
      inn: "",
      description: "",
    },
  });

  const { handleSubmit, getValues, reset } = methods;

  const userExistenceCheckByMail = useCallback(
    async (email: string) => {
      await fetchUserByEmail(email);
    },
    [fetchUserByEmail]
  );

  const handleEmailBlur = useCallback(async () => {
    const emailValue = getValues("email");
    await userExistenceCheckByMail(emailValue);
  }, [getValues, userExistenceCheckByMail]);

  useEffect(() => {
    if (fetchedUser) {
      const { id, file, number, ...restUser } = fetchedUser;
      const numberWithoutNumberCode = number.slice(-10, number.length);
      reset({ ...restUser, number: numberWithoutNumberCode });
    }
  }, [fetchedUser, reset]);

  const handleInputFileChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if ((event.target as HTMLInputElement).files === null) return;
    const file = (event.target as HTMLInputElement).files![0];
    const errorMessage = validateFileSize(file);
    if (errorMessage) {
      setSelectedFileError(errorMessage);
      return;
    }
    setSelectedFileError("");
    setSelectedFile(file);
  };

  const handleCreateUser = useCallback(
    async (data: CreateUserBody) => {
      try {
        const response = await createUser(data).unwrap();
        return response;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    [createUser]
  );

  const handleCreateFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await createFile({
          file: formData,
          frontName: file.name,
        }).unwrap();

        return response;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    [createFile]
  );

  const handleCreateOrder = useCallback(
    async (formDescription: string, user: UserId) => {
      if (!user) return;

      try {
        await createOrder({
          ...orderBody,
          description: formDescription,
          user: user,
        }).unwrap();
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    [createOrder, orderBody]
  );

  const handleSubmitFormData = useCallback(
    async (data: UserInfoFormValues) => {
      if (!selectedFile) {
        if (!fetchedUser) {
          const { numberCode, description, ...body } = {
            ...data,
            number: data.numberCode + data.number,
            file: null,
          };

          const { id: userId } = await handleCreateUser(body);
          if (!userId) return;
          await handleCreateOrder(description, { id: userId });
          handleNextStep();
          return;
        }

        const { numberCode, description, ...body } = {
          ...data,
          id: fetchedUser.id,
          email: fetchedUser.email,
          number: data.numberCode + data.number,
          file: fetchedUser.file ? fetchedUser.file : null,
        };

        const { id: userId } = await handleCreateUser(body);
        if (!userId) return;
        await handleCreateOrder(description, { id: userId });
        handleNextStep();
        return;
      }

      if (!fetchedUser) {
        const fileResponse = await handleCreateFile(selectedFile);

        const { numberCode, description, ...body } = {
          ...data,
          number: data.numberCode + data.number,
          file: { ...fileResponse },
        };

        const { id: userId } = await handleCreateUser(body);
        if (!userId) return;
        await handleCreateOrder(description, { id: userId });
        handleNextStep();
        return;
      }

      const fileResponse = await handleCreateFile(selectedFile);

      const { numberCode, description, ...body } = {
        ...data,
        id: fetchedUser.id,
        email: fetchedUser.email,
        number: data.numberCode + data.number,
        file: { ...fileResponse },
      };

      const { id: userId } = await handleCreateUser(body);
      if (!userId) return;
      await handleCreateOrder(description, { id: userId });
      handleNextStep();
      return;
    },
    [
      fetchedUser,
      handleCreateFile,
      handleCreateOrder,
      handleCreateUser,
      handleNextStep,
      selectedFile,
    ]
  );

  const orderMessage = !isLaptopScreen ? (
    <StyledModalFillingUserInfoBlocksBoxWrapper isMobileScreen={isMobileScreen}>
      <div>
        <StyledRequestModalFillingUserInfoTextArea
          name="description"
          placeholder="Сообщение..."
        />
      </div>
    </StyledModalFillingUserInfoBlocksBoxWrapper>
  ) : (
    <StyledModalFillingUserInfoBlocksBoxWrapper isMobileScreen={isMobileScreen}>
      <Stack direction="column" spacing={isMobileScreen ? "23px" : "30px"}>
        <StyledRequestModalFillingUserInfoTitle
          isMobileScreen={isMobileScreen}
          variant="h5"
        >
          Комментарий к заказу:
        </StyledRequestModalFillingUserInfoTitle>

        <div>
          <StyledRequestModalFillingUserInfoTextArea
            name="description"
            placeholder="Сообщение..."
          />
        </div>
      </Stack>
    </StyledModalFillingUserInfoBlocksBoxWrapper>
  );

  const selectItems = NUMBER_CODES.map((option) => {
    return (
      <StyledRequestModalFillingUserInfoSelectItem
        key={option.value}
        value={option.value}
      >
        <StyledRequestModalFillingUserInfoSelectItemLabel
          isMobileScreen={isMobileScreen}
          variant="requestModalWindowBody1"
          textAlign="center"
        >
          {option.label}
        </StyledRequestModalFillingUserInfoSelectItemLabel>
      </StyledRequestModalFillingUserInfoSelectItem>
    );
  });

  const mobileNavigationButtonsConfig: MobileButtonConfig[] = useMemo(
    () => [
      {
        icon: <ArrowBackIcon />,
        onClick: handleReturningOnOrderVerify,
        background: theme.palette.secondary.main,
        textColor: "#F6F7F8",
      },
      {
        icon: isCreateOrderLoading ? (
          <CircularProgress style={{ color: "#fff" }} size={22} />
        ) : (
          <CheckIcon />
        ),
        onClick: () => {},
        background: "#50D890",
        textColor: "#F6F7F8",
        type: "submit",
        form: "userInfoForm",
      },
    ],
    [
      handleReturningOnOrderVerify,
      isCreateOrderLoading,
      theme.palette.secondary.main,
    ]
  );

  return (
    <FormProvider {...methods}>
      <form
        id="userInfoForm"
        onSubmit={handleSubmit(handleSubmitFormData)}
        encType="multipart/form-data"
      >
        <Grid2
          container
          spacing={isMobileScreen ? "15px" : "30px"}
          justifyContent="center"
          disableEqualOverflow
        >
          <Grid2 xs={12} lg={6}>
            <Stack
              direction="column"
              spacing={isMobileScreen ? "15px" : "30px"}
            >
              <StyledModalFillingUserInfoBlocksBoxWrapper
                isMobileScreen={isMobileScreen}
                background="rgba(26, 115, 232, 0.05)"
              >
                <Stack
                  direction="column"
                  spacing={isMobileScreen ? "23px" : "30px"}
                >
                  <StyledRequestModalFillingUserInfoTitle
                    isMobileScreen={isMobileScreen}
                    variant="h5"
                  >
                    Укажите контактные данные:
                  </StyledRequestModalFillingUserInfoTitle>
                  <StyledRequestModalFillingUserInfoReactHookFormInput
                    isMobileScreen={isMobileScreen}
                    placeholder="Введите электронную почту"
                    type="email"
                    name="email"
                    handleBlur={handleEmailBlur}
                    rules={{
                      required: "Поле обязательно для заполнения.",
                      pattern: {
                        value: EMAIL_REGEXP,
                        message: "Некорректный email.",
                      },
                    }}
                  />
                  <Stack direction="row" spacing={"10px"}>
                    <StyledRequestModalFillingUserInfoReactHookFormSelect
                      isMobileScreen={isMobileScreen}
                      name="numberCode"
                      rules={{ required: true }}
                      SelectProps={{
                        ...selectMenuStyleProps,
                      }}
                    >
                      {selectItems}
                    </StyledRequestModalFillingUserInfoReactHookFormSelect>
                    <StyledRequestModalFillingUserInfoReactHookFormInput
                      isMobileScreen={isMobileScreen}
                      placeholder="Введите номер телефона"
                      type="text"
                      fullWidth
                      name="number"
                      inputProps={{
                        maxLength: 10,
                      }}
                      isNumberInput
                      rules={{
                        required: "Поле обязательно для заполнения",
                        minLength: {
                          value: 10,
                          message: "Введите правильный номер",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </StyledModalFillingUserInfoBlocksBoxWrapper>
              {!isLaptopScreen && orderMessage}
            </Stack>
          </Grid2>
          <Grid2 sm={12} lg={6}>
            <StyledModalFillingUserInfoBlocksBoxWrapper
              isMobileScreen={isMobileScreen}
            >
              <Stack
                direction="column"
                spacing={isMobileScreen ? "23px" : "30px"}
              >
                <StyledRequestModalFillingUserInfoTitle
                  isMobileScreen={isMobileScreen}
                  variant="h5"
                >
                  Будем рады познакомиться:
                </StyledRequestModalFillingUserInfoTitle>
                <Stack direction="column" spacing={"15px"}>
                  <StyledRequestModalFillingUserInfoReactHookFormInput
                    isMobileScreen={isMobileScreen}
                    placeholder="Как к Вам обращаться?"
                    type="text"
                    name="name"
                  />
                  <StyledRequestModalFillingUserInfoReactHookFormInput
                    isMobileScreen={isMobileScreen}
                    placeholder="Название организации"
                    type="text"
                    name="companyName"
                  />
                  <StyledRequestModalFillingUserInfoReactHookFormInput
                    isMobileScreen={isMobileScreen}
                    placeholder="ИНН организации"
                    type="text"
                    name="inn"
                    isNumberInput
                    inputProps={{
                      maxLength: 11,
                    }}
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Введите правильный ИНН",
                      },
                    }}
                  />
                </Stack>
                <FormControlLabel
                  disableTypography
                  control={
                    <StyledRequestModalFillingUserInfoInputFile
                      placeholder="Прикрепить карточку с реквизитами"
                      type="file"
                      name="file"
                      inputProps={{ accept: ".pdf, .txt, .doc" }}
                      onChange={handleInputFileChange}
                    />
                  }
                  label={
                    <StyledRequestModalFillingUserInfoInputFileLabel
                      isMobileScreen={isMobileScreen}
                    >
                      <Stack
                        direction="row"
                        spacing={isMobileScreen ? "0" : "5px"}
                        alignItems="center"
                      >
                        <StyledRequestModalFillingUserInfoInputFileLabelTypography
                          isMobileScreen={isMobileScreen}
                          variant="requestModalWindowBody1"
                        >
                          {selectedFile
                            ? selectedFile.name
                            : "Прикрепить карточку с реквизитами"}
                        </StyledRequestModalFillingUserInfoInputFileLabelTypography>
                        <AttachFileIcon />
                      </Stack>
                    </StyledRequestModalFillingUserInfoInputFileLabel>
                  }
                />
                {selectedFileError && (
                  <StyledFillingUserInfoAttachFileError
                    isMobileScreen={isMobileScreen}
                    variant="body2"
                  >
                    {selectedFileError}
                  </StyledFillingUserInfoAttachFileError>
                )}
                <StyledFillingUserInfoAttachFileDescription
                  isMobileScreen={isMobileScreen}
                  variant="body2"
                >
                  К загрузке допускаются файлы следующих форматов: DOC, PDF, TXT
                  до 10 МБ.
                </StyledFillingUserInfoAttachFileDescription>
              </Stack>
            </StyledModalFillingUserInfoBlocksBoxWrapper>
          </Grid2>
          {isLaptopScreen && (
            <Grid2 xs={12} sx={{ marginBottom: isTabletScreen ? "90px" : "0" }}>
              {orderMessage}
            </Grid2>
          )}
        </Grid2>
        {isTabletScreen ? (
          <RequestModalNavButtonsBoxWrapper
            isSmallMobileScreen={isSmallMobileScreen}
            isTabletScreen={isTabletScreen}
          >
            <RequestModalMobileNavButtons
              handleCloseRequestModal={handleCloseRequestModal}
              buttonsConfig={mobileNavigationButtonsConfig}
            />
          </RequestModalNavButtonsBoxWrapper>
        ) : (
          <StyledModalFillingUserInfoButtonBoxWrapper>
            <StyledModalFillingUserInfoButtonStackContainer
              isLaptopScreen={isLaptopScreen}
              direction="column"
              spacing={"15px"}
            >
              <StyledRequestModalFillingUserInfoButton
                type="submit"
                variant="contained"
                startIcon={
                  isCreateOrderLoading ? (
                    <CircularProgress style={{ color: "#fff" }} size={22} />
                  ) : null
                }
                fullWidth
              >
                {isCreateOrderLoading ? "Отправка..." : "Оправить заявку"}
              </StyledRequestModalFillingUserInfoButton>

              <Typography variant="body2" color="text.secondary">
                Нажимая кнопку “Отправить заявку”, Вы автоматически даёте
                согласие на обработку персональных данных.
              </Typography>
            </StyledModalFillingUserInfoButtonStackContainer>
          </StyledModalFillingUserInfoButtonBoxWrapper>
        )}
      </form>
    </FormProvider>
  );
};
export default RequestModalFillingUserInfo;
