import {
  GetContactsApiResponse,
  GetTeamContactsApiResponse,
} from "@customTypes/apiTypes/models/contact.model";
import { ContactsApiPaths } from "@redux/api/contacts/contactsApiPaths";
import generalApi from "@redux/api/initialApi";

export const contactsApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query<GetContactsApiResponse, void>({
      query: () => ContactsApiPaths.Contacts,
    }),
    getTeamContacts: builder.query<GetTeamContactsApiResponse, void>({
      query: () => ContactsApiPaths.TeamContacts,
    }),
  }),
});

export const { useGetContactsQuery, useGetTeamContactsQuery } = contactsApi;
