import Button from "@components/Button/Button";
import { borderRadius } from "@constants/constants.style";
import { IconButton, styled } from "@mui/material";

export const StyledRequestModalNavigateBackButton = styled(IconButton)`
  padding: 14px 22px;
  background: #ebeced;
  border-radius: ${borderRadius};
  z-index: 999;
  .MuiSvgIcon-root {
    font-size: 30px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const StyledRequestModalNavigateNextButton = styled(Button)`
  border-radius: ${borderRadius};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  padding: 12px 12px;
  font-size: 20px;
  z-index: 999;
  .MuiSvgIcon-root {
    font-size: 30px;
  }
`;
