import Button from "@components/Button/Button";
import CustomImage from "@components/CustomImage/CustomImage";
import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TypographyProps } from "@mui/system";

export const StyledBasicAcidBriefGridContainer = styled(Grid)`
  position: relative;
`;

export const StyledAcidSmallImageWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive?: boolean }>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${({ isActive }) => (isActive ? "3px solid #1A73E8" : "none")};
  border-radius: ${borderRadius};
`;

export const StyledSmallImage = styled(CustomImage, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive?: boolean }>`
  border-radius: ${({ isActive }) => (isActive ? "0" : borderRadius)};
  object-fit: cover;
  width: 100px;
  height: 100px;
`;

export const StyledManufactureLogo = styled("img", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  height: ${({ isMobileScreen }) => (isMobileScreen ? "20px" : "50px")};
`;

export const StyledManufactureTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp<TypographyProps>>`
  font-weight: 700;
  ${({ isMobileScreen }) =>
    isMobileScreen && "font-size: 14px; line-height: 16px;"}
`;

export const StyledManufactureGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen, theme }) =>
    isMobileScreen &&
    ` 
      position: absolute; 
      background: ${theme.palette.background.paper}; 
      border-radius: ${borderRadius};     
      top: -1%;
      right: 0; 
      padding: 6px
    `}
`;

export const StyledImageWrapper = styled("div")`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius};
`;

export const StyledBigImage = styled(CustomImage, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<{ isMobileScreen: boolean }>`
  width: ${({ isMobileScreen }) => (isMobileScreen ? "100%" : "500px")};
  height: ${({ isMobileScreen }) => (isMobileScreen ? "360px" : "500px")};
  border-radius: ${borderRadius};
  object-fit: cover;
`;

export const StyledGoodPriceBlockRequestButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  border-radius: ${borderRadius};
  font-size: ${({ isMobileScreen }) => (isMobileScreen ? "16px" : "20px")};
  box-shadow: none;
  padding: ${({ theme, isMobileScreen }) =>
    isMobileScreen ? theme.spacing(1) : theme.spacing(2)};
`;
