import { useCallback, useEffect, useMemo } from "react";

import { MobileButtonConfig } from "@customTypes/requestModalTypes";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import RequestModalMobileNavButtons from "@pages/RequestModal/components/RequestModalMobileNavButtons/RequestModalMobileNavButtons";
import RequestModalNavButtonsBoxWrapper from "@pages/RequestModal/components/RequestModalNavButtonsBoxWrapper/RequestModalNavButtonsBoxWrapper";
import RequestModalNavigateButtons from "@pages/RequestModal/components/RequestModalNavigateButtons/RequestModalNavigateButtons";
import { RequestModalOrderVerificationProps } from "@pages/RequestModal/components/RequestModalOrderVerification/RequestModalOrderVerificationTypes";
import {
  StyledOrderVerificationSelectedAcidsStackContainer,
  StyledRequestModalOrderVerificationButton,
  StyledRequestModalOrderVerificationTitle,
} from "@pages/RequestModal/components/RequestModalOrderVerification/StyledRequestModalOrderVerification";
import RequestModalSelectedAcidBlock from "@pages/RequestModal/components/RequestModalSelectedAcidBlock/RequestModalSelectedAcidBlock";
import {
  clearFormalizedAcidId,
  createOrderBodyOrderElements,
  createOrderBodyTotalSum,
  selectAcid,
  clearAcidIdFromPage,
} from "@redux/slices/requestModal/requestModalSlice";
import { FormalizedAcid } from "@redux/slices/requestModal/requestModalSliceTypes";

const RequestModalOrderVerification = ({
  setIsChooseTare,
  setIsOrderVerify,
  handleNextStep,
  handleCloseRequestModal,
}: RequestModalOrderVerificationProps) => {
  const theme = useTheme();

  const isSmallMobileScreen = useMediaQuery("(max-width:420px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreeHeight = useMediaQuery("(max-height: 760px)");

  const dispatch = useAppDispatch();

  const formalizedAcids = useAppSelector(
    (state) => state.requestModal.formalizedAcids
  );

  useEffect(() => {
    if (formalizedAcids?.length === 0) {
      dispatch(selectAcid(null));
      dispatch(clearFormalizedAcidId());
      setIsChooseTare(false);
      setIsOrderVerify(false);
    }
  }, [dispatch, formalizedAcids?.length, setIsChooseTare, setIsOrderVerify]);

  const handleAddGoodButton = () => {
    dispatch(selectAcid(null));
    dispatch(clearAcidIdFromPage());
    setIsChooseTare(false);
    setIsOrderVerify(false);
  };

  const formattingOrderBodyOrderElementsAndTotalSum = useCallback(() => {
    if (!formalizedAcids) return;

    const totalSumOrder = formalizedAcids?.reduce(
      (acc: number, current: FormalizedAcid) => acc + current.sum,
      0
    );

    const orderElements = formalizedAcids?.map((item) => {
      const { sum, name, image, formalizedAcidId, ...restItem } = item;
      return restItem;
    });

    dispatch(createOrderBodyTotalSum(totalSumOrder));
    dispatch(createOrderBodyOrderElements(orderElements));
  }, [dispatch, formalizedAcids]);

  const handleClickNextButton = () => {
    formattingOrderBodyOrderElementsAndTotalSum();
    handleNextStep();
  };

  const selectedAcidsPreview = useMemo(() => {
    if (formalizedAcids === null) return [];
    return formalizedAcids.map((item) => {
      const extraActions =
        item.extraActions.length > 0 ? item.extraActions : null;
      return (
        <RequestModalSelectedAcidBlock
          key={item.formalizedAcidId}
          formalizedAcidId={item.formalizedAcidId}
          acidWeight={item.mass}
          acidName={item.name}
          tareCount={item.tareCount}
          tareId={item.tare.id}
          extraActions={extraActions}
          acidImage={item.image}
        />
      );
    });
  }, [formalizedAcids]);

  const mobileNavigationButtonsConfig: MobileButtonConfig[] = [
    {
      title: "Далее",
      variant: "contained",
      icon: <ArrowForwardIcon fontSize="large" />,
      onClick: handleClickNextButton,
      background: theme.palette.secondary.main,
      textColor: "#F6F7F8",
    },
  ];

  return (
    <>
      <StyledRequestModalOrderVerificationTitle
        variant="h4"
        isMobileScreen={isMobileScreen}
      >
        Сверьтесь с деталями:
      </StyledRequestModalOrderVerificationTitle>

      <StyledOrderVerificationSelectedAcidsStackContainer
        direction="column"
        spacing={2}
      >
        {selectedAcidsPreview}
      </StyledOrderVerificationSelectedAcidsStackContainer>

      <Box sx={{ marginBottom: isSmallScreeHeight ? "80px" : "0" }}>
        <StyledRequestModalOrderVerificationButton
          variant="outlined"
          startIcon={<AddIcon fontSize="large" />}
          onClick={handleAddGoodButton}
          isMobileScreen={isMobileScreen}
        >
          Добавить товар
        </StyledRequestModalOrderVerificationButton>
      </Box>

      <RequestModalNavButtonsBoxWrapper
        isTabletScreen={isTabletScreen}
        isSmallMobileScreen={isSmallMobileScreen}
      >
        {isTabletScreen ? (
          <RequestModalMobileNavButtons
            handleCloseRequestModal={handleCloseRequestModal}
            buttonsConfig={mobileNavigationButtonsConfig}
          />
        ) : (
          <RequestModalNavigateButtons
            handleNexButton={handleClickNextButton}
            nextButtonTitle="Продолжить"
          />
        )}
      </RequestModalNavButtonsBoxWrapper>
    </>
  );
};
export default RequestModalOrderVerification;
