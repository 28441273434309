import { borderRadius } from "@constants/constants.style";
import { Box, styled } from "@mui/material";
import ContactInfoBlock from "@pages/ContactPage/components/ContactInfoBlock/ContactInfoBlock";
import { Map } from "@pbe/react-yandex-maps";

export const StyledMainContactsInfoAddressBlock = styled(ContactInfoBlock)`
  max-height: 133px;
  height: 100%;
`;

export const StyledMainContactsMap = styled(Map)`
  width: 100%;
  height: 360px;
  .ymaps-2-1-79-map {
    border-radius: ${borderRadius};
  }

  .ymaps-2-1-79-inner-panes {
    border-radius: ${borderRadius};
  }
`;

export const StyledMainContactsMainAddressBoxWrapper = styled(Box)`
  margin-top: 30px;
`;
