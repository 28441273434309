import StyledMobileScreenTitle from "@components/MobileScreenTitle/StyledMobileScreenTitle";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { OurTeamBlockProps } from "@pages/ContactPage/components/OurTeamBlock/OurTeamBlockTypes";
import TeamMemberBlock from "@pages/ContactPage/components/TeamMemberBlock/TeamMemberBlock";
import formatPhoneNumber from "@utils/formatPhoneNumber";

const OurTeamBlock = ({
  teamContacts,
  isTeamContactsLoading,
  isTeamContactsError,
}: OurTeamBlockProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const ourTeamBlockTitle = isMobileScreen ? (
    <StyledMobileScreenTitle variant="h3">Наша команда</StyledMobileScreenTitle>
  ) : (
    <Typography variant="h3">Наша команда</Typography>
  );

  const teamContent = teamContacts?.map((contact) => (
    <Grid2 key={contact.id} xs={12} sm={6} md={4}>
      <TeamMemberBlock
        name={contact.employerName}
        jobTitle={contact.name}
        phone={formatPhoneNumber(contact.number)}
        email={contact.email}
      />
    </Grid2>
  ));

  return (
    <Box>
      <Stack spacing={"30px"} direction="column">
        <Box>{ourTeamBlockTitle}</Box>

        <WithLoader isLoading={isTeamContactsLoading}>
          <WithErrorMessage isError={isTeamContactsError}>
            <Box>
              <Grid2
                container
                rowSpacing={"30px"}
                columnSpacing={isMobileScreen ? "15px" : "30px"}
              >
                {teamContent}
              </Grid2>
            </Box>
          </WithErrorMessage>
        </WithLoader>
      </Stack>
    </Box>
  );
};
export default OurTeamBlock;
