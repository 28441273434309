import { InDevelopedButtonProps } from "@components/InDevelopedButton/InDevelopedButtonTypes";
import { StyledInDevelopedButton } from "@components/InDevelopedButton/StyledInDevelopedButton";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const InDevelopedButton = ({
  children,
  className,
  variant = "contained",
  ...restProps
}: InDevelopedButtonProps) => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledInDevelopedButton
      isMobileScreen={isMobileScreen}
      variant={variant}
      className={className}
      {...restProps}
    >
      {children}
    </StyledInDevelopedButton>
  );
};
export default InDevelopedButton;
