import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";

export const StyledQuantityIndicatorSquare = styled("div", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen" && prop !== "color",
})<TypeStyledComponentWithMobileScreenProp<{ color: string }>>`
  width: ${({ isMobileScreen }) => (isMobileScreen ? "16px" : "20px")};
  height: ${({ isMobileScreen }) => (isMobileScreen ? "16px" : "20px")};
  background: ${({ color }) => color};
  border-radius: 3px;
`;
