import { PriceBlockProps } from "@components/PriceBlock/PriceBlockTypes";
import Typography from "@components/Typography/Typography";
import { useTheme, useMediaQuery, Box, Stack } from "@mui/material";

const PriceBlock = ({
  prices,
  spacing,
  priceTitleFontSize,
  priceFontSize,
}: PriceBlockProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const priceVariant = isMobileScreen ? "body1" : "h5";

  const wholesalePriceTitle = isMobileScreen
    ? "Цена от 20т"
    : "Цена от 20 тонн";

  const price =
    prices && prices?.length !== 0
      ? `${prices[0].price ? String(prices[0].price) + " ₽/кг" : "по запросу"}`
      : "по запросу";

  const wholesalePrice =
    prices && prices?.length > 1
      ? `${prices[1].price ? String(prices[1].price) + " ₽/кг" : "по запросу"}`
      : "по запросу";

  return (
    <Stack direction="row" justifyContent="space-between" spacing={spacing}>
      <Box>
        <Typography
          fontSize={priceTitleFontSize}
          variant="body2"
          color={theme.palette.text.secondary}
        >
          Цена
        </Typography>

        <Typography
          fontSize={priceFontSize}
          fontWeight={theme.typography.fontWeightBold}
          variant={priceVariant}
        >
          {price}
        </Typography>
      </Box>

      <Box>
        <Typography
          fontSize={priceTitleFontSize}
          variant="body2"
          color={theme.palette.text.secondary}
        >
          {wholesalePriceTitle}
        </Typography>

        <Typography
          fontSize={priceFontSize}
          fontWeight={theme.typography.fontWeightBold}
          variant={priceVariant}
        >
          {wholesalePrice}
        </Typography>
      </Box>
    </Stack>
  );
};
export default PriceBlock;
