import ReactHookFormInput from "@components/form-components/ReactHookFormInput/ReactHookFormInput";
import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material";

export const StyledMailingBlock = styled("div", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  border-radius: ${borderRadius};
  background: #1a73e80d;
  overflow-x: ${({ isMobileScreen }) => (isMobileScreen ? "visible" : "clip")};
  position: relative;
  ${({ isMobileScreen }) =>
    !isMobileScreen && "max-height: 349px; height: 100%;"}
  padding: ${({ isMobileScreen }) => (isMobileScreen ? "30px 32px" : "60px")};
`;

export const StyledMailingBlockReactHookFormInput = styled(ReactHookFormInput)`
  .MuiInputBase-root {
    border-radius: ${borderRadius};
    max-width: 430px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid #27272740;
  }
`;

export const StyledMailingBlockInputIcon = styled(ArrowForwardIcon)`
  color: #27272740;
`;

export const StyledMailingBlockImageContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  position: absolute;
  right: ${({ isMobileScreen }) => (isMobileScreen ? "-2%" : "-60px")};
  bottom: -8%;
  z-index: ${({ isMobileScreen }) => (isMobileScreen ? 1 : -1)};
  ${({ isMobileScreen }) => isMobileScreen && "top: -10%"}
`;

export const StyledMailingBlockSubtitle = styled(Typography)`
  white-space: pre-line;
`;

export const StyledMailingBlockImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  ${({ isMobileScreen }) => isMobileScreen && "width: 105px; height: 99px;"}
`;
