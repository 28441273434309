import React from "react";

import { TypographyProps } from "@components/Typography/TypographyTypes";
import theme from "@config/theme";
import MuiTypography from "@mui/material/Typography";
const Typography = React.forwardRef(
  (
    {
      children,
      className,
      color = theme.palette.text.primary,
      ...rest
    }: TypographyProps,
    ref
  ) => {
    return (
      <MuiTypography
        className={className}
        ref={ref as React.RefObject<HTMLElement>}
        color={color}
        {...rest}
      >
        {children}
      </MuiTypography>
    );
  }
);
export default Typography;
