import { Box, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
const NewsItem = () => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        background: "#EEEEEE",
        padding: isMobileScreen ? "30px" : "60px",
        borderRadius: "10px",
      }}
    >
      <Grid2 container spacing={"30px"} direction="column">
        <Grid2 container spacing={"15px"} direction="column">
          <Grid2 xs={5} sm={4} md={2} lg={1.5}>
            <Box
              sx={{
                background: "#C7C7C7",
                height: "20px",
                borderRadius: "10px",
              }}
            />
          </Grid2>

          <Grid2>
            <Box
              sx={{
                background: "#C7C7C7",
                height: "38px",
                borderRadius: "10px",
              }}
            />
          </Grid2>
        </Grid2>

        <Grid2>
          <Box
            sx={{ background: "#C7C7C7", height: "20px", borderRadius: "10px" }}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};
export default NewsItem;
