import { useCallback, useEffect, useMemo, useState } from "react";

import imageError from "@assets/images/imageError.png";
import { BasicAcidBriefProps } from "@components/BasicAcidBrief/BasicAcidBriefTypes";
import {
  StyledBigImage,
  StyledSmallImage,
  StyledImageWrapper,
  StyledManufactureLogo,
  StyledManufactureTitle,
  StyledAcidSmallImageWrapper,
  StyledManufactureGridContainer,
  StyledGoodPriceBlockRequestButton,
  StyledBasicAcidBriefGridContainer,
} from "@components/BasicAcidBrief/StyledBasicAcidBrief";
import GoodPriceBlock from "@components/GoodPriceBlock/GoodPriceBlock";
import QuantityIndicator from "@components/QuantityIndicator/QuantityIndicator";
import Table from "@components/Table/Table";
import { AcidImage } from "@customTypes/acidTypes";
import useAppDispatch from "@hooks/useAppDispatch";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  setAcidIdFromPage,
  openRequestModal,
} from "@redux/slices/requestModal/requestModalSlice";
import generateImageSrc from "@utils/generateImageSrc";
import imageOnErrorHandler from "@utils/handleImageError";

const BasicAcidBrief = ({
  acidId,
  characteristics,
  prices,
  count,
  images,
  manufacturer,
}: BasicAcidBriefProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isComputerScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const smallImages = useMemo(() => {
    if (!images) return;
    if (images.length <= 0) return;
    return images;
  }, [images]);

  const [activeImage, setActiveImage] = useState<AcidImage>();

  useEffect(() => {
    if (!smallImages) return;
    setActiveImage(smallImages[0]);
  }, [smallImages]);

  const handleChangeImage = useCallback(
    (index: number) => {
      if (!smallImages) return;
      if (smallImages.length === 0) return;

      const activeImage = smallImages[index];
      setActiveImage(activeImage);
    },
    [smallImages]
  );

  const handleRequestAcidButton = useCallback(() => {
    if (!acidId) return;
    dispatch(setAcidIdFromPage(Number(acidId)));
    dispatch(openRequestModal());
  }, [acidId, dispatch]);

  const handleManufacturerImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    if (event.currentTarget.parentElement) {
      event.currentTarget.parentElement.style.display = "none";
      return;
    }
    event.currentTarget.style.display = "none";
  };

  const smallImagesContent = useMemo(() => {
    if (!smallImages) return;
    if (smallImages.length === 0) return;
    return smallImages.map((image, index) => {
      return (
        <StyledAcidSmallImageWrapper
          key={image.id}
          onClick={() => handleChangeImage(index)}
          isActive={activeImage?.id === image.id ? true : false}
        >
          {/* ?NOTE: src={image.name} because the file name is enough for us to query*/}

          <StyledSmallImage
            isActive={activeImage?.id === image.id ? true : false}
            src={generateImageSrc(image.name)}
            onError={(event) => imageOnErrorHandler(event)}
            alt="Кислота"
            width={100}
            height={100}
          />
        </StyledAcidSmallImageWrapper>
      );
    });
  }, [activeImage, handleChangeImage, smallImages]);

  const manufacturerRender = () => {
    if (!manufacturer) return;

    if (!manufacturer.file) {
      return (
        <StyledManufactureGridContainer
          alignItems="center"
          isMobileScreen={isMobileScreen}
        >
          <StyledManufactureTitle
            variant="body1"
            isMobileScreen={isMobileScreen}
          >
            {manufacturer.name}
          </StyledManufactureTitle>
        </StyledManufactureGridContainer>
      );
    }

    return (
      <StyledManufactureGridContainer
        container
        alignItems="center"
        spacing={"8px"}
        isMobileScreen={isMobileScreen}
      >
        <Grid2>
          <StyledManufactureLogo
            src={generateImageSrc(manufacturer.file.name)}
            alt={manufacturer.name}
            isMobileScreen={isMobileScreen}
            onError={(event) => handleManufacturerImageError(event)}
          />
        </Grid2>

        <Grid2>
          <StyledManufactureTitle
            variant="body1"
            isMobileScreen={isMobileScreen}
          >
            {manufacturer.name}
          </StyledManufactureTitle>
        </Grid2>
      </StyledManufactureGridContainer>
    );
  };

  const goodPriceBlockRequestButton = (
    <StyledGoodPriceBlockRequestButton
      variant="contained"
      size="large"
      fullWidth
      isMobileScreen={isMobileScreen}
      onClick={handleRequestAcidButton}
    >
      Оставить заявку
    </StyledGoodPriceBlockRequestButton>
  );

  return (
    <div>
      <StyledBasicAcidBriefGridContainer
        container
        spacing={isMobileScreen ? "15px" : "30px"}
        justifyContent="center"
      >
        <Grid2 xs={12} lg={6} xl={5}>
          <Grid2
            container
            wrap={isMobileScreen ? "wrap" : "nowrap"}
            direction={isMobileScreen ? "column-reverse" : "row"}
            justifyContent={isComputerScreen ? "center" : "space-between"}
            spacing={isMobileScreen ? "15px" : "30px"}
          >
            {smallImagesContent && (
              <Grid2>
                <Stack
                  sx={{ marginTop: isMobileScreen ? "16px" : "0px" }}
                  direction={isMobileScreen ? "row" : "column"}
                  justifyContent={isMobileScreen ? "center" : "flex-start"}
                  spacing={isMobileScreen ? "15px" : "30px"}
                >
                  {smallImagesContent}
                </Stack>
              </Grid2>
            )}

            <Grid2>
              <StyledImageWrapper>
                <StyledBigImage
                  isMobileScreen={isMobileScreen}
                  src={
                    !images || images.length <= 0
                      ? imageError
                      : generateImageSrc(activeImage ? activeImage.name : "")
                  }
                  alt="Кислота"
                  width={560}
                  height={500}
                  onError={(event) => imageOnErrorHandler(event)}
                />
              </StyledImageWrapper>
            </Grid2>
          </Grid2>
        </Grid2>

        {!isMobileScreen && (
          <Grid2 lg={6} xl={4}>
            <Stack direction="column" spacing={2}>
              {manufacturerRender()}
              {characteristics && (
                <Grid2>
                  <Table
                    tableData={characteristics?.slice(0, 5)}
                    withoutBorder
                  />
                </Grid2>
              )}
            </Stack>
          </Grid2>
        )}

        {isMobileScreen && manufacturerRender()}

        <Grid2 xs={12} sm={8} md={6} lg={4} xl={3}>
          <Grid2
            container
            direction="column"
            spacing={isMobileScreen ? "15px" : "30px"}
            alignItems="center"
          >
            <Grid2 sx={{ maxWidth: "360px", width: "100%" }}>
              <GoodPriceBlock
                sx={{
                  padding: isMobileScreen ? "20px 15px" : "30px",
                }}
                prices={prices}
                button={goodPriceBlockRequestButton}
              />
            </Grid2>
            {count && (
              <Grid2>
                <QuantityIndicator count={count} />
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </StyledBasicAcidBriefGridContainer>
    </div>
  );
};

export default BasicAcidBrief;
