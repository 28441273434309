import Button from "@components/Button/Button";
import { styled } from "@mui/material";

const RequestButton = styled(Button)`
  border-radius: 50px;
  background: ${({ theme }) => theme.palette.secondary.main};
  box-shadow: none;
  font-size: 20px;
  padding: 4px 20px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    box-shadow: none;
  }
`;

export default RequestButton;
