import { Box, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MainBanner from "@pages/MainPage/components/MainBanner/MainBanner";
import MainInfoSection from "@pages/MainPage/components/MainInfoSection/MainInfoSection";
import MainProducts from "@pages/MainPage/components/MainProducts/MainProducts";
import MainWithUsSection from "@pages/MainPage/components/MainWithUsSection/MainWithUsSection";
import { StyledMainPageContainer } from "@pages/MainPage/StyledMainPage";

const MainPage = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledMainPageContainer>
      <MainBanner />
      <MainProducts />

      <Box sx={{ marginTop: isMobileScreen ? "60px" : "90px" }}>
        <MainInfoSection />
      </Box>

      <Box sx={{ marginTop: isMobileScreen ? "48px" : "78px" }}>
        <MainWithUsSection />
      </Box>
    </StyledMainPageContainer>
  );
};
export default MainPage;
