import Typography from "@components/Typography/Typography";
import { MobileButtonConfig } from "@customTypes/requestModalTypes";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  StyledRequestModalMobileNavButton,
  StyledRequestModalMobileNavIconButton,
} from "@pages/RequestModal/components/RequestModalMobileNavButtons/StyledRequestModalMobileNavButtons";

export type RequestModalMobileNavButtonsProps = {
  buttonsConfig: MobileButtonConfig[];
  handleCloseRequestModal: () => void;
};

const RequestModalMobileNavButtons = ({
  buttonsConfig,
  handleCloseRequestModal,
}: RequestModalMobileNavButtonsProps) => {
  const theme = useTheme();

  const mobileNavButtonsContent = buttonsConfig.map((button, index) => {
    const { title, icon, background, textColor, form, type, ...restProps } =
      button;
    return (
      <Grid2
        key={title ? title : "" + index}
        xs={buttonsConfig.length === 1 ? 12 : 6}
      >
        {title ? (
          <StyledRequestModalMobileNavButton
            endIcon={icon}
            background={background}
            textColor={textColor}
            form={form || ""}
            type={type || "button"}
            {...restProps}
          >
            <Typography variant="body1" color={textColor}>
              {title}
            </Typography>
          </StyledRequestModalMobileNavButton>
        ) : (
          <StyledRequestModalMobileNavIconButton
            background={background}
            textColor={textColor}
            type={type || "button"}
            form={form || ""}
            {...restProps}
          >
            {icon}
          </StyledRequestModalMobileNavIconButton>
        )}
      </Grid2>
    );
  });

  return (
    <Grid2
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={"20px"}
      sx={{ width: "inherit" }}
    >
      <Grid2 xs={6}>
        <StyledRequestModalMobileNavButton
          variant="contained"
          endIcon={<CloseIcon fontSize="large" />}
          onClick={handleCloseRequestModal}
          background="#EBECED"
          textColor={theme.palette.text.primary}
        >
          <Typography variant="body1" color={theme.palette.text.primary}>
            Закрыть
          </Typography>
        </StyledRequestModalMobileNavButton>
      </Grid2>

      <Grid2 container spacing={"10px"} xs={6}>
        {mobileNavButtonsContent}
      </Grid2>
    </Grid2>
  );
};
export default RequestModalMobileNavButtons;
