import imageError from "@assets/images/imageError.png";
import { GoodCardProps } from "@components/GoodCard/GoodCardTypes";
import {
  StyledGoodCard,
  StyledGoodCardImage,
  StyledGoodCardButton,
  StyledGoodCardInfoIconButton,
  StyledGoodCardTooltipText,
  StyledGoodCardStackContainer,
} from "@components/GoodCard/StyledGoodCard";
import PriceBlock from "@components/PriceBlock/PriceBlock";
import Typography from "@components/Typography/Typography";
import useAppDispatch from "@hooks/useAppDispatch";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme, useMediaQuery, Stack, Box } from "@mui/material";
import {
  setAcidIdFromPage,
  openRequestModal,
} from "@redux/slices/requestModal/requestModalSlice";
import generateImageSrc from "@utils/generateImageSrc";
import imageOnErrorHandler from "@utils/handleImageError";
import { useLocation, useNavigate } from "react-router-dom";

const GoodCard = ({
  acidId,
  image,
  name,
  prices,
  acidFrontName,
}: GoodCardProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateOnAcidPage = () => {
    navigate(`${location.pathname}/${acidFrontName}`);
  };

  const handleOrderButtonClick = () => {
    if (!acidId) return;
    dispatch(setAcidIdFromPage(Number(acidId)));
    dispatch(openRequestModal());
  };

  return (
    <StyledGoodCard isMobileScreen={isMobileScreen}>
      <StyledGoodCardStackContainer
        direction="column"
        alignItems="baseline"
        spacing={"20px"}
      >
        <Stack
          sx={{ height: "100%" }}
          direction="column"
          spacing={isMobileScreen ? "15px" : "40px"}
        >
          <Box onClick={handleNavigateOnAcidPage}>
            <StyledGoodCardImage
              src={image ? generateImageSrc(image) : imageError}
              alt="Изображение товара"
              isMobileScreen={isMobileScreen}
              onError={(event) => imageOnErrorHandler(event)}
              width={360}
              height={360}
            />
          </Box>

          <Box>
            {isMobileScreen ? (
              <StyledGoodCardTooltipText
                text={name}
                fontSize="12px"
                lineHeight="14px"
                maxTextLength={70}
              />
            ) : (
              <Typography className="good-card__title" variant="body1">
                {name}
              </Typography>
            )}
          </Box>
        </Stack>

        <Stack direction="column" spacing={isMobileScreen ? "15px" : "20px"}>
          <PriceBlock
            priceFontSize={isMobileScreen ? "16px" : "24px"}
            priceTitleFontSize={isMobileScreen ? "12px" : "16px"}
            prices={prices}
            spacing={isMobileScreen ? "10px" : "20px"}
          />
          <Stack direction="row" spacing={"12px"}>
            <StyledGoodCardButton
              variant="contained"
              size="large"
              fullWidth
              isMobileScreen={isMobileScreen}
              onClick={handleOrderButtonClick}
            >
              Заказать
            </StyledGoodCardButton>
            {isMobileScreen ? (
              <StyledGoodCardInfoIconButton onClick={handleNavigateOnAcidPage}>
                <InfoOutlinedIcon sx={{ color: "rgba(39, 39, 39, 0.5)" }} />
              </StyledGoodCardInfoIconButton>
            ) : (
              <StyledGoodCardButton
                variant="contained"
                size="large"
                fullWidth
                isMobileScreen={isMobileScreen}
                background="#EEEEEE"
                textColor={theme.palette.text.primary}
                onClick={handleNavigateOnAcidPage}
              >
                Подробнее
              </StyledGoodCardButton>
            )}
          </Stack>
        </Stack>
      </StyledGoodCardStackContainer>
    </StyledGoodCard>
  );
};
export default GoodCard;
