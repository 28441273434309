import Button from "@components/Button/Button";
import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Stack, styled } from "@mui/material";

export const StyledMainBannerStackContainer = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isDesktopScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isDesktopScreen: boolean }>>`
  background: rgba(39, 39, 39, 0.05);
  padding-left: ${({ isMobileScreen }) => (isMobileScreen ? "30px" : "80px")};
  border-radius: ${borderRadius};
  position: relative;
  margin-top: ${({ isMobileScreen }) => (isMobileScreen ? "30px" : "50px")};
  ${({ isDesktopScreen }) => isDesktopScreen && `padding: 30px 30px 30px 80px`};
  ${({ isMobileScreen }) => isMobileScreen && `padding: 30px;`}
`;

export const StyledMainBannerTitle = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== "isMonitorSmallScreen" && prop !== "isBigTabletScreen",
})<{
  isMonitorSmallScreen?: boolean;
  isBigTabletScreen?: boolean;
}>`
  white-space: pre-line;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  ${({ isMonitorSmallScreen }) =>
    isMonitorSmallScreen && "font-size: 48px;line-height: 56px;"}
  ${({ isBigTabletScreen }) =>
    isBigTabletScreen && "font-size: 36px; line-height: 42px;"}
`;

export const StyledMainBannerButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" &&
    prop !== "isTabletScreen" &&
    prop !== "isBigTabletScreen",
})<
  TypeStyledComponentWithMobileScreenProp<{
    isTabletScreen: boolean;
    isBigTabletScreen: boolean;
  }>
>`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ isMobileScreen }) => (isMobileScreen ? "16px" : "20px")};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  border-radius: ${borderRadius};
  border-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ isBigTabletScreen }) =>
    isBigTabletScreen ? "16px 40px" : "20px 80px"};
  ${({ isTabletScreen }) => isTabletScreen && "padding: 16px 20px"};
  ${({ isMobileScreen }) => isMobileScreen && "padding: 14px 20px"};
  max-width: 355px;
  :hover {
    background-color: #1659b0;
  }
`;

export const StyledMainBannerImageContainer = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" &&
    prop !== "isMonitorSmallScreen" &&
    prop !== "isSmallMobileScreen",
})<
  TypeStyledComponentWithMobileScreenProp<{
    isMonitorSmallScreen: boolean;
    isSmallMobileScreen: boolean;
  }>
>`
  position: ${({ isMobileScreen }) =>
    isMobileScreen ? "absolute" : "inherit"};
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    "top: -12%; right: -8%; width: 214px; height: 208px; transform: rotate(15deg);"};
  ${({ isSmallMobileScreen }) => isSmallMobileScreen && "right: -12%"}
  ${({ isMonitorSmallScreen }) =>
    !isMonitorSmallScreen && "max-width: 528px; max-height: 498px"}
`;

export const StyledMainBannerImage = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: rotate(15deg);
`;
