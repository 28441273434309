import Button from "@components/Button/Button";
import CustomImage from "@components/CustomImage/CustomImage";
import TooltipText from "@components/TooltipText/TooltipText";
import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Box, IconButton, Stack, styled } from "@mui/material";

export const StyledGoodCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  max-width: ${({ isMobileScreen }) => (isMobileScreen ? "172px" : "360px")};
  margin: auto;
  height: 100%;

  :hover {
    .good-card__title {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const StyledGoodCardStackContainer = styled(Stack)`
  height: 100%;
`;

export const StyledGoodCardButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "background" && prop !== "textColor",
})<
  TypeStyledComponentWithMobileScreenProp<{
    background?: string;
    textColor?: string;
  }>
>`
  border-radius: 15px;
  max-width: 174px;
  min-width: 124px;
  box-shadow: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  padding: 15px 40px;
  font-size: 20px;
  :hover {
    background-color: ${({ background, theme }) =>
      background ? background : theme.palette.primary.main};
  }
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.palette.common.white};

  ${({ isMobileScreen }) =>
    isMobileScreen && "padding: 10px 26px; font-size: 16px"};

  ${({ background }) => background && `background-color: ${background}`};
`;

export const StyledGoodCardInfoIconButton = styled(IconButton)`
  background: #eeeeee;
  border-radius: 15px;
`;

export const StyledGoodCardImage = styled(CustomImage, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  border-radius: ${borderRadius};
  object-fit: cover;
  width: ${({ isMobileScreen }) => (isMobileScreen ? "173px" : "360px")};
  height: ${({ isMobileScreen }) => (isMobileScreen ? "173px" : "360px")};
`;

export const StyledGoodCardTooltipText = styled(TooltipText)`
  max-height: 34px;
  height: 100%;
`;
