import mailImage from "@assets/images/mail.png";
import StyledMobileScreenTitle from "@components/MobileScreenTitle/StyledMobileScreenTitle";
import Typography from "@components/Typography/Typography";
import { EMAIL_REGEXP } from "@constants/constants.common";
import { IconButton, InputAdornment, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  StyledMailingBlock,
  StyledMailingBlockImage,
  StyledMailingBlockReactHookFormInput,
  StyledMailingBlockSubtitle,
  StyledMailingBlockInputIcon,
  StyledMailingBlockImageContainer,
} from "@pages/MainPage/components/MailingBlock/StyledMailingBlock";
import { useAddEmailMutation } from "@redux/api/eventSubscription/eventSubscriptionApi";
import { FormProvider, useForm } from "react-hook-form";

interface MailingBlockFormData {
  email: string;
}

const MailingBlock = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const methods = useForm<MailingBlockFormData>({
    defaultValues: {
      email: "",
    },
  });

  const { handleSubmit, reset } = methods;

  const [addEmail] = useAddEmailMutation();

  const handleSubmitForm = (formData: MailingBlockFormData) => {
    addEmail(formData.email);
    reset();
    return;
  };

  const mailingBlockTitle = isMobileScreen ? (
    <StyledMobileScreenTitle variant="h3" sx={{ maxWidth: "262px" }}>
      Всегда в курсе событий
    </StyledMobileScreenTitle>
  ) : (
    <Typography variant="h3">Всегда в курсе событий</Typography>
  );

  const subtitle =
    "Подпишитесь на рассылку новостей и следите \n за изменением цен и датами ближайших поставок";

  return (
    <FormProvider {...methods}>
      <StyledMailingBlock isMobileScreen={isMobileScreen}>
        <form
          action="подписка на события"
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <Grid2
            container
            spacing={isMobileScreen ? 2 : 6}
            direction="column"
            sx={{ padding: 0 }}
          >
            <Grid2>
              <Grid2 container spacing={2} direction="column">
                <Grid2>{mailingBlockTitle}</Grid2>
                <Grid2>
                  <StyledMailingBlockSubtitle
                    variant={isMobileScreen ? "body2" : "body1"}
                  >
                    {subtitle}
                  </StyledMailingBlockSubtitle>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2>
              <StyledMailingBlockReactHookFormInput
                name="email"
                variant="outlined"
                color="secondary"
                type="text"
                label="Введите E-Mail"
                fullWidth
                rules={{
                  pattern: {
                    value: EMAIL_REGEXP,
                    message: "Некорректный email.",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                      <IconButton type="submit">
                        <StyledMailingBlockInputIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid2>
            <StyledMailingBlockImageContainer isMobileScreen={isMobileScreen}>
              <StyledMailingBlockImage
                src={mailImage}
                alt="Email"
                isMobileScreen={isMobileScreen}
              />
            </StyledMailingBlockImageContainer>
          </Grid2>
        </form>
      </StyledMailingBlock>
    </FormProvider>
  );
};
export default MailingBlock;
